import React, { Component } from 'react';

class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconbox: [
                {
                    id: 1,
                    icon: "autora-icon-quality",
                    title: "BEST QUALITY",
                    text: "Autora Construction Services are committed to meeting the highest quality standards without compromising our safety culture."
                },
                {
                    id: 2,
                    icon: "autora-icon-time",
                    title: "ON TIME",
                    text: "Autora Construction Services are committed to meeting the highest quality standards without compromising our safety culture."
                },
                {
                    id: 3,
                    icon: "autora-icon-author",
                    title: "EXPERIENCED",
                    text: "Autora Construction Services are committed to meeting the highest quality standards without compromising our safety culture."
                }
            ],
            history: [
                {
                    id: 1,
                    content: [
                        {
                            id: 1,
                            year: '2000',
                            text: 'Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo Text seit 1500',
                            classnames: "flat-content-wrap",
                        },
                        {
                            id: 2,
                            year: '2010',
                            text: 'Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo Text seit 1500',
                            classnames: "flat-content-wrap",
                        },
                    ]
                },
                {
                    id: 2,
                    content: [
                        {
                            id: 3,
                            year: '2005',
                            text: 'Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo Text seit 1500',
                            classnames: "flat-content-wrap pd26",
                        },
                        {
                            id: 4,
                            year: '2018',
                            text: 'Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie. Lorem Ipsum ist in der Industrie bereits der Standard Demo Text seit 1500',
                            classnames: "flat-content-wrap pd26",
                        },
                    ]
                }
            ],
            accordions: [
                {
                    id: 1,
                    classnames: 'accordion-item',
                    title: 'our mission',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    id: 2,
                    classnames: 'accordion-item',
                    title: 'our vision',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                },
                {
                    id: 3,
                    classnames: 'accordion-item',
                    title: 'our technology',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
                }
            ]
        }
    }
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-headings style-2 clearfix">
                        <h2 className="heading">Call for Abstracts</h2>
                        <div className="sep has-width w80 accent-bg clearfix" />
                        <p className="sub-heading line-height-24 text-777 margin-top-28 margin-right-12">
                            The 4th Annual Meeting of Asian Society of Dermatopathology (ASD) invites submissions for: <br />

                            <ul>
                                <li>
                                    Free Communications
                                </li>
                                <li>
                                    E-Posters
                                </li>
                            </ul>
                            <br />

                            Submissions that are related to dermatopathology including case reports, case series or studies highlighting dermatopathological features, descriptions or investigations are welcome.
                            <br />

                            Case presentations highlighting extraordinary or unusual dermatopathological features are suitable for free communications or E-posters.
                            <br />

                            Only registered attendees can submit an abstract.
                            <br />

                            Dates to remember:
                            <ul>
                                <li>
                                    Start of submission: April 30
                                </li>
                                <li>
                                    Deadline of submission: June 30
                                </li>
                                <li>
                                    Notice of acceptance: July 30
                                </li>
                            </ul>
                            <br />
                            Research Committee Email
                            <br />
                            research@asdmeeting2022.com
                            <br /><br />
                            Click Here For More Information
                        </p>
                    </div>               
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;