import React, { useState, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import env from "react-dotenv";


export default function StepThree(props) {
    const [showDialogue, setShowDialogue] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const submitAbstract = () => {
        const accessToken = localStorage.getItem('accessToken')

        NotificationManager.success("Submiting Abstract...", '', 5000);
        setShowDialogue(true)
        setIsLoading(true)
        // CREATE SOURCE
        let options = {
            method: props.hasAbstract ? 'PUT' : 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(props.form)
        };

        fetch(env.API_URL + "/metadata/abstract", options)
            .then(response => response.json())
            .then(async response => {
                setShowDialogue(false)
                setIsLoading(false)
                if (response.error) {
                    if (response.error === "jwt expired") {
                        NotificationManager.error("Session expired. You need to login again...", '', 5000);
                        await setTimeout(() => {
                            localStorage.removeItem('accessToken');
                            window.location = '/login';
                        }, 5000);
                        return
                    }
                    NotificationManager.error("Could not submit. Contact the system administrator for help.", '', 5000);
                    return
                }
                // REGISTRATION SUCCESS
                setShowDialogue(false)
                NotificationManager.success("Submitted Successfully", '', 5000);
                props.handleNext()
            })
            .catch(err => {
                setShowDialogue(false)
                setIsLoading(false)
                console.error(err)
            });
    }

    return (
        <div className={props.currentForm === 2 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz" >
            <Modal
                open={showDialogue}
                center
                onClose={() => setShowDialogue(false)}
            >
                {!isLoading ?
                    <div style={{ height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <h2 style={{ lineHeight: 1.5, margin: 10 }}>ABSTRACT SUBMISSION GUIDELINES</h2>
                        <p>
                            <br /><br />1. Please register and submit your abstract online.
                            <br /><br />2. Indicate intent of abstract to be present either as “Free Communications” or "E-poster".
                            <br /><br />3. The abstract must be written in English in not more than 250 words. This word limit refers to the body of the abstract and does not include the authors' names, affiliations, and title of abstract.
                            <br /><br />4. Name of the authors in upper and lower cases, surname in capital.  Department/Hospital/Institution/Country of first author only.
                            <br /><br />5. All abstracts must be submitted electronically. Typed, hand-written, incomplete, faxed or late entries will not be accepted. International submissions are to use their family name when "last" name is requested.
                            <br /><br />6. Use of standard abbreviations is desirable. Place special or unusual abbreviations in brackets after the full word the first time it appears. Use numerals to indicate numbers, except to begin sentences.
                            <br /><br />7. Do not include graphs, tables and references in the abstract.
                        </p>
                        <br /> <h2 style={{ lineHeight: 1.5, margin: 10 }}>IMPORTANT INFORMATION:</h2>
                        <p>
                            <br /><br />1. Only authors who have paid the registration fee are entitled to submit an abstract for Poster or Free Communications.
                            <br /><br />2. Abstracts are subject to the approval of the Scientific Sub-committee.
                            <br /><br />3. Contents of the abstract must not have been presented, submitted or accepted for presentation at another meeting or previously published in any journal.
                            <br /><br />4. Potential conflicts of interest must be indicated at the point of abstract submission.
                            <br /><br />5. The Scientific Committee reserves the right to assign an accepted “E-poster” abstract to be presented under the “Free Communications” category and vice versa.
                            <br /><br />6. By submitting an abstract in the “Free Communications” category, you agree to make yourself available for the presentation slot to which your abstract is assigned, and the Q&A session that follows.
                            <br /><br />7. Submission deadline for abstracts is on July 30, 2022.
                            <br /><br />8. Notification of acceptance or rejection will be sent via email by August 15, 2022.
                            <br /><br />9. Accepted abstracts will be featured in the conference E-book of abstracts.
                        </p>
                    </div>
                    :
                    <h2 style={{ lineHeight: 1.5, margin: 10 }}>Loading...</h2>
                }
            </Modal>
            <NotificationContainer />


            <div className="wizard-forms">
                <div className="inner pb-100 clearfix">
                    <div className="form-content pera-content" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                        <div className="step-inner-content">
                            <h2>Review Abstract</h2>
                            <p>Review thoroughly before you click “Submit.” <font style={{ fontWeight: 'bold' }}> You will not be able to edit once it has been submitted.</font></p>

                            <div className="form-inner-area">
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Study Design: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>{props.form.study_design}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Research Category: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>{props.form.research_category}</p>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                                <div className="row" style={{ marginBottom: 20 }}>
                                    <div className="col-12">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Title:</p>
                                    </div>
                                    <div className="col-12">
                                        <p>{props.form.title}</p>
                                    </div>
                                </div>


                                <div className="row" style={{ marginBottom: 10 }}>
                                    <div className="col-12">
                                        <p>
                                            {/* {props.form.corresponding_author_last_name + ' ' + props.form.corresponding_author_first_name}, */}
                                            {props.form[`author_0_last_name`] !== '' && props.form[`author_0_last_name`] + ' ' + props.form[`author_0_first_name`]} {props.form[`author_0_last_name`] !== '' && <sup>1</sup>}{props.form[`author_0_last_name`] !== '' && ", "}
                                            {props.form[`author_1_last_name`] !== '' && props.form[`author_1_last_name`] + ' ' + props.form[`author_1_first_name`]} {props.form[`author_1_last_name`] !== '' && <sup>2</sup>}{props.form[`author_1_last_name`] !== '' && ", "}
                                            {props.form[`author_2_last_name`] !== '' && props.form[`author_2_last_name`] + ' ' + props.form[`author_2_first_name`]} {props.form[`author_2_last_name`] !== '' && <sup>3</sup>}{props.form[`author_2_last_name`] !== '' && ", "}
                                            {props.form[`author_3_last_name`] !== '' && props.form[`author_3_last_name`] + ' ' + props.form[`author_3_first_name`]} {props.form[`author_3_last_name`] !== '' && <sup>4</sup>}{props.form[`author_3_last_name`] !== '' && ", "}
                                            {props.form[`author_4_last_name`] !== '' && props.form[`author_4_last_name`] + ' ' + props.form[`author_4_first_name`]} {props.form[`author_4_last_name`] !== '' && <sup>5</sup>}{props.form[`author_4_last_name`] !== '' && ", "}

                                        </p>
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: 20 }}>
                                    <div className="col-12">
                                        <p>
                                            {props.form[`author_0_position`] !== '' && <sup>1</sup>}{props.form[`author_0_position`] !== '' && props.form[`author_0_position`]}{props.form[`author_0_position`] !== '' && ", "}
                                            {props.form[`author_0_institution`] !== '' && props.form[`author_0_institution`]}{props.form[`author_0_institution`] !== '' && ", "}
                                            {props.form[`author_0_department`] !== '' && props.form[`author_0_department`]}{props.form[`author_0_department`] !== '' && ", "}
                                            {props.form[`author_0_country`] !== '' && props.form[`author_0_country`]}{props.form[`author_0_country`] !== '' && ", "}

                                            {props.form[`author_1_position`] !== '' && <sup>2</sup>}{props.form[`author_1_position`] !== '' && props.form[`author_1_position`]}{props.form[`author_1_position`] !== '' && ", "}
                                            {props.form[`author_1_institution`] !== '' && props.form[`author_1_institution`]}{props.form[`author_1_institution`] !== '' && ", "}
                                            {props.form[`author_1_department`] !== '' && props.form[`author_1_department`]}{props.form[`author_1_department`] !== '' && ", "}
                                            {props.form[`author_1_country`] !== '' && props.form[`author_1_country`]}{props.form[`author_1_country`] !== '' && ", "}

                                            {props.form[`author_2_position`] !== '' && <sup>3</sup>}{props.form[`author_2_position`] !== '' && props.form[`author_2_position`]}{props.form[`author_2_position`] !== '' && ", "}
                                            {props.form[`author_2_institution`] !== '' && props.form[`author_2_institution`]}{props.form[`author_2_institution`] !== '' && ", "}
                                            {props.form[`author_2_department`] !== '' && props.form[`author_2_department`]}{props.form[`author_2_department`] !== '' && ", "}
                                            {props.form[`author_2_country`] !== '' && props.form[`author_2_country`]}{props.form[`author_2_country`] !== '' && ", "}

                                            {props.form[`author_3_position`] !== '' && <sup>4</sup>}{props.form[`author_3_position`] !== '' && props.form[`author_3_position`]}{props.form[`author_3_position`] !== '' && ", "}
                                            {props.form[`author_3_institution`] !== '' && props.form[`author_3_institution`]}{props.form[`author_3_institution`] !== '' && ", "}
                                            {props.form[`author_3_department`] !== '' && props.form[`author_3_department`]}{props.form[`author_3_department`] !== '' && ", "}
                                            {props.form[`author_3_country`] !== '' && props.form[`author_3_country`]}{props.form[`author_3_country`] !== '' && ", "}

                                            {props.form[`author_4_position`] !== '' && <sup>5</sup>}{props.form[`author_4_position`] !== '' && props.form[`author_4_position`]}{props.form[`author_4_position`] !== '' && ", "}
                                            {props.form[`author_4_institution`] !== '' && props.form[`author_4_institution`]}{props.form[`author_4_institution`] !== '' && ", "}
                                            {props.form[`author_4_department`] !== '' && props.form[`author_4_department`]}{props.form[`author_4_department`] !== '' && ", "}
                                            {props.form[`author_4_country`] !== '' && props.form[`author_4_country`]}{props.form[`author_4_country`] !== '' && ", "}
                                        </p>
                                    </div>
                                </div>

                                <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                                <div className="row">
                                    <div className="col-12">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Abstract: </p>
                                    </div>
                                    <div className="col-12">
                                        <p style={{ textAlign: 'justify' }}>{props.form.abstract}</p>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Keywords: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>{props.form.keywords}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Conflict of Interest: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>{props.form.conflictofinterest}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Commercial Funding: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>{props.form.commercialfunding}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Corresponding author: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>
                                            {props.form.corresponding_author_last_name + ' ' + props.form.corresponding_author_first_name}
                                        </p>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ marginRight: 10, fontWeight: 'bold' }}>Email: </p>
                                    </div>
                                    <div className="col-9">
                                        <p>
                                            {props.form.corresponding_author_email}
                                        </p>
                                    </div>
                                </div>



                            </div>
                            <div className="form-inner-area">
                                <p >I have read and agree with the Abstract Submission Guidelines.</p>

                                <br /><br />
                                <p>In clicking “Submit”, I declare that the abstract has not been previously presented at a major international meeting, that the presentation is based on scientific and/or clinical methods that are ethical and valid, that all co-authors have consented to being listed as authors and have contributed to, agree and approve the abstract in its entirety.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <ul>
                        <li>
                            <button onClick={(e) => {
                                e.preventDefault();
                                props.handleBack()
                            }} className="themesflat-button bg-accent js-btn-prev">BACK</button>
                        </li>
                        {props.hasAbstract ?
                            <li>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    submitAbstract()
                                }} className="themesflat-button bg-accent">UPDATE</button>
                            </li>
                            :
                            <li>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    submitAbstract()
                                }} className="themesflat-button bg-accent">SUBMIT</button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}