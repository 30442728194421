const menuLogged = [
  {
    id: 1,
    name: 'Home',
    link: '/',
  },
  {
    id: 0,
    name: 'Maps',
    link: '/maps',
  },
  {
    id: 2,
    name: 'About Us',
    link: '/about-us',
  },
  {
    id: 3,
    name: 'Welcome Messages',
    link: '/opening-messages',
  },
  {
    id: 4,
    name: 'Scientific Programme',
    link: '/scientific-programme',
  }, {
    id: 5,
    name: 'Speakers',
    link: '/speakers',
  },
  {
    id: 6,
    name: 'Abstract Submission',
    link: '/abstract-submission',
  },
  {
    id: 7,
    name: 'Precon',
    link: '/precon',
  },
  {
    id: 8,
    name: 'Fellowship Night',
    link: '/fellowship',
  },
  // {
  //     id: 6,
  //     name: 'Abstract Submission',
  //     link: null,
  //     namesub: [
  //         {
  //             id: 7,
  //             sub: 'Abstract Submission Guidelines',
  //             links: '/guidelines'
  //         },
  //         {
  //             id: 8,
  //             sub: 'Submit an Abstract',
  //             links: '/abstractsubmission'
  //         },
  //         {
  //             id: 9,
  //             sub: 'ASD Presentation Awards',
  //             links: '/abstractsubmission'
  //         },

  //     ],
  // },
  {
    id: 10,
    name: 'Contact Us',
    link: '/contact-us'
  },
  {
    id: 12,
    name: 'Logout',
    link: 'logout'
  },

];

export default menuLogged;
