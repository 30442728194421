import React, { useState, useEffect } from 'react';
import env from 'react-dotenv';
import axios from 'axios';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Modal } from 'react-responsive-modal';

export default function Verify(props) {

    const [userData, setUserData] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasId, setHasId] = useState(false)
    const [notFound, setNotFound] = useState(false)

    useEffect(async () => {
        fetchUpdate()
    }, [setUserData, setIsAdmin])



    const fetchUpdate = () => {
        try {
            setIsLoading(true)
            const parts = window.location.href.split("/");
            const lastPart = parts[parts.length - 1];

            if (lastPart !== "verify") {
                setHasId(true)
            }
            try {
                const userData = JSON.parse(localStorage.getItem('userData'))
                setIsAdmin(userData.admin ? true : false)
            } catch (error) { }


            // CHECK IF ALREADY REGISTERED
            axios.get(`https://api.pdsconvention2023.com/metadata/verify/` + lastPart).then((dt) => {
                setUserData(dt.data)
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)

                setNotFound(true)
                // NotificationManager.error("You are not registered to the PDS Convention. Register for the event first before reserving workshops.", '', 5000);
            })
        } catch (error) { }
    }

    const registerToEvent = (workshop) => {
        try {
            const parts = window.location.href.split("/");
            const lastPart = parts[parts.length - 1];

            setIsLoading(true)
            const accessToken = localStorage.getItem('accessToken')

            // CHECK IF ALREADY REGISTERED
            axios.post(`https://api.pdsconvention2023.com/metadata/registerevent/` + lastPart,
                {
                    "day": workshop.day,
                    "category": workshop.category,
                    "workshop": workshop
                },
                {
                    headers: {
                        'authorization': 'Bearer ' + accessToken,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                },
            ).then((dt) => {
                fetchUpdate()
                // setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
            })
        } catch (error) {

        }

    }

    const RenderWorkshop = (array) => {
        return (
            <>
                {
                    array?.sort((workshopA, workshopB) => {
                        if (workshopA.day < workshopB.day) {
                            return -1;
                        }
                        if (workshopA.day > workshopB.day) {
                            return 1;
                        }
                        return 0;
                    }).map((session, index) => {
                        return <div className='col-12 card' key={index}
                            style={{
                                marginTop: 10,
                                padding: 20,
                                pointerEvents: session.isRegistered ? 'none' : 'all',
                                opacity: session.isRegistered ? 0.5 : 1,
                            }}>

                            <div>
                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                <p style={{ marginRight: 10 }}>{session.assignment.toUpperCase()}</p>
                                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                            </div>

                            {isAdmin ?
                                <>
                                    <button
                                        style={{
                                            backgroundColor: session.isRegistered && 'lightgray'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // props.handleNext()
                                            registerToEvent(session)
                                        }}
                                        className="themesflat-button bg-accent"
                                    >
                                        {session.isRegistered ?
                                            "Confirmed at " + new Date(session.isRegistered).toLocaleString() : "Confirm Attendance"
                                        }
                                    </button>
                                </>
                                :
                                <>
                                    {session.isRegistered &&
                                        "Confirmed at " + new Date(session.isRegistered).toLocaleString()
                                    }
                                </>
                            }
                        </div>
                    })
                }
            </>
        )
    }

    return (
        <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
            <Modal
                closeOnOverlayClick={false}
                open={isLoading}
                showCloseIcon={false}
                center>
                <div style={{ height: '150px', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <h6 style={{ color: 'black', margin: 10 }}>LOADING...</h6>
                    <Dots color={'black'} size={50} />
                </div>
            </Modal>

            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div className="page-content">
                                {hasId ?
                                    <div className="step-inner-content">
                                        <div className='container'>
                                            <div className='row align-items-md-center'>

                                                <img style={{ width: 100, marginTop: 10 }} src={"https://www.pdsconvention2023.com/assets/img/logo-small.png"} alt="board" />
                                                <div className='m-2'>
                                                    <span className="step-no bottom-line">User Verification</span>
                                                    <h4 style={{ marginTop: 0 }}>ID Scan Result</h4>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='container'>

                                            <div className='row justify-content-md-center'>
                                                <div className='col-4'></div>
                                                <div className='col-4 mt-5'>
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={userData ? "../../../assets/img/sign-check-icon.png" : "https://icon-library.com/images/x-icon-white/x-icon-white-23.jpg"} alt="board" />
                                                </div>
                                                <div className='col-4'></div>
                                            </div>
                                            <div className='justify-content-md-center mt-5'>
                                                <h3 style={{ textAlign: 'center' }}>{userData ? "Verified Attendee" : "Participant not found"}</h3>
                                                <p style={{ textAlign: 'center' }}>{userData ? `Remember to present your ID to the admins before entering the workshop` : "The participant is not found in the database. Please direct the participant to the registration area."}</p>

                                            </div>
                                            {userData &&
                                                <div style={{ marginLeft: '2rem', marginRight: '2rem' }} className='justify-content-md-center mt-5'>
                                                    <p style={{ marginTop: 5, textAlign: 'left' }}> Name: {userData?.user?.firstName} {userData?.user?.lastName}</p>
                                                    <p style={{ marginTop: 5, textAlign: 'left' }}> Email: {userData?.user?.email}</p>
                                                    {(userData?.user?.pdsNumber !== "") &&
                                                        <p style={{ marginTop: 5, textAlign: 'left' }}> PDS Number: {userData?.user?.pdsNumber}</p>
                                                    }
                                                    <p style={{ marginTop: 5, textAlign: 'left' }}> Membership Type: {userData?.user?.categoryId}</p>

                                                    <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.businessMeeting ? 'green' : 'red' }}> {userData?.user?.businessMeeting ? '✔ Attending business meeting' : '✖ Not attending business meeting'}</p>


                                                    <p style={{ marginTop: 5, textAlign: 'left', color: userData?.user?.fellowship ? 'green' : 'red' }}> {userData?.user?.fellowship ? '✔ Attending fellowship night' : '✖ Not attending fellowship night'}</p>

                                                    {/* {isAdmin &&
                                                    <> */}
                                                    {userData?.user?.accompany_first_name &&
                                                        <div className='col-12 card'
                                                            style={{
                                                                marginTop: 10,
                                                                padding: 20,
                                                            }}>

                                                            <div>
                                                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>Accompanied by:</p>
                                                                <p style={{ marginRight: 10 }}>{userData?.user?.accompany_first_name} {userData?.user?.accompany_last_name}</p>
                                                                <p style={{ marginRight: 10 }}>{userData?.user?.accompany_email}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/* </>
                                                } */}
                                                </div>
                                            }


                                            {/* {userData?.precon &&
                                                <div className="session-group" style={{ marginTop: 40 }}>
                                                    {userData.precon.length > 0 &&
                                                        <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                            <h3>Precon (November 05, 2023)</h3>
                                                        </div>
                                                    }
                                                    <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                        {userData.precon.map((session, index) => {
                                                            return <div className='col-12 card' key={index}
                                                                style={{
                                                                    marginTop: 10,
                                                                    padding: 20,
                                                                    pointerEvents: session.isRegistered ? 'none' : 'all',
                                                                    opacity: session.isRegistered ? 0.5 : 1,
                                                                }}>

                                                                <div>
                                                                    <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.assignment} Room</p>
                                                                    <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.type}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.price}</p>

                                                                </div>
                                                                {isAdmin ?
                                                                    <>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: session.isRegistered && 'lightgray'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                // props.handleNext()
                                                                                registerToEvent(session)
                                                                            }}
                                                                            className="themesflat-button bg-accent"
                                                                        >
                                                                            {session.isRegistered ?
                                                                                "Confirmed at " + new Date(session.isRegistered).toLocaleString() : "Confirm Attendance"
                                                                            }
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {session.isRegistered &&
                                                                            "Confirmed at " + new Date(session.isRegistered).toLocaleString()
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            } */}
                                            {/* {userData &&
                                                <div className="session-group" style={{ marginTop: 40 }}>
                                                    <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                        <h3>Day 1 (November 06, 2023)</h3>
                                                    </div>
                                                    <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>

                                                        {RenderWorkshop([...userData?.main.filter((item) => item.assignment === "day 1"), ...userData?.workshop.filter((item) => item.assignment === "day 1")])}
                                                    </div>
                                                </div>
                                            } */}


                                            {userData?.workshop &&
                                                <div className="session-group" style={{ marginTop: 40 }}>
                                                    {userData.workshop.length > 0 &&
                                                        <div className='card-deck' style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                                            <h3>Day 2 (November 07, 2023)</h3>
                                                        </div>
                                                    }
                                                    <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem', marginRight: '2rem' }}>

                                                        {RenderWorkshop([...userData?.main.filter((item) => item.assignment === "day 2"), ...userData?.workshop.filter((item) => item.assignment === "day 2")])}


                                                        {/* {userData?.workshop?.sort((workshopA, workshopB) => {
                                                            if (workshopA.assignment < workshopB.assignment) {
                                                                return -1;
                                                            }
                                                            if (workshopA.assignment > workshopB.assignment) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((session, index) => {
                                                            return <div className='col-12 card' key={index}
                                                                style={{
                                                                    marginTop: 10,
                                                                    padding: 20,
                                                                    pointerEvents: session.isRegistered ? 'none' : 'all',
                                                                    opacity: session.isRegistered ? 0.5 : 1,
                                                                }}>

                                                                <div>
                                                                    <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{session.title}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.assignment.toUpperCase()}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.type}</p>
                                                                    <p style={{ marginRight: 10 }}>{session.price}</p>

                                                                </div>
                                                                {isAdmin ?
                                                                    <>
                                                                        <button
                                                                            style={{
                                                                                backgroundColor: session.isRegistered && 'lightgray'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                // props.handleNext()
                                                                                registerToEvent(session)
                                                                            }}
                                                                            className="themesflat-button bg-accent"
                                                                        >
                                                                            {session.isRegistered ?
                                                                                "Confirmed at " + new Date(session.isRegistered).toUTCString() : "Confirm Attendance"
                                                                            }
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {session.isRegistered &&
                                                                            "Confirmed at " + new Date(session.isRegistered).toUTCString()
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        })} */}
                                                    </div>
                                                </div>
                                            }




                                        </div>


                                    </div>
                                    :

                                    <div className="step-inner-content">
                                        <div className='container'>
                                            <div className='row align-items-md-center'>

                                                <img style={{ width: 100, marginTop: 10 }} src={"https://www.pdsconvention2023.com/assets/img/logo-small.png"} alt="board" />
                                                <div className='m-2'>
                                                    <span className="step-no bottom-line">User Verification</span>
                                                    <h4 style={{ marginTop: 0 }}>ID Scan Result</h4>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='container'>

                                            <div className='row justify-content-md-center'>
                                                <div className='col-2'></div>
                                                <div className='col-8 mt-5'>
                                                    <img style={{ width: '100%', maxWidth: '100%', marginTop: '5rem' }} src={"https://cdn0.iconfinder.com/data/icons/qr-code/154/scan-read-qr-code-quick-response-512.png"} alt="board" />
                                                </div>
                                                <div className='col-2'></div>
                                            </div>
                                            <div className='justify-content-md-center mt-5'>
                                                <h3 style={{ textAlign: 'center' }}>Verification Scanner</h3>
                                                <p style={{ textAlign: 'center' }}>Scan an ID using your Camera App<br></br> to get started</p>

                                            </div>
                                        </div>


                                    </div>
                                }
                            </div>

                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />

                    </div>
                </div>
                {/* <Footer /> */}
                {/* {
                        headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    } */}
            </div>
        </div>
    );
}
