import React, { Component } from 'react';
import { LeaderTeam } from '../layouts/about';
import { Slider} from '../layouts/home01/index'

import BannerTitle from '../layouts/about/banner/BannerTitle'
import { Header, BottomBar } from '../layouts/general/index'
class AboutTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_mobile.png",
                    names: "Speakers",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '/speakers',
                    titlelink: 'Speakers',
                    border: '|',
                    // name: ' Speaker',
                    heading: 'Speakers'
                }
            ],
        }
    }
    render() {
        const myStyle = {
            backgroundImage:
                "url('assets/img/slider/bg.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };


        return (
            <div className="header-fixed page sidebar-right width-71 header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div style={myStyle} >
                            <Slider />
                        </div>


                        <div id="site-header-wrap" style={{height: "61px"}}>
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data, idx) => (
                                    <Header data={data} key={idx} />
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap" className="container">
                                {/* <SiteContent />
                                <SideBar /> */}

                                <LeaderTeam />
                            </div>
                        </div>

                    </div>
                    {/* <Footer /> */}
                    {
                        this.state.headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default AboutTeam;