import React, { Component } from 'react';

class Opening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Completing projects on time & Following budget guidelines'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Elevated quality of workmanship'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Meeting diverse supplier requirements'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Implementing appropriate safety precautions and procedures'
                },
            ]
        }
    }

    render() {
        return (
            <div className="row-about">
                <div className="container-fluid">

                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-6 half-background style-1" />
                        <div className="col-md-6 bg-light-grey">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">Message from the ASD President</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                                    <p className="margin-top-30">On behalf of the Asian Society of Dermatopathology (ASD), we sincerely welcome you to join our 4th Annual Meeting on Oct 24-25, 2022. It's our great pleasure and privileges to share the knowledge, experience, and updates of dermatopathology in this meeting. We deeply acknowledge all staffs and faculties for their great efforts and commitment to organize and present in this conference. We wish the convention every success and all the best to everyone.
                                        <br />
                                        Best regards,
                                        <br />
                                        Yuhung</p>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>

                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-8 bg-light-grey">
                            <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 4.5% 0 20%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">Message from the DSP-PDS President and Over-All Chair of the 4th ASD Annual Meeting</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                                    <p className="margin-top-30">
                                        Mabuhay!
                                        <br /> <br />
                                        Welcome everyone!
                                        <br /> <br />
                                        On October 24-25, 2022, the 4th Asian Society Dermatopathology (ASD) Annual Meeting will be hosted by the Dermatopathology Society of the Philippines of the Philippine Dermatological Society (DSP-PDS). For this virtual conference, we are fortunate to have world-renowned speakers from the United States, Europe and Asia, who will share with us their knowledge and updates through the plenary sessions where dermatopathology is correlated with medical dermatology, infectious and tropical medicine, tumors and nails, and advances in diagnostics. For the case discussions, our Asian colleagues will feature various cases seen within the region which may be rare, interesting or challenging. We hope trainees and practicing physicians will join the e-poster or oral presentation of their researches, as well as win the 2022 ASD Presentation and Scholarship Awards.
                                        <br />
                                        On behalf of the members of the DSP-PDS and the Organizing Committee of the 4th ASD Annual Meeting, I would like to thank all the speakers and participants who will join us for this two-day conference. I certainly appreciate the guidance and support of the ASD Officers and Executive Committee members. I would like to thank also the members of the Organizing Committee who have diligently worked hard for this annual meeting, as well as the pharmaceutical companies that have supported our endeavor. All our joint efforts will surely make this event a success and a fruitful learning experience.                                        <br />
                                        <br />
                                        Arnelfa C. Paliza, MD, DPDS
                                        <br />
                                        President
                                        <br />
                                        Dermatopathology Society of the Philippines of the Philippine Dermatological Society (DSP-PDS)
                                        <br />
                                        Over-all Chair
                                        <br />
                                        Organizing Committee of the 4th Asian Society of Dermatopathology (ASD) Annual Meeting

                                    </p>
                                </div>

                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                        <div className="col-md-4 half-background style-3" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Opening;