import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Slider, TitleHeading, IconBox, MainContent, About, SubContent, Service, Project, ServicesBox, Testimonial, Overview, Faq, Partner } from '../layouts/home01/index'
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index'
import { SideBar, SiteContent, SiteContent2 } from '../layouts/about/detail'
import BannerTitle from '../layouts/about/banner/BannerTitle'

class ScientificProgramme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_mobile.png",
                    names: "Scientific Programme",
                }
            ],
            headingchoose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'Registration Fees and Deadlines',
                    text: 'More than 500 projects completed in Autora - With over 25 years of combined experience, we have the knowledge to serve you.',
                    classtext: 'sub-heading',
                }
            ],
            headingservice: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'BEST SERVICES 1',
                    text: 'Construction Services offers a full range of construction services from initial design to project completion.',
                    classtext: 'sub-heading',
                }
            ],
            headingproject: [
                {
                    id: 1,
                    classnames: 'heading text-white',
                    title: 'FEATURED PROJECT',
                    classtext: 'sub-heading',
                }
            ],
            servicebox: [
                {
                    id: 1,
                    classnames: 'heading font-size-30',
                    title: 'ALL SERVICES',
                    classtext: 'sub-heading font-weight-400',
                    text: 'Are you interested in finding out how we can make your project a success? Please constact us.'
                }

            ],
            banners: [
                {
                    id: 1,
                    links: '/',
                    titlelink: 'Scientific Programme',
                    border: '|',
                    heading: 'Scientific Programme'
                }
            ],
        }
    }

    render() {
        const myStyle = {
            backgroundImage:
                "url('assets/img/slider/bg.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">

                        <div style={myStyle} >
                            <Slider />
                        </div>

                        <div id="site-header-wrap" style={{height: "61px"}}>
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data, idx) => (
                                    <Header data={data} key={idx} />
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">

                                            <div className="row-services">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <SiteContent2 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Footer /> */}
                        {
                            this.state.headers.map((data, idx) => (
                                <BottomBar data={data} key={idx} />
                            ))
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default ScientificProgramme;