import React, { Component } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Completing projects on time & Following budget guidelines'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Elevated quality of workmanship'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Meeting diverse supplier requirements'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Implementing appropriate safety precautions and procedures'
                },
            ]
        }
    }

    render() {
        return (
            
            <div className="row-about">
                <div className="container-fluid">

                    {/* <div className="equalize sm-equalize-auto" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className="row flex-row-reverse flex-md-row" style={{ height: '700px', width: '1200px' }}>
                            <div className="col-md-9 order-md-12" >
                                <img className='margin-top-10 logosub' src={"https://res.cloudinary.com/development-mar/image/upload/v1683265771/PDS/Sample_Welcome_Message_rehzkj.jpg"} alt="altimage" data-bgposition="center center" data-no-retina />
                            </div>
                            <div className="col-md-3 order-md-1">
                                <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={60} data-smobile={60} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-headings style-1 clearfix">
                                        <div className='m-10'>
                                            <div className="col-12 d-flex justify-content-sm-center">
                                                <img className='w-80 margin-top-10 logosub' src={"https://res.cloudinary.com/development-mar/image/upload/v1683273300/PDS/PDS_LOGO-02_wmhc0a.png"} alt="altimage" data-bgposition="center center" data-no-retina />
                                            </div>
                                            <h2 className="margin-top-30 heading" >Message from the PDS President</h2>
                                            <div className="sep has-width w80 accent-bg margin-top-50 clearfix" />
                                            <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                            <p > FRANCISCO D. RIVERA IV, MD, FPDS</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                        </div>                        
                    </div> */}

    
                    
                    <div className="equalize sm-equalize-auto" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5vh" }}>
                        <div className="row flex-row-reverse flex-md-row" style={{ height: '700px', width: '1200px' }}>


                            
                            <div className="col-md-3 order-md-1">
                                <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={60} data-smobile={60} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-headings style-1 clearfix">
                                        <div className='m-10'>
                                            <Row>
                                                <Col xs={6} sm={6} md={12}>
                                                    <div className="d-flex justify-content-sm-center">
                                                        <img className='logosub ' src={"assets/img/wmp/chair-pic-2.png"} style={{ height: 'auto' }} alt="Maria Jasmin Zamora" data-bgposition="d-flex align-items-center" data-no-retina />
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={12}>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <div>
                                                                
                                                                <p> CYNTHIA P. CIRIACO-TAN, MD, FPDS</p>
                                                                <div className="sep has-width w80 accent-bg clearfix" />
                                                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                                                <h3>PRESIDENT</h3>
                                                            </div>
                                                        </Col>                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                            <div className="col-md-9 order-md-12" >
                                <img className='margin-top-10 logosub' src={"assets/img/wmp/chair-message-2.jpg"} alt="altimage" data-bgposition="center center" data-no-retina />
                            </div>
                        </div>
                    </div>
                    

                    <div className="equalize sm-equalize-auto" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5vh" }}>
                        <div className="row flex-row-reverse flex-md-row" style={{ height: '700px', width: '1200px' }}>

                            <div className="col-md-3 order-md-1">
                                <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={60} data-smobile={60} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-headings style-1 clearfix">
                                        <div className='m-10'>
                                            <Row>
                                                <Col xs={6} sm={6} md={12}>
                                                    <div className="d-flex justify-content-sm-center">
                                                        <img className='logosub ' src={"assets/img/wmp/chair-pic.png"} style={{ height: 'auto' }} alt="Maria Jasmin Zamora" data-bgposition="d-flex align-items-center" data-no-retina />
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={12}>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <div>
                                                                
                                                                <p> MARIA JASMIN J. JAMORA, MD, FPDS</p>
                                                                <div className="sep has-width w80 accent-bg clearfix" />
                                                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                                                <h3>CHAIR</h3>
                                                            </div>
                                                        </Col>                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                            <div className="col-md-9 order-md-12" >
                                <img className='margin-top-10 logosub' src={"assets/img/wmp/chair-message.jpg"} alt="altimage" data-bgposition="center center" data-no-retina />
                            </div>
                        </div>
                    </div>

                    <div className="equalize sm-equalize-auto" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5vh" }}>
                        <div className="row flex-row-reverse flex-md-row" style={{ height: '700px', width: '1200px' }}>

                            <div className="col-md-3 order-md-1">
                                <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={60} data-smobile={60} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-headings style-1 clearfix">
                                        <div className='m-10'>
                                            <Row>
                                                <Col xs={6} sm={6} md={12}>
                                                    <div className="d-flex justify-content-sm-center">
                                                        <img className='logosub ' src={"assets/img/wmp/claude_javier.png"} style={{ height: 'auto' }} alt="Maria Jasmin Zamora" data-bgposition="d-flex align-items-center" data-no-retina />
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={12}>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <div>
                                                                
                                                                <p> CLAUDE JAVIER, MD, FPDS</p>
                                                                <div className="sep has-width w80 accent-bg clearfix" />
                                                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                                                <h3>CO-CHAIR</h3>
                                                            </div>
                                                        </Col>                                                        
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                            <div className="col-md-9 order-md-12" >
                                <img className='margin-top-10 logosub' src={"assets/img/wmp/claude_javier_message.jpg"} alt="altimage" data-bgposition="center center" data-no-retina />
                            </div>
                        </div>
                    </div>
                    
                    <div className="equalize sm-equalize-auto" style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "5vh" }}>
                        <div className="row flex-row-reverse flex-md-row" style={{ height: '700px', width: '1200px' }}>

                            <div className="col-md-3 order-md-1">
                                <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={60} data-smobile={60} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-headings style-1 clearfix">
                                        <div className='m-10'>
                                            <Row>
                                                <Col xs={6} sm={6} md={12}>
                                                    <div className="d-flex justify-content-sm-center">
                                                        <img className='logosub ' src={"assets/img/wmp/pcp.png"} style={{ height: 'auto' }} alt="" data-bgposition="d-flex align-items-center" data-no-retina />
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={12}>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <div>
                                                                
                                                                <p>RONTGENE M. SOLANTE, MD, FPCP</p>
                                                                <div className="sep has-width w80 accent-bg clearfix" />
                                                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                                                <h3>PRESIDENT</h3>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <div className="d-flex justify-content-sm-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <img className='logosub ' src={"assets/img/wmp/chair-logo.png"} style={{ height: 'auto' }} alt="Future Forward" data-bgposition="d-flex align-items-center" data-no-retina />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                            </div>
                            <div className="col-md-9 order-md-12" >
                                <img className='margin-top-10 logosub' src={"assets/img/wmp/pcp_message.jpg"} alt="altimage" data-bgposition="center center" data-no-retina />
                            </div>
                        </div>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />

                    <br />
                </div>
            </div>
        );
    }
}

export default About;