import React, { Component } from 'react';

class Information extends Component {
  render() {
    return (
      <div className="col-md-6">
        <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
        <div className="themesflat-headings style-2 clearfix">
          <h2 className="heading">INFOMATION</h2>
          <div className="sep has-width w80 accent-bg clearfix" />
        </div>
        <div className="themesflat-spacer clearfix" data-desktop={36} data-mobile={35} data-smobile={35} />
        <div className="themesflat-tabs style-1 w168 clearfix">
          <ul className="tab-title clearfix">
            <li className="item-title active">
              <span className="inner">Head Office</span>
            </li>
            <li className="item-title">
              <span className="inner">Colombia</span>
            </li>
            <li className="item-title">
              <span className="inner">Mexico</span>
            </li>
          </ul>
          <div className="tab-content-wrap clearfix">
            <div className="tab-content">
              <div className="item-content">
                <ul>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-map-marker" />
                      <span className="text">PO BOX 16122 COLLINS STREET WEST VICTORIA, NEWYORK </span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-phone" />
                      <span className="text">CALL US : (+61) 3 8376 6284</span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-envelope" />
                      <span className="text">EMAIL : SUPPORT@NICHE_THEME</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content">
              <div className="item-content">
                <ul>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-map-marker" />
                      <span className="text">PO BOX 16122 COLLINS STREET WEST VICTORIA, NEWYORK </span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-phone" />
                      <span className="text">CALL US : (+61) 3 8376 6284</span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-envelope" />
                      <span className="text">EMAIL : SUPPORT@THEMESFLAT.COM</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-content">
              <div className="item-content">
                <ul>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-map-marker" />
                      <span className="text">PO BOX 16122 COLLINS STREET WEST VICTORIA, NEWYORK </span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-phone" />
                      <span className="text">CALL US : (+61) 3 8376 6284</span>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="inner">
                      <span className="fa fa-envelope" />
                      <span className="text">EMAIL : SUPPORT@THEMESFLAT.COM</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="themesflat-spacer clearfix" data-desktop={20} data-mobile={35} data-smobile={35} />
        <div className="themesflat-map" />
      </div>
    );
  }
}

export default Information;
