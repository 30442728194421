import React from "react";
import { Card, Container, Row, Col, Button, Table, Accordion, Tab, Tabs } from 'react-bootstrap';


export default function Scipro2v1() {

    return (
        <Container>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <Card>
                        <Card.Text style={{ margin: "auto" }}>
                            <Table style={{ margin: "auto" }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Time</th>
                                        <th colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap" }}>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>7:00 AM - 8:00 AM</td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}>Registration</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>8:00 AM - 10:00 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}><a href="#focusgroup-content" style={{ color: "#F6AA1C" }}>Focus Group Discussions</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>10:00 AM - 10:15 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Morning Plenary Session</p><p>Moderator: Dr. Angeli Eloise Torres-Paulino</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>10:15 AM - 10:35 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>The Future is Now: Predictive Biomarkers, Immunotherapy, and Precision Dermatology</p><p>(Dr. Bryan K. Guevara)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>10:35 AM - 10:55 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Revolution in Photoprotection: Review and Updates<br />(Dr. Henry Lim)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>10:55 AM - 11:15 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Beyond the Surface: Unraveling the Complexities of Parasitic Skin Infections</p><p>(Dr. Rowena Natividad F. Genuino)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>11:15 AM - 11:30 AM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Open Forum
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>11:30 AM - 12:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Opening Ceremonies
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>12:00 PM - 12:05 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Introduction of Perpetuo Gutierrez Memorial Lecturer<br />(Dr. Ma. Angela T. Cumagun)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>12:05 PM - 12:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}><p>Perpetuo Gutierrez Memorial Lecture:</p><p>Future Direction of Dermatopathology in the Philippines<br />(Dr. Daisy King Ismael)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>12:30 PM - 1:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Lunch Symposium (Diamond) Galderma</p></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>1:30 PM - 2:15 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Lunch Symposium (Platinum) Novartis</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        {/* <td style={{ textAlign: "center" }}>12:45 PM - 1:30 PM
                                        </td> */}
                                        <td colSpan={4} style={{ textAlign: "center", verticalAlign: "middle" }}>Afternoon Plenary Session
                                            <p>Moderator: Dr. Lily Lyralin Laconico-Tumalad</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:15 PM - 2:35 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            {/* <p>Moderator: Dr. Lily Lyralin Laconico-Tumalad</p> */}
                                            <p>Pediatric Autoimmune Connective Tissue Diseases:</p>
                                            <p>An Up-to-Date Guide and Approach to Management<br />(Dr. Leong Kin Fon)</p>
                                        </td>
                                    </tr>

    

                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:35 PM - 2:55 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Cutaneous T-Cell Lymphoma Best Practices: Practical to Cutting-edge</p>
                                            <p>(Dr. Henry Lim)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:55 PM - 3:05 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>International League of Dermatological Societies (ILDS) session</p>
                                            <p>(Dr. Henry Lim)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>3:05 PM - 3:10 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Open Forum
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>3:10 PM - 3:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>GUT-SKIN AXIS: Impact of the Gut Microbiome on Skin Health<br />(Dr. Maria Tricia Malahito)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>3:30 PM - 3:50 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Toxic Beauty: Are We Polluting the Environment with Our Personal Skin Care?<br />(Dr. Chandra Co)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>3:50 PM - 4:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}>Open Forum</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>4:00 PM - 4:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Gold Plus Symposium: Sun Pharma</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>4:30 PM - 5:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Gold Plus Symposium: Unilever</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>6:00 PM - 7:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Pre-Fellowship Night Activities</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>7:00 PM - 10:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Welcome Reception and Fellowship Night</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Text>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={1}></Col>
                <Col md={10} id="focusgroup-content">
                    <h3 class="my-5">Focus Group Discussions</h3>
                    <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0" >
                        <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner"> Focus Group 1: STI & Leprosy (Isla 1)</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <p><b><i>TITLE:</i></b> INFECTIOUS CHRONICLES: UNVEILING THE CURRENT WORLD OF STI AND LEPROSY</p>
                                            <p></p>
                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                            <p className="mb-3">Dr. Abelaine V. Tablizo and Dr. Emmerson Vista</p>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Leprosy</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>From Isolation to Inspiration: Rising Above the Stigma of Leprosy</p>
                                                            <p>(Dr. Abelaine V. Tablizo) </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Conquering Reaction with Precision: Management of Challenging Lepra Reactions</p>
                                                            <p>(Dr. Abelaine V. Tablizo)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Cracking the Code: PGL-1 as the New Biomarker in Leprosy</p>
                                                            <p>(Dr. Malaya P. Santos)</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className='container justify-content-md-center mt-3'>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Sexually Transmitted Infection</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Overview of the Current STI Situation in the Philippines</p>
                                                            <p>(Dr. Arthur Dessi E. Roman - PSMID)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Always Think of STI (Great Mimicker of Many Dermatologic Conditions)</p>
                                                            <p>(Dr. Gilbert Yang)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>New Trends in the Diagnostic Approach of STIs</p>
                                                            <p>(Dr. Rodelio Domingo Lim)</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner"> Focus Group 2: CONTACT AND ENVIRONMENTAL DERMATOLOGY (Isla 2)</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <p><b><i>TITLE:</i></b> PATCH TEST CARAVAN: MANILA STOP - REVIEW OF PATCH TESTING: FROM BASICS AND BEYOND</p>
                                            <p></p>
                                            <p className="mt-3"><b><i>Chair and Moderator:</i></b></p>
                                            <p className="mb-3">Dr. Sharon O. Lim</p>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>The Basics of Patch testing:</p>
                                                            <p>What, Why, and How to Patch Test</p>
                                                            <p>(Dr. Elaine Gutierrez-Villaroman)</p>

                                                        </td>
                                                        {/* <td style={{verticalAlign: "middle"}}>Dr. Elaine Gutierrez-Villaroman</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Interpretation, Relevance, and Significance of Patch Test Results</p>
                                                            <p>(Dr. Lonabel Encarnacion)</p>

                                                        </td>
                                                        {/* <td style={{verticalAlign: "middle"}}>Dr. Lonabel Encarnacion</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Updates in the World of Contact Dermatitis</p>
                                                            <p>(Dr. Margaret Jimenez)</p>

                                                        </td>
                                                        {/* <td style={{verticalAlign: "middle"}}>Dr. Margaret Jimenez</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Patch Test Workshop
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">Focus Group 3: FUNCTIONAL DERMATOLOGY AND PSYCHODERMATOLOGY (Isla 3)</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <p><b><i>TITLE:</i></b> A CONVERSATION ON THE INTERPLAY BETWEEN BRAIN, SKIN, AND STRESS</p>
                                            <p></p>
                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                            <p className="mb-3">Dr. Antonio Sison and Dr. Maria Tricia Malahito</p>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Talk Show Panelists: Dr. Ma. Angela T. Medina-Lavadia, </p>
                                                            <p>Dr. Maria Jasmin J. Jamora, & Dr. Celia Theresa Lim-Layson </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Gut, Brain, and Skin Connection (Dr. Maria Julieta Arambulo-Cordero)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Hormones of the Skin in Connection with Stress (Dr. Patricia S. Valle-Tin)</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Acupuncture for the Skin and Stress (Dr. Lovell Christina Sioson-Magat)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Open Discussion and Synthesis (Dr. Maria Tricia B. Manlongat-Malahito & Dr. Antonio C. Sison)
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">Focus Group 4: Photodermatology and Immunodermatology (Palawan)</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <p><b><i>TITLE:</i></b> PROBING THE COMPLEXITIES OF PHOTODERMATOLOGY AND IMMUNE-MEDIATED SKIN CONDITIONS </p>
                                            <p></p>
                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                            <p className="mb-3">Dr. Lily Lyralin Tumalad and Dr. Clarisse Mendoza</p>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Photodermatology</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>Optimizing Phototherapy for Vitiligo</p>
                                                            <p>(Dr. Angeli Torres-Paulino)</p>

                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>When It's Complicated:</p>
                                                            <p>Use of Light Therapy in Psoriasis</p>
                                                            <p>(Dr. Giselle Marie Tioleco-Ver)</p>

                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Photosensitivity in Autoimmune Skin Diseases</p>
                                                            <p>(Dr. Josef Symon Concha)</p>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className='container justify-content-md-center mt-3'>
                                            <Table style={{ margin: "auto", textAlign: "center", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>Immunodermatology</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p>The Burning Issue: </p><p>Updates on the Management of Stevens </p><p>Johnson Syndrome/Toxic Epidermal Necrolysis</p>
                                                            <p>(Dr. Jamaime Cruz-Regalado)</p>

                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Beyond Borders and Tracts:</p>
                                                            <p>A Case-Based Discussion on Pyoderma</p>
                                                            <p>Gangrenosum and Hidradenitis Suppurativa</p>
                                                            <p>(Dr. Cybill Uy)</p>

                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>Simplifying Cutaneous Vasculitis for the Dermatologist</p>
                                                            <p>(Dr. Rogelio Balagat)</p>

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </Card>




                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}