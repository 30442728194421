import Home01 from './Home01';
import Home02 from './Home02';
import AboutUs from './AboutUs';
import AboutDetails from './AboutDetails';
import AboutTeam from './AboutTeam';
import AboutCarres from './AboutCarres';
import Services from './Services';
import ServiceDetail from './ServiceDetail';
import Projects from './Projects';
import ProjectFullWidth from './ProjectFullWidth';
import ProjectDetail from './ProjectDetail';
import Testimonial from './Testimonial';
import PricePage from './PricePage';
import Blog from './Blog';
import BlogSingle from './BlogSingle';
import Contact01 from './Contact01';
import Contact02 from './Contact02';
import Verify from './Verify';

import Login from './login';

import VersionOne from './registration/version-one';
import Precon from './precon';
import Fellowship from './fellowship';

import Success from './registration/redirect/success';
import OpeningMessages from './OpeningMessages';
import ScientificProgramme from './ScientificProgramme';

import Guidelines from './Guidelines';
import Awards from './Awards';

import AbstractSubmission from './abstract'
import Maps from './Maps';

const routes = [
  { path: '/', component: Home02 },
  { path: '/maps', component: Maps },
  { path: '/about-us', component: AboutUs },
  { path: '/opening-messages', component: OpeningMessages },
  { path: '/scientific-programme', component: ScientificProgramme },
  { path: '/speakers', component: AboutTeam },

  { path: '/about-detail', component: AboutDetails },
  { path: '/about-carees', component: AboutCarres },
  { path: '/services', component: Services },
  { path: '/services-detail', component: ServiceDetail },
  { path: '/project', component: Projects },
  { path: '/project-full-width', component: ProjectFullWidth },
  { path: '/project-detail', component: ProjectDetail },
  { path: '/page-testimonial', component: Testimonial },
  { path: '/page-pricing', component: PricePage },
  { path: '/blog', component: Blog },
  { path: '/blog-single', component: BlogSingle },
  { path: '/contact-us', component: Contact01 },
  { path: '/contact-02', component: Contact02 },
  // { path: '/register', component: Home01 },
  { path: '/register', component: VersionOne },
  { path: '/precon', component: Precon },
  { path: '/fellowship', component: Fellowship },

  { path: '/login', component: Login },
  { path: '/register/success', component: Success },
  { path: '/guidelines', component: Guidelines },

  { path: '/asdpresentationawards', component: Awards },

  { path: '/abstract-submission', component: AbstractSubmission },
  { path: '/research-forum', component: AbstractSubmission },

  { path: '/verify/:id', component: Verify },
  { path: '/verify/', component: Verify },

];

export default routes;
