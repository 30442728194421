const bannerhome1 = [
  {
      id: 1,
      srcimg: 'assets/img/slider/banner.jpg',
      classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
      classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
      datax: "['left','left','left','center']",
      datahset: "['34','34','34','0']",
      datavset1: "['-134','-134','-134','-134']",
      datavset2: "['-63','-63','-63','-63']",
      datavset3: "['2','2','2','2']",
      datavset: "['106','106','106','106']",
      classbtn: "tp-caption"
  },
  // {
  //   id: 2,
  //   srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1710128458/GV%20Registration/banner.jpg',
  //   classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
  //   classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
  //   datax: "['center','center','center','center']",
  //   datahset: "['0','0','0','0']",
  //   datavset1: "['-134','-134','-134','-134']",
  //   datavset2: "['-63','-63','-63','-63']",
  //   datavset3: "['2','2','2','2']",
  //   datavset: "['106','106','106','106']",
  //   classbtn: 'tp-caption text-center'
  // },
  // {
  //     id: 3,
  //     srcimg: 'assets/img/slider/slider-bg-2.jpg',
  //     classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
  //     classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
  //     datax: "['right','right','right','center']",
  //     datahset: "['-14','-14','-14','0']",
  //     datavset1: "['-134','-134','-134','-134']",
  //     datavset2: "['-63','-63','-63','-63']",
  //     datavset3: "['2','2','2','2']",
  //     datavset: "['106','106','106','106']",
  //     classbtn: "tp-caption text-right"
  // }
];

export default bannerhome1;
