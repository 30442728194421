import React, { useState, useEffect } from "react";
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Modal } from 'react-responsive-modal'

export default function StepTwoSession(props) {
    const [workshops, setWorkshops] = useState([])
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);


    const [userLabel, setUserLabel] = useState("Delegate");

    const [boracay, setBoracay] = useState([]);
    const [batanes, setBatanes] = useState([]);
    const [mactan, setMactan] = useState([]);

    const [disabledList, setDisabledList] = useState([]);
    const [disabledListPM, setDisabledListPM] = useState([]);


    useEffect(() => {
        getWorkshops()

    }, [setUserLabel])

    const getName = () => {
        if (props.speakerSession) {
            // setUserLabel("Speaker")
            return ("Speaker")
        }
        if (props.boardMember) {
            // setUserLabel("Board Member")
            return ("Board Member")
        }
        return ("Participant")
    }
    const getWorkshops = () => {

        axios.get(`${env.API_URL}/metadata/workshops`)
            .then((res) => {
                const tempBoracay = []
                const tempBatanes = []
                const tempMactan = []
                const temp = []

                res.data.map((item) => {
                    console.log(item)
                    // const regular = item.regular
                    // const handsOn = item.handsOn
                    // switch (props.userData.categoryId) {
                    //     case "PDS Resident - Accredited Institution":
                    //         handsOn = item.residentsAccreditedHandson
                    //         regular = item.residentsAccreditedRegular
                    //         break;
                    //     case "PDS Resident - Applicant Institution":
                    //         handsOn = item.residentsApplicantHandson
                    //         regular = item.residentsApplicantRegular
                    //         break;
                    //     default:
                    //         break;
                    // }

                    temp.push({
                        ...item,
                        // regular, 
                        // handsOn,
                        selectedHandsOn: false,
                        selectRegular: false
                    })
                    switch (item.assignment) {
                        case 'Boracay':
                            tempBoracay.push(item)
                            break;
                        case 'Batanes':
                            tempBatanes.push(item)
                            break;
                        case 'Mactan':
                            tempMactan.push(item)
                            break;
                        default:
                            break;
                    }
                })
                props.setWorkshops(temp)
                setBoracay(tempBoracay)
                setBatanes(tempBatanes)
                setMactan(tempMactan)
            }).catch((e) => {
                NotificationManager.error('Please try again later', '', 5000);
            })
    }

    const getFormattedString = (value) => {
        var re = '\\d(?=(\\d{' + 3 + '})+' + '\\D' + ')';
        var num = value.toFixed(Math.max(0, ~~2));
        var str = num.replace(new RegExp(re, 'g'), '$&' + ',');
        return str;
    }

    const handleChange = (selectedHandsOn, selectRegular, id) => {
        const temp = props.workshops.map((item) => {
            if (item._id === id) {
                const temp = {
                    ...item,
                    selectedHandsOn: selectedHandsOn,
                    selectRegular: selectRegular
                }
                if (!selectedHandsOn && !selectRegular) {
                    props.setCheckoutSelected(props.checkoutSelected.filter((dt) => dt._id !== item._id))
                } else {
                    if (props.checkoutSelected.length < 1) {
                        props.setCheckoutSelected([temp])
                    } else {
                        // ADD TO CHEKOUT
                        // EDIT THE ITEM IF ITS IN CHECKOUT
                        props.setCheckoutSelected([

                            ...props.checkoutSelected.filter((dt) => dt._id !== item._id),
                            temp
                        ])
                    }
                }
                return temp
            } else {
                return item
            }
        })
        props.setWorkshops(temp);
    };

    // console.log('checkoutSelectedcheckoutSelected', props.selected)

    const getTotal = () => {
        let price = 0
        props.checkoutSelected.map((item) => {
            if (item.selectedHandsOn) {
                price = price + item.handsOn
            } else if (item.selectRegular) {
                price = price + item.regular
            }
        })
        return price
    }

    const getSelectedSessions = () => {
        const temp = []
        props.checkoutSelected.map((item) => {
            if (item.selectedHandsOn) {
                temp.push({
                    id: item._id,
                    title: item.title,
                    assignment: item.assignment + " Room",
                    startTime: item.startTime,
                    endTime: item.endTime,
                    type: "Hands On",
                    price: 'Php ' + getFormattedString(item.handsOn)
                })
            } else if (item.selectRegular) {
                temp.push({
                    id: item._id,
                    title: item.title,
                    assignment: item.assignment + " Room",
                    startTime: item.startTime,
                    endTime: item.endTime,
                    type: "Regular",
                    price: 'Php ' + getFormattedString(item.regular)
                })
            }
        })
        return temp
    }

    const handleSetSelected = (workshop, type) => {
        if (props.selected.length > 0) {
            props.selected.map((dt) => {
                if (dt._id === workshop._id) {

                    // REMOVE SELF IF IN LIST
                    if (type) {
                        const temp = []
                        props.selected.map((item) => {
                            // console.log(item._id, workshop._id)
                            if (item._id !== workshop._id) {
                                temp.push(item)
                            }
                        })
                        return props.setSelected(temp)
                    }
                    return
                } else {
                    const temp = []

                    props.selected.map((item) => {
                        if (item._id !== workshop._id) {
                            temp.push(item)
                        }
                    })

                    props.setSelected([
                        ...temp,
                        workshop
                    ])
                    return
                }
            })
        } else {

            props.setSelected((prev) => [
                ...prev,
                workshop
            ])
            return
        }
    }
    console.log('props.disabledList', props.selected)

    return (
        <div className={props.currentForm === 0.5 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
            <NotificationContainer />

            <Modal
                open={open}
                onClose={() => { setOpen(false) }}
                center
            >
                <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem', marginBottom: '2rem' }}>Select delegate type</h2>
                    <fieldset>
                        <div className="row">

                            <div className="col-6">
                                <div className="session-wrapper" style={{
                                    height: '100%',
                                    display: 'grid'
                                }}>

                                    <div className="session-group">

                                        <div className="session-form col-12 p-2" style={{ opacity: 1 }}>
                                            <div style={{ width: "10%" }}>
                                                <input
                                                    type="radio"
                                                    style={{ width: 30, height: 30 }}
                                                    className="radio mr-4"
                                                    name='delegate_select'
                                                    // name={workshop.assignment}
                                                    // disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                    onChange={(e) => {

                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: "90%" }}>
                                                <p style={{ marginLeft: 10 }}> <font style={{ fontSize: 30 }}>Regular Delegate</font><br></br><br></br> <font>Participants will be able to learn and observe workshops onsite and through live streaming via LED screen</font></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="session-wrapper">
                                    <div className="session-group">

                                        <div className="session-form col-12 p-2" style={{ opacity: 1 }}>
                                            <div style={{ width: "10%" }}>
                                                <input
                                                    type="radio"
                                                    style={{ width: 30, height: 30 }}
                                                    className="radio mr-4"
                                                    name='delegate_select'
                                                    // name={workshop.assignment}
                                                    // disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                    onChange={(e) => {

                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: "90%" }}>
                                                <p style={{ marginLeft: 10 }}> <font style={{ fontSize: 30 }}>Hands-On Delegate</font><br></br><br></br> <font >Participants have the unique opportunity to learn and perform the procedures on live patients with direct supervision by speakers</font></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>


                    {/* <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
                        <button
                            onClick={(e) => {
                                setOpen(false)
                                props.setFellowship(false)
                                props.handleNext('step_2_5');
                            }}
                            className="button-next"
                            style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
                        >
                            No
                        </button>
                        <button
                            onClick={(e) => {
                                setOpen(false)
                                // props.setFellowship(true)
                                // props.handleNext('step_2_5');

                                // props.setSessions(selected);
                                // props.handleNext('step_3');
                            }}
                            className="button-next-active"
                            style={{ width: '120px', borderRadius: 5 }}
                        >
                            Yes
                        </button>
                    </div> */}
                </div>
            </Modal>

            <div className="wizard-forms">
                <div className="inner pb-30 clearfix">
                    <div className="form-content pera-content">
                        <div className="step-inner-content">
                            <span className="step-no bottom-line">Step 2</span>
                            <div className="step-progress float-right">
                                <span>2 of 4 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '40%' }} />
                                    </div>
                                </div>
                            </div>
                            <h2>Hello {getName()}, select your workshop</h2>
                            <p>(You cannot attend sessions that have 0 slots remaining)</p>
                            <br></br>
                            <p style={{ marginLeft: 10 }}><font > <font style={{ fontSize: 30 }}>Hands-On Delegate </font>  </font><br></br> <font >Participants have the unique opportunity to learn and perform the procedures on live patients with direct supervision by speakers</font></p>
                            <br></br>
                            <p style={{ marginLeft: 10 }}><font > <font style={{ fontSize: 30 }}>Regular Delegate </font>  </font><br></br><font>Participants will be able to learn and observe workshops onsite and through live streaming via LED screen</font></p>


                            <div className="form-inner-area">
                                <div className="row py-3" style={{ marginTop: 16 }}>

                                    <div className="col-12">
                                        <div className="session-wrapper">

                                            {props.noSlotsAvailable &&
                                                <p style={{ color: 'red' }}>You have exceeded the maximum slots</p>

                                            }
                                            <div className="session-group">
                                                <div className="d-none d-md-block d-lg-block d-sm-none">
                                                    <div className="row col-12 p-2">
                                                        <div className="col-lg-4">
                                                            <p>Workshops</p>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <p>Hands On</p>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <p>Regular</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <p className="d-none d-md-block d-lg-block d-sm-none ">Php {getFormattedString(parseInt(workshop.handsOn))} </p>
                                                <p className="d-block d-sm-block d-md-none d-lg-none">Php {getFormattedString(parseInt(workshop.handsOn))} - Hands On</p> */}


                                                {props.workshops.filter((item) => item.registrationType === "precon").map((workshop, index) => {
                                                    let isInDisabledList = false
                                                    let isPurchased = false

                                                    // props.selected[0]?.disabledList?.map((e) => {
                                                    //     console.log(e,workshop._id )
                                                    //     if (e === workshop._id) {
                                                    //         isInDisabledList = true
                                                    //     }
                                                    // })
                                                    props.disabledList.map((dt) => {
                                                        if (dt === workshop._id) {
                                                            isInDisabledList = true
                                                            isPurchased = true
                                                        } else {
                                                            isInDisabledList = false
                                                        }
                                                    })
                                                    // DISABLED PURCHASED DISABLED LIST


                                                    // ONLY 2 WORKSHOPS CAN BE SELECTED
                                                    // if ((props.registeredWorkshops.length + props.checkoutSelected.length) > 1) {
                                                    //     if (!workshop.selectedHandsOn && !workshop.selectRegular) {
                                                    //         isInDisabledList = true
                                                    //     } else {
                                                    //         isInDisabledList = false

                                                    //     }
                                                    // }

                                                    props.checkoutSelected.map((dt) => {
                                                        dt.disabledList.map((e) => {
                                                            if (e === workshop._id) {
                                                                isInDisabledList = true
                                                            }
                                                        })
                                                    })

                                                    // DISABLED PURCHASED
                                                    if (isPurchased) {
                                                        isInDisabledList = true
                                                    }


                                                    // if (workshop.handsOnCap === 0 || workshop.regularCap === 0) {
                                                    //     isInDisabledList = true
                                                    // }

                                                    return (

                                                        <div className="session-form row col-12 p-2" key={workshop._id} style={{ marginBottom: 20, opacity: isInDisabledList ? 0.5 : 1 }}>

                                                            <div className="col-lg-4 col-xs-12" style={{ paddingRight: 10, marginBottom: 10 }}>
                                                                <p >{index + 1}. {workshop.title}<br></br> <font style={{ fontSize: 15 }}>({workshop.startTime} - {workshop.endTime} )</font></p>
                                                                {isPurchased &&
                                                                    <p >Purchased</p>
                                                                }
                                                            </div>


                                                            <div className="col-lg-4 col-xs-6" >
                                                                {/* <div className="d-block d-sm-block d-md-none d-lg-none">
                                                                    <p>Hands On</p>
                                                                </div> */}
                                                                <div className="w-100" style={{ opacity: workshop.handsOnCap < 1 ? 0.5 : 1, pointerEvents: workshop.handsOnCap < 1 ? 'none' : 'all', display: 'flex', flexDirection: 'row' }} >

                                                                    <div
                                                                        id={index}
                                                                        type="checkbox"
                                                                        // value={workshop.selectedHandsOn}
                                                                        checked={workshop.selectedHandsOn}
                                                                        className={`mr-4 checkbox-custom ${workshop.selectedHandsOn ? 'checked' : ''}`}

                                                                        // className="radio mr-4"
                                                                        // style={{ width: 30, height: 30, opacity: workshop.selectedHandsOn? 1 : 0.5 }}
                                                                        name={workshop.title}
                                                                        // name={workshop.assignment}
                                                                        disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            if (isInDisabledList) return


                                                                            handleChange(!workshop.selectedHandsOn, false, workshop._id)

                                                                            handleSetSelected(workshop, !workshop.selectRegular)
                                                                        }}
                                                                    />
                                                                    <div>
                                                                        <p className="d-none d-md-block d-lg-block d-sm-none ">Php {getFormattedString(parseInt(workshop.handsOn))} </p>
                                                                        <p className="d-block d-sm-block d-md-none d-lg-none">Php {getFormattedString(parseInt(workshop.handsOn))} - Hands On</p>

                                                                        <p ><font style={{ color: workshop.handsOnCap < 1 ? 'red' : 'grey', fontSize: 15 }}>(Slots Remaining: {workshop.handsOnCap < 1 ? 0 : workshop.handsOnCap})</font></p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {workshop.regular > 0 &&
                                                                <div className="col-lg-4 col-xs-6">
                                                                    {/* <div className="d-block d-sm-block d-md-none d-lg-none">
                                                                        <p>Regular</p>
                                                                    </div> */}
                                                                    <div className="w-100" style={{ opacity: workshop.regularCap < 1 ? 0.5 : 1, pointerEvents: workshop.regularCap < 1 ? 'none' : 'all', display: 'flex', flexDirection: 'row' }} >
                                                                        <div
                                                                            id={index}
                                                                            type="checkbox"
                                                                            // className="radio mr-4"
                                                                            className={`mr-4 checkbox-custom ${workshop.selectRegular ? 'checked' : ''}`}

                                                                            // style={{ width: 30, height: 30, opacity: workshop.selectRegular? 1 : 0.5 }}
                                                                            name={workshop.title}
                                                                            checked={workshop.selectRegular}
                                                                            // value={workshop.selectRegular}
                                                                            disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (isInDisabledList) return


                                                                                handleChange(false, !workshop.selectRegular, workshop._id)

                                                                                handleSetSelected(workshop, !workshop.selectedHandsOn)

                                                                            }}
                                                                        />

                                                                        <div>
                                                                            <p className="d-none d-md-block d-lg-block d-sm-none ">Php {getFormattedString(parseInt(workshop.regular))} </p>
                                                                            <p className="d-block d-sm-block d-md-none d-lg-none">Php {getFormattedString(parseInt(workshop.regular))} - Regular</p>

                                                                            {/* <p >Php {getFormattedString(parseInt(workshop.regular))} <p style={{fontSize: 7}} className="d-block d-sm-block d-md-none d-lg-none">Regular</p></p> */}
                                                                            <p ><font style={{ color: workshop.regularCap < 1 ? 'red' : 'grey', fontSize: 15 }}>(Slots Remaining: {workshop.regularCap < 1 ? 0 : workshop.regularCap})</font></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    )
                                                })
                                                }

                                                {!props.noSlotsAvailable &&

                                                    <div style={{ marginTop: 20 }} className="row col-12 p-2">
                                                        <div className="col-lg-6 col-sm-12">
                                                            {/* <p>Workshops</p> */}
                                                        </div>
                                                        <div className="col-lg-6 col-sm-12" >
                                                            <h3 style={{ textAlign: 'right', width: '100%' }}>Total: Php {getFormattedString(getTotal())}</h3>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="actions">
                    <ul>
                        {/* <li>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.handleBack('step_2');
                                }}
                                className="themesflat-button bg-accent js-btn-prev"
                            >
                                BACK

                            </button>
                        </li> */}
                        {!props.noSlotsAvailable &&
                            <>
                                {props.checkoutSelected.length > 0 &&
                                    <li>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.setSessions(getSelectedSessions());
                                                props.handleNext('step_2_5');
                                                props.setTotal(getTotal())
                                                // setOpen(true)
                                            }}
                                            className={'themesflat-button bg-accent'}
                                            disabled={props.noSlotsAvailable ? true : props.checkoutSelected.length === 0}
                                        >
                                            NEXT

                                        </button>
                                    </li>
                                }
                            </>

                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}