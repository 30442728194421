import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import { FormContact, Information } from '../layouts/contact';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index'
import { Slider, TitleHeading, IconBox, MainContent, About, SubContent, Service, Project, ServicesBox, Testimonial, Overview, Faq, Partner } from '../layouts/home01/index'

class Guidelines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_mobile.png",
                    names: "Guidelines",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Guidelines',
                    heading: 'Abstract Submission Guidelines'
                }
            ]
        }
    }
    render() {
        const myStyle = {
            backgroundImage:
                "url('assets/img/slider/bg.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div style={myStyle} >
                            <Slider />
                        </div>
                        <div id="site-header-wrap" style={{height: "61px"}}>
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data, idx) => (
                                    <Header data={data} key={idx} />
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <div className="row-contact">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div style={{ height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                            <h2 style={{ lineHeight: 1.5, margin: 10 }}>ABSTRACT SUBMISSION GUIDELINES</h2>
                                                            <p>
                                                                <br /><br />1. Please register and submit your abstract online.
                                                                <br /><br />2. Indicate intent of abstract to be present either as “Free Communications” or "E-poster".
                                                                <br /><br />3. The abstract must be written in English in not more than 250 words. This word limit refers to the body of the abstract and does not include the authors' names, affiliations, and title of abstract.
                                                                <br /><br />4. Name of the authors in upper and lower cases, surname in capital.  Department/Hospital/Institution/Country of first author only.
                                                                <br /><br />5. All abstracts must be submitted electronically. Typed, hand-written, incomplete, faxed or late entries will not be accepted. International submissions are to use their family name when "last" name is requested.
                                                                <br /><br />6. Use of standard abbreviations is desirable. Place special or unusual abbreviations in brackets after the full word the first time it appears. Use numerals to indicate numbers, except to begin sentences.
                                                                <br /><br />7. Do not include graphs, tables and references in the abstract.
                                                            </p>
                                                            <br /><br /> <h2 style={{ lineHeight: 1.5, margin: 10 }}>IMPORTANT INFORMATION:</h2>
                                                            <p>
                                                                <br /><br />1. Only authors who have paid the registration fee are entitled to submit an abstract for Poster or Free Communications.
                                                                <br /><br />2. Abstracts are subject to the approval of the Scientific Sub-committee.
                                                                <br /><br />3. Contents of the abstract must not have been presented, submitted or accepted for presentation at another meeting or previously published in any journal.
                                                                <br /><br />4. Potential conflicts of interest must be indicated at the point of abstract submission.
                                                                <br /><br />5. The Scientific Committee reserves the right to assign an accepted “E-poster” abstract to be presented under the “Free Communications” category and vice versa.
                                                                <br /><br />6. By submitting an abstract in the “Free Communications” category, you agree to make yourself available for the presentation slot to which your abstract is assigned, and the Q&A session that follows.
                                                                <br /><br />7. Submission deadline for abstracts is on July 30, 2022.
                                                                <br /><br />8. Notification of acceptance or rejection will be sent via email by August 15, 2022.
                                                                <br /><br />9. Accepted abstracts will be featured in the conference E-book of abstracts.
                                                            </p>
                                                        </div>

                                                        {/* <FormContact /> */}
                                                        {/* <Information /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />

                    {/* <Footer /> */}
                    {
                        this.state.headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Guidelines;