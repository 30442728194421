import React, {Component, useEffect, useState} from 'react';
import SendMessage from './sendMessage';

class FormContact extends Component {
  
  render() {
    return (      
      <div>
        <div className='row'>

        <div className="col-md-4">
          <div className="themesflat-headings style-2 clearfix">
            <h2 className="heading">CONTACT US</h2>
            <div className="sep has-width w80 accent-bg clearfix" />
            <p className="sub-heading">
              <br />
              <font style={{ fontWeight: 'bold' }}>Registration Committee</font>
              <br />
              Email: registration@pdsconvention2023.com

              <br />
              <br />
              <br />
              <font style={{ fontWeight: 'bold' }}>Research Committee</font>
              <br />
              Email: research@pdsconvention2023.com

              <br />
              <br />
              <br />
              <font style={{ fontWeight: 'bold' }}>Scientific Programme Committee</font>
              <br />
              Email: scientific@pdsconvention2023.com

              <br />
              <br />
              <br />
              <font style={{ fontWeight: 'bold' }}>Convention Secretariat</font>
              <br />
              Email: info@pdsconvention2023.com

            </p>
          </div>
          <div className="themesflat-spacer clearfix" data-desktop={36} data-mobile={35} data-smobile={35} />

          <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
        </div>

        <SendMessage />
        
        </div>
        <div>
                  <div className='row mt-5 justify-content-md-center'>
                      <div>
                        <p className='mt-5 center' style={{textAlign:'center'}}> <b>PHILIPPINE DERMATOLOGICAL SOCIETY</b> <br />
                        73 Malakas St., Barangay Pinyahan, Quezon City <br />
                        Telefax No.: (632) 8727-7309 <br />
                        Website: www.pds.org.ph <br />
                        E-mail: secretariat@pds.org.ph
                        </p>
                      </div>
                  </div>
        </div>
      </div>
    
    );
  }
}

export default FormContact;
