import React, {useState} from 'react';
import Alert from 'react-bootstrap/Alert';

export default function SendMessage(){
    const [name,setName]=useState("");
    const [phone,setPhone]=useState("");
    const [email,setEmail]=useState("");
    const [message,setMessage]=useState("");
    const [receipientEmail,setReceipientEmail]=useState("");
    const [subject,setSubject]=useState("");
    const [toaster,setToaster]=useState("");
    const [toastkey,setToastkey]=useState("");

    function changeEmail(e){

      setSubject(e.target.value)
      if(e.target.value === "Registration"){
        setReceipientEmail("registration@pdsconvention2023.com")
      }
      else if(e.target.value === "Research"){
        setReceipientEmail("research@pdsconvention2023.com")
      }
      else if(e.target.value === "Program"){
        setReceipientEmail("scientific@pdsconvention2023.com")
      }
      else {
        setReceipientEmail("info@pdsconvention2023.com")
      }
    };

    function submitMessage(e){
      e.preventDefault();
      console.log(name,phone,email,message,subject,receipientEmail);

        fetch('https://api.pdsconvention2023.com/metadata/contactus', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						email: email,
						message: message,
						name: name,
						phone: phone,
						receipientEmail: receipientEmail,
            subject: subject,
					})
				})
				.then(res => res.json())
				.then(data => {
					setToaster("Message has been sent")
          setToastkey("success");          
				})
        .catch(error => {
          setToaster("Message sending has encountered a problem, please call number below to inform request")
          setToastkey("danger");
        });        
    }
    
    return(
        <div className="col-md-8 themesflat-contact-form style-2 clearfix">
          <Alert variant={toastkey} className='pt-3 pb-1'>
            <Alert.Heading>
              {toaster}
            </Alert.Heading>
          </Alert>
          <div style={{backgroundColor: '#fbfbfb', padding: 20}}>
            <form action="#" method="post" className="form-submit contact-form wpcf7-form">
              <span className="wpcf7-form-control-wrap your-name">
                <input style={{backgroundColor: 'white'}} type="text" tabIndex={1} id="name" name="name" defaultValue={""} className="wpcf7-form-control"
                  placeholder="Name*" value={name} onChange={(e)=>setName(e.target.value)} required />
              </span>
              <span className="wpcf7-form-control-wrap your-phone">
                <input style={{backgroundColor: 'white'}} type="text" tabIndex={2} id="phone" name="phone" defaultValue={""} className="wpcf7-form-control"
                  placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
              </span>
              <span className="wpcf7-form-control-wrap your-email">
                <input style={{backgroundColor: 'white'}} type="email" tabIndex={3} id="email" name="email" defaultValue={""} value={email} onChange={(e)=>setEmail(e.target.value)}
                  className="wpcf7-form-control" placeholder="Your Email*" required />
              </span>
              <span className="wpcf7-form-control-wrap your-subject">
              <select onChange={changeEmail} >
                  <option value="" disabled selected hidden>Subject</option>
                  <option value="Registration">Registration</option>
                  <option value="Research">Research</option>
                  <option value="Program">Program</option>
                  <option value="Others">Other Concerns</option>
                </select>
                
              </span>
              <span className="wpcf7-form-control-wrap your-message">
                <textarea style={{backgroundColor: 'white'}} name="message" tabIndex={5} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea"
                  placeholder="Message*" required defaultValue={""} onChange={(e)=>setMessage(e.target.value)}/>
              </span>
              <span className="wrap-submit">
                <input defaultValue="SEND US" className="submit wpcf7-form-control wpcf7-submit" type='submit' onClick={submitMessage}/>
              </span>
            </form>
          </div>
        </div>
    )
}