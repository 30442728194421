import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import bannerhome1 from './data_banner';
import sliderSmall from './data_slider';
import { Modal } from 'react-responsive-modal';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
SwiperCore.use([Autoplay]);


class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            imageUrl: ""
        }
    }

    render() {
        const { data } = this.props;
        const mainContent = () => {
            if (data !== "slider-small") {
                return (
                    <>
                        {
                            bannerhome1.map(data => (
                                <li data-transition="random" key={data.id} style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={data.srcimg} alt="altimage" style={{ objectFit: "contain", maxWidth: '100%' }} />
                                </li>
                            ))
                        }
                    </>
                )

            } else {
                return (
                    <div style={{
                        // paddingBottom: '7rem'
                    }}>
                        <Modal
                            open={this.state.showModal}
                            center
                            showCloseIcon={false}
                            onClose={() => this.setState({
                                showModal: false
                            })}
                        >


                            <div style={{ height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img style={{ maxWidth: 'none', width: '100%' }} src={this.state.imageUrl} alt="imagealt" />
                            </div>
                        </Modal>

                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            centeredSlides
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            showsPagination={true}
                        >
                            {
                                sliderSmall.map(data => (
                                    <SwiperSlide
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <div className="row d-flex justify-content-center align-items-center">

                                            <div className="thumb data-effect-item">
                                                <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                <ul className="socials clearfix">
                                                    <li className="facebook" style={{
                                                        color: "white", width: "100%",
                                                        height: "100%"
                                                    }} onClick={() => {
                                                        this.setState({
                                                            showModal: true,
                                                            imageUrl: data.srcimg
                                                        })
                                                    }}>More Info</li>
                                                </ul>
                                                <div className="overlay-effect bg-color-4" onClick={() => {
                                                    this.setState({
                                                        showModal: true,
                                                        imageUrl: data.srcimg
                                                    })
                                                }} />
                                            </div>
                                        </div>

                                        {/* <img style={{ maxWidth: '100%', maxHeight: '500px', margin: '0 auto' }} src={data.srcimg} alt="altimage" data-bgposition="center center" data-no-retina /> */}
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>

                    </div>
                )
            }
        }
        return (

            <ul>
                {mainContent()}
            </ul>
        );
    }
}

export default withRouter(Slider);