
import React, { useState } from 'react';
import Step1 from './step/step-1';
import Step2 from './step/step-2';
import Step3 from './step/step-3';
import Step4 from './step/step-4';
import "../assets/scss/style.scss";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Slider } from '../../layouts/home01/index'
import { Header, BottomBar } from '../../layouts/general/index'


export default function AbstractSubmission(props) {

    const [currentForm, setCurrentForm] = useState(1)
    const [asd, setAsd] = useState("")
    const [pds, setPds] = useState("")
    const [pdsNumber, setPdsNumber] = useState("")
    const [asdEmail, setAsdEmail] = useState("")
    const [showAuthors, setShowAuthors] = useState(false)


    const [middleName, setMiddleName] = useState("")
    const [proSuffix, setProSuffix] = useState("")
    const [suffix, setSuffix] = useState("")
    const [certName, setCertName] = useState("")
    const [password, setPassword] = useState("")
    const [repassword, setRePassword] = useState("")
    const [prcNumber, setPrcNumber] = useState("")

    const [hasUpload, setHasUpload] = useState(false)
    const [paymentType, setPaymentType] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [letterUrl, setLetterUrl] = useState("")
    const [hasAbstract, setHasAbstract] = useState(false)



    const [title, setTitle] = useState("")
    const [abstract, setAbstract] = useState("")
    const [conflictOfInterest, setConflictOfinterest] = useState("")
    const [commericalFunding, setCommercialFunding] = useState("")
    const [keywords, setKeywords] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [isLocal, setIsLocal] = useState("")
    const [institution, setInstitution] = useState("")


    const [author2FirstName, setAuthor2FirstName] = useState("")
    const [author2LastName, setAuthor2LastName] = useState("")
    const [author3FirstName, setAuthor3FirstName] = useState("")
    const [author3LastName, setAuthor3LastName] = useState("")
    const [author4FirstName, setAuthor4FirstName] = useState("")
    const [author4LastName, setAuthor4LastName] = useState("")
    const [author5FirstName, setAuthor5FirstName] = useState("")
    const [author5LastName, setAuthor5LastName] = useState("")

    const [form, setForm] = useState({
        presentation: 'eposters',
        research_category: '',

        corresponding_author_last_name: '',
        corresponding_author_first_name: '',
        corresponding_author_middle_name: '',
        corresponding_author_email: '',

        author_0_last_name: '',
        author_0_first_name: '',
        author_0_middle_name: '',
        author_0_aspcr: '',
        author_0_position: '',
        author_0_institution: '',
        author_0_department: '',
        author_0_country: '',

        author_1_last_name: '',
        author_1_first_name: '',
        author_1_middle_name: '',
        author_1_aspcr: '',
        author_1_position: '',
        author_1_institution: '',
        author_1_department: '',
        author_1_country: '',

        author_2_last_name: '',
        author_2_first_name: '',
        author_2_middle_name: '',
        author_2_aspcr: '',
        author_2_position: '',
        author_2_institution: '',
        author_2_department: '',
        author_2_country: '',

        author_3_last_name: '',
        author_3_first_name: '',
        author_3_middle_name: '',
        author_3_aspcr: '',
        author_3_position: '',
        author_3_institution: '',
        author_3_department: '',
        author_3_country: '',

        author_4_last_name: '',
        author_4_first_name: '',
        author_4_middle_name: '',
        author_4_aspcr: '',
        author_4_position: '',
        author_4_institution: '',
        author_4_department: '',
        author_4_country: '',

        study_design: '',
        title: '',
        abstract: '',
        keywords: '',
        conflictofinterest: '',
        commercialfunding: '',
        consenttoshare: false
    })

    const handleSetAll = (val) => {
        setForm(val.data)
    }

    const handleNext = () => {
        // console.log(firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution)
        setCurrentForm(currentForm + 1)

    }
    const handleBack = () => {
        setCurrentForm(currentForm - 1)
    }

    const myStyle = {
        backgroundImage:
            "url('assets/img/slider/bg.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };


    const data = {
        headers: [
            {
                id: 1,
                logoweb: "assets/img/logo_mobile.png",
                names: "Home",
            }
        ],
        headingchoose: [
            {
                id: 1,
                classnames: 'heading',
                title: 'Registration Fees and Deadlines',
                text: 'More than 500 projects completed in Autora - With over 25 years of combined experience, we have the knowledge to serve you.',
                classtext: 'sub-heading',
            }
        ],
        headingservice: [
            {
                id: 1,
                classnames: 'heading',
                title: 'BEST SERVICES 1',
                text: 'Construction Services offers a full range of construction services from initial design to project completion.',
                classtext: 'sub-heading',
            }
        ],
        headingproject: [
            {
                id: 1,
                classnames: 'heading text-white',
                title: 'FEATURED PROJECT',
                classtext: 'sub-heading',
            }
        ],
        servicebox: [
            {
                id: 1,
                classnames: 'heading font-size-30',
                title: 'ALL SERVICES',
                classtext: 'sub-heading font-weight-400',
                text: 'Are you interested in finding out how we can make your project a success? Please constact us.'
            }

        ],
        banners: [
            {
                id: 1,
                links: '',
                titlelink: '',
                border: '',
                name: 'Abstract Submission',
                heading: 'Abstract Submission'
            }
        ]
    }

    return (

        <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
            {isLoading &&
                <div style={{ backgroundColor: '#000000a8', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', flexDirection: 'column', zIndex: 20000, position: 'absolute' }}>
                    <h6 style={{ color: 'white', margin: 20 }}>LOADING...</h6>
                    <Dots color={'white'} size={50} />
                </div>
            }

            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    {/* <div className="rev_slider_wrapper fullwidthbanner-container">
                    <div id="rev-slider1" className="rev_slider fullwidthabanner"> */}
                    <div style={myStyle} >
                        <Slider />
                    </div>
                    {/* </div>
                </div> */}


                    <div id="site-header-wrap">
                        {/* <TopBar /> */}
                        {
                            data.headers.map((data, idx) => (
                                <Header data={data} key={idx} />
                            ))
                        }
                    </div>
                    {/* {
                            data.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        } */}

                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div className="wrapper">
                                <div className="multisteps-form__form" style={{ width: '100%' }} >
                                    <div className="form-area position-relative">
                                        {/* <Step1
                                            pds={pds}
                                            pdsNumber={pdsNumber}
                                            asdEmail={asdEmail}
                                            asd={asd}
                                            isLocal={isLocal}
                                            currentForm={currentForm}
                                            handleNext={handleNext}
                                            setIsLocal={setIsLocal}
                                            setAsd={setAsd}
                                            setPds={setPds}
                                            setPdsNumber={setPdsNumber}
                                            setAsdEmail={setAsdEmail}
                                            setHasUpload={setHasUpload}

                                            setFirstName={setFirstName}
                                            setLastName={setLastName}
                                            setEmail={setEmail}
                                        /> */}
                                        <Step2
                                            hasAbstract={() => {
                                                setHasAbstract(true)
                                            }}
                                            handleSetAll={handleSetAll}
                                            form={form}
                                            setForm={setForm}
                                            setTitle={setTitle}
                                            setAbstract={setAbstract}
                                            setKeywords={setKeywords}
                                            setConflictOfinterest={setConflictOfinterest}
                                            setCommercialFunding={setCommercialFunding}
                                            setFirstName={setFirstName}
                                            setLastName={setLastName}
                                            setEmail={setEmail}
                                            setCountry={setCountry}
                                            setCity={setCity}
                                            setInstitution={setInstitution}
                                            setIsLocal={setIsLocal}
                                            isLocal={isLocal}
                                            firstName={firstName}
                                            lastName={lastName}
                                            email={email}
                                            showAuthors={showAuthors}
                                            setShowAuthors={setShowAuthors}

                                            setAuthor2FirstName={setAuthor2FirstName}
                                            setAuthor2LastName={setAuthor2LastName}
                                            setAuthor3FirstName={setAuthor3FirstName}
                                            setAuthor3LastName={setAuthor3LastName}
                                            setAuthor4FirstName={setAuthor4FirstName}
                                            setAuthor4LastName={setAuthor4LastName}
                                            setAuthor5FirstName={setAuthor5FirstName}
                                            setAuthor5LastName={setAuthor5LastName}

                                            author2FirstName={author2FirstName}
                                            author2LastName={author2LastName}
                                            author3FirstName={author3FirstName}
                                            author3LastName={author3LastName}
                                            author4FirstName={author4FirstName}
                                            author4LastName={author4LastName}
                                            author5FirstName={author5FirstName}
                                            author5LastName={author5LastName}


                                            password={password}
                                            setMiddleName={setMiddleName}
                                            setProSuffix={setProSuffix}
                                            setSuffix={setSuffix}
                                            setCertName={setCertName}
                                            setPassword={setPassword}
                                            setRePassword={setRePassword}
                                            setPrcNumber={setPrcNumber}
                                            currentForm={currentForm}
                                            handleNext={handleNext}
                                            handleBack={handleBack}
                                            hasUpload={hasUpload}

                                            setLetterUrl={setLetterUrl}
                                        />
                                        <Step3
                                            hasAbstract={hasAbstract}
                                            form={form}
                                            data={
                                                {
                                                    firstName,
                                                    lastName,
                                                    title,
                                                    abstract,
                                                    conflictOfInterest,
                                                    commericalFunding,
                                                    keywords,
                                                    email,
                                                    country,
                                                    city,
                                                    isLocal,
                                                    institution,

                                                    author2FirstName,
                                                    author2LastName,
                                                    author3FirstName,
                                                    author3LastName,
                                                    author4FirstName,
                                                    author4LastName,
                                                    author5FirstName,
                                                    author5LastName,
                                                }
                                            }
                                            setIsLoading={setIsLoading}
                                            showAuthors={showAuthors}
                                            setShowAuthors={setShowAuthors}
                                            currentForm={currentForm}
                                            handleNext={handleNext}
                                            handleBack={handleBack}
                                        />
                                        <Step4 data={
                                            { firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution, isLocal, asd, pds, prcNumber, letterUrl, password }
                                        }
                                            isLocal={isLocal}
                                            setIsLoading={setIsLoading}
                                            asd={asd}
                                            pds={pds}

                                            currentForm={currentForm} setPaymentType={setPaymentType} handleNext={handleNext} handleBack={handleBack} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />
                <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                {
                    data.headers.map((data, idx) => (
                        <BottomBar data={data} key={idx} />
                    ))
                }
            </div>
        </div>



    )
};



