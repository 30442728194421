/* eslint-disable react/button-has-type */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'

import { registrationFields } from './constant';

export default function StepTwo(props) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [message3] = useState('');
  const [documentURL, setDocumentURL] = useState(null);
  const [setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const [prcnumber, setPrcNumber] = useState(null);
  const [pdsIdNumber, setPdsIdNumber] = useState(null);
  const [pmaNumber, setPmaNumber] = useState(null);

  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(async () => {
  //     checkEmail(props.email)
  // }, [props.email])
  useEffect(() => {
    // console.log('uefiauaguibawgoipawg', props.selectedOptions)
    setFields(registrationFields[props.selectedOptions.join('_')]);

    const tempFields = registrationFields[props.selectedOptions.join('_')]
    if (tempFields?.includes('philippines')) {
      props.setCountry('Philippines');
    }
  }, [props.selectedOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('documentURL', documentURL)
    if (fields?.includes('upload') || fields?.includes('upload_prc')) {
      if (!documentURL) {
        NotificationManager.error('Document is required.', '', 5000);
        return;
      }
    }

    // CHECK ERROR MESSAGES
    if (error) {
      NotificationManager.error('Email is not valid. Check email field.', '', 5000);
      return;
    }
    if (message3 !== '') {
      NotificationManager.error('PRC Number is not valid.', '', 5000);
      return;
    }


    if (pdsIdNumber) {
      props.setPdsNumber(pdsIdNumber);
    }

    if (prcnumber) {
      props.setPrcNumber(prcnumber);
    }

    if (pmaNumber) {
      props.setPmaNumber(pmaNumber);
    }

    props.setCountry('Philippines');
    props.setLetterUrl(documentURL);


    if (fields?.includes('fellowship')) {
      setOpen(true);
    } else {
      props.handleNext('step_2_5');
    }


    // if (props.selectedOptions.includes('speaker')
    //   || props.selectedOptions.includes('board')
    //   || props.selectedOptions.includes('member')
    // ) {
    //   setOpen(true);
    // } else {
    //   props.handleNext('step_2_5');
    // }

  };

  const checkEmail = (e) => {
    if (e !== '') {
      // CREATE SOURCE
      const body = {
        email: e.toLowerCase(),
      };
      axios.post(`${env.API_URL}/metadata/email`, body).then((res) => {
        try {
          if (res.data.error) {
            setMessage(res.data.error);
            setError(true);
            return false;
          }
          setMessage(res.data.message);
          setError(false);
          return true;
        } catch (e) { }
      }).catch((e) => {
        setMessage(e.response.data.error);
        setError(true);
        return false;
      });
    }
  };

  const uploadImage = (image) => {
    const data = new FormData();
    data.append('file', image);
    data.append("upload_preset", "pds_uploads")
    fetch("https://api.cloudinary.com/v1_1/duuldyiu5/image/upload", {
      method: "post",
      body: data
    })
      .then((resp) => resp.json())
      .then((data) => {
        setDocumentURL(data.url);
        setDocumentName(data.original_filename);
        // props.setLetterUrl(data.url);
        // console.log(data.url, data.original_filename)
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    props.setCategory(value)
  };

  return (
    <form id="wizard" onSubmit={handleSubmit}>
      <NotificationContainer />

      <Modal
        open={false}
        onClose={() => { setOpen(false) }}
        center
      >
        <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

          {/* <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Would you like to attend the Fellowship Night ?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              No
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(true)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Yes
            </button>
          </div> */}

          <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem' }}>Confirm details?</h2>
          <div className="actions" style={{ display: "flex", marginTop: '2rem', bottom: 0, right: 0 }}>
            <button
              onClick={(e) => {
                setOpen(false)
              }}
              className="button-next"
              style={{ marginRight: 10, width: '120px', borderRadius: 5 }}
            >
              Back
            </button>
            <button
              onClick={(e) => {
                setOpen(false)
                props.setFellowship(false)
                props.handleNext('step_2_5');
              }}
              className="button-next-active"
              style={{ width: '120px', borderRadius: 5 }}
            >
              Ok
            </button>
          </div>

        </div>
      </Modal>

      <div className={props.currentForm === 1 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
        {/* div 2 */}
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <span className="step-no bottom-line">Step 2</span>
                <div className="step-progress float-right">
                  <span>2 of 4 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div className="progress-bar" />
                    </div>
                  </div>
                </div>

                <div className="form-inner-area">
                  <p>General Information</p>
                  <div className="row">
                    <div className="col-6">
                      <input disabled={props.email || props.pdsNumber} onChange={(e) => { props.setLastName(e.target.value); }} value={props.lastName} type="text" name="last_name" id="last_name" className="form-control required" minLength="2" placeholder="Last Name *" required autoComplete="off" />
                      {/* <input disabled={props.pdsNumber} onChange={(e) => { props.setMiddleName(e.target.value); }} type="text" name="middle_name" id="middle_name" className="form-control required" placeholder="MI " /> */}
                      <input onChange={(e) => { props.setProSuffix(e.target.value); }} type="text" name="proffesional_suffix" id="proffesional_suffix" className="form-control" minLength="2" placeholder="Middle Name *" />
                    </div>
                    <div className="col-6">
                      <input disabled={props.email || props.pdsNumber} onChange={(e) => { props.setFirstName(e.target.value); }} value={props.firstName} type="text" name="first_name" id="first_name" className="form-control required" minLength="2" placeholder="First Name *" required autoComplete="off" />
                      <input onChange={(e) => { props.setSuffix(e.target.value); }} type="text" name="suffix" id="suffix" className="form-control" minLength="2" placeholder="Suffix " />
                    </div>
                  </div>
                </div>

                <div className="form-inner-area">
                  <p>Please type in how you want your name to appear in the certificate.</p>
                  <input onChange={(e) => { props.setCertName(e.target.value.toUpperCase()); }} type="text" name="cert_name" id="cert_name" className="form-control required" minLength="2" placeholder="Full Name (ex. Juan Dela Cruz)" required autoComplete="off" />
                  <input value={props.country} onChange={(e) => { props.setCountry(e.target.value); }} disabled={fields?.includes('philippines')} type="text" name="country" id="country" className="form-control required" minLength="2" placeholder="Province* " required autoComplete="off" />
                  <input
                    value={props.email}
                    onChange={(e) => {
                      function validEmail(e) {
                        var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                        return String(e).search(filter) !== -1;
                      }

                      if (validEmail(e.target.value)) {
                        checkEmail(e.target.value)
                      } else {
                        setMessage('Invalid Email')
                        setError(true)
                      }

                      props.setEmail(e.target.value);
                    }}
                    // disabled={props.email}
                    type="email"
                    name="email"
                    id="email"
                    className="form-control required"
                    minLength="5"
                    placeholder="Email *"
                    required
                    autoComplete="off"
                  />
                  <p style={{ color: error && 'red', marginBottom: 10 }}>{message}</p>

                  {fields?.includes('prc_number') && (
                    <>
                      <input
                        onChange={(e) => {
                          setPrcNumber(e.target.value.toUpperCase());
                        }}
                        required={fields?.includes('prc_required')}
                        type="text"
                        name="prcnumber"
                        id="prcnumber"
                        className="form-control required"
                        minLength="2"
                        placeholder="Region *"
                        // required
                        autoComplete="off"
                      />
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )}

                  {fields?.includes('pma_number') && (
                    <>
                      <input
                        onChange={(e) => {
                          setPmaNumber(e.target.value.toUpperCase());
                        }}
                        type="text"
                        name="pma_number"
                        id="pma_number"
                        className="form-control required"
                        minLength="2"
                        placeholder="Address"
                        // required
                        autoComplete="off"
                      />
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )}

                  {/* {fields?.includes('pds_id_number') && (
                    <>
                      <input
                        value={props.pdsNumber}
                        onChange={(e) => {
                          setPdsIdNumber(e.target.value.toUpperCase());
                          props.setPdsNumber(e.target.value.toUpperCase())
                        }}
                        type="text"
                        name="pds_id_number"
                        id="pds_id_number"
                        className="form-control required"
                        minLength="2"
                        placeholder="ID Number *"
                        required
                        disabled={props.pdsNumber}
                        autoComplete="off"
                      />
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )} */}

                  {/* {fields?.includes('speaker') && ( */}
                    <>
                      <select
                        style={{ fontSize: 20 }}
                        required>
                        <option value="">
                          Select Type
                        </option>
                        <option value="EAST AVENUE MEDICAL CENTER">Category 1</option>
                        <option value="JOSE R. REYES MEMORIAL MEDICAL CENTER">Category 2</option>
                        <option value="MAKATI MEDICAL CENTER">Category 3</option>
                      </select>
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  {/* )}  */}

                  {fields?.includes('select_applicant_institution') && (
                    <>
                      <select
                        style={{ fontSize: 20 }}
                        required>
                        <option value="">Select Applicant Institution</option>
                        <option value="VALENZUELA MEDICAL CENTER">VALENZUELA MEDICAL CENTER</option>
                        <option value="SOUTHERN ISABELA MEDICAL CENTER">SOUTHERN ISABELA MEDICAL CENTER</option>
                        <option value="QUIRINO MEMORIAL MEDICAL CENTER">QUIRINO MEMORIAL MEDICAL CENTER</option>
                      </select>
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )}

                  {fields?.includes('category') && (
                    <>
                      <select
                        onChange={handleChange}
                        style={{ fontSize: 20 }}
                        required>
                        <option value="">
                          Select Category
                        </option>
                        <option value="Board of Directors">Board of Directors</option>
                        <option value="Council of Advisers">Council of Advisers</option>
                        <option value="Organizing Committee">Organizing Committee</option>
                        <option value="Life Member">Life Member</option>
                      </select>
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )}

                  {fields?.includes('select_lads') && (
                    <>
                      <select
                        style={{ fontSize: 20 }}
                        required>
                        <option value="">
                          Select League of ASEAN Dermatological Societies (LADS)
                        </option>
                        <option value="Indonesian Society for Dermatology and Venereology">Indonesian Society for Dermatology and Venereology</option>
                        <option value="Dermatological Society of Malaysia">Dermatological Society of Malaysia</option>
                        <option value="Dermatological Society of Singapore">Dermatological Society of Singapore</option>
                        <option value="Dermatological Society of Thailand">Dermatological Society of Thailand</option>
                        <option value="Vietnam Society of Dermatology and Venereology">Vietnam Society of Dermatology and Venereology</option>
                        <option value="Cambodia Dermatological Society">Cambodia Dermatological Society</option>

                      </select>
                      <p style={{ color: 'red', marginBottom: 10 }}>{message3}</p>
                    </>
                  )}

                </div>

                {(fields?.includes('upload') || fields?.includes('upload_prc')) && (
                  <div className="upload-documents">
                    <h3>
                      {/* {
                        fields?.includes('upload_prc')
                          ? 'Upload PRC ID'
                          : 'For residents, upload certificate from Institution Chair or ID.'
                      } */}
                      {
                        fields?.includes('upload_prc') && 'Upload PRC ID'
                      }


                      {
                        fields?.includes('delegate_international_lads') && 'Upload proof of LADS Membership'
                      }
                      {
                        fields?.includes('delegate_international_non_lads') && 'Upload proof of physician licensure'
                      }
                    </h3>
                    <div className="upload-araa bg-white">
                      <input type="hidden" value="" name="fileContent" id="fileContent" />
                      <input type="hidden" value="" name="filename" id="filename" />
                      <div className="upload-icon float-left">
                        <i className="fas fa-cloud-upload-alt" />
                      </div>
                      <div className="upload-text">

                        <span style={{ fontSize: 'large' }}>{documentName || '( Only .PNG and .JPEG formats are allowed. Max. of 20 MB )'}</span>
                      </div>
                      <div className="upload-option text-center">
                        <label htmlFor="attach">Upload The Document</label>
                        <input
                          id="attach"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={(e) => {
                            // setDocument(e.target.files[0]);
                            uploadImage(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <p>By clicking "Next" below, I certify that the above information is true and correct.</p>
                {' '}
                <br />
                <p>Under RA 10173 of the Republic of the Philippines, all personal information collected, stored and processed by this site shall be duty bound to observe and respect your privacy rights.</p>
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleBack('fromtemplate');
                  }}
                  className="themesflat-button bg-accent js-btn-prev"
                >
                  BACK

                </button>
              </li>
              <li>
                <button
                  onClick={(e) => {
                    // if (fields[0])
                    //   props.setCategory(fields[0])
                    // e.preventDefault();
                    props.handleNext('step_3')
                  }}
                  className="themesflat-button bg-accent"
                >
                  NEXT

                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  );
}
