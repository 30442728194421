export const registrationOptions = {
  // SCREEN ONE
  option_1: {
    details: {
      title: 'Are you a attendee or volunteer?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'speaker',
        label: 'Attendee',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400593/GV%20Registration/Icons/Check_perspective_matte.svg',
        nextOption: 'option_2_1'
      },
      {
        value: 'delegate',
        label: 'Volunteer',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400586/GV%20Registration/Icons/Flash_perspective_matte.svg',
        nextOption: 'option_2'
      }
    ]
  },
  // SPEAKERS
  option_2_1: {
    details: {
      title: 'Choose your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'local',
        label: 'Local',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400597/GV%20Registration/Icons/Padlock_perspective_matte.svg',
        // nextOption: 'option_4_5',
        afterOption: 'option_3_2'
      },
      {
        value: 'international',
        label: 'International',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400591/GV%20Registration/Icons/Key_perspective_matte.svg',
        // nextOption: 'validate_email',
        afterOption: 'option_3_2'
      }
    ]

  },

  // DELEGATES
  option_2: {
    details: {
      title: 'Choose your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'local',
        label: 'Local',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400591/GV%20Registration/Icons/Key_perspective_matte.svg',
        // nextOption: 'option_4',
      },
      {
        value: 'international',
        label: 'International',
        icon: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1714400597/GV%20Registration/Icons/Padlock_perspective_matte.svg',
        // nextOption: 'option_3_2'
      }
    ]

  },
  // -------------- PDS CHECK -------------
  option_3_1: {
    details: {
      title: 'Are you a PDS or Non-PDS?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'pds',
        label: 'PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'non_pds',
        label: 'Non-PDS',
        icon: 'assets/img/registration/speaker.png',
      }
    ]
  },

  // DELEGATES ===> INTERNATIONAL
  option_3_2: {
    details: {
      title: 'Select your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.',
      lads: true
    },
    options: [
      {
        value: 'lads',
        label: 'LADS',
        icon: 'assets/img/registration/member.png',
      },
      {
        value: 'non_lads',
        label: 'Non-LADS',
        icon: 'assets/img/registration/nonemember.png',
      }
    ]
  },
  option_4_5: {
    details: {
      title: 'Please enter your PDS ID for validation',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: []
  },
  validate_email: {
    details: {
      title: 'Please enter your email for verification',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: []
  },
  option_4: {
    details: {
      title: 'Select your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'board',
        label: 'BOD, COA, Org Com, Life Member',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'member',
        label: 'Member',
        icon: 'assets/img/registration/member.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'resident',
        label: 'Residents',
        icon: 'assets/img/registration/resident.png',
        nextOption: 'option_5'
      },
      {
        value: 'nonpds',
        label: 'Non-PDS',
        icon: 'assets/img/registration/nonemember.png',
      }
    ]
  },
  option_4_1: {
    details: {
      title: 'Are you a PDS or Non-PDS?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'pds',
        label: 'PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'non_pds',
        label: 'Non-PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_3_2'
      }
    ]
  },
  option_5: {
    details: {
      title: 'Are you from an Accredited Institution or an Applicant Institution?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'accredited',
        label: 'Accredited Institution',
        icon: 'assets/img/registration/accreditedicon.png',
      },
      {
        value: 'pending',
        label: 'Applicant Institution',
        icon: 'assets/img/registration/applicanticon.png',
      }
    ]
  },
};

export const registrationFields = {
  board: [
    'payment',
  ],
  speaker: [
    'payment',
  ],


  speaker_local: [
    'Speaker',

    'pma_number',
    'pds_id_number',
    'philippines',
    'payment',
    'fellowship',
    'precon',
    'prc_number',
  ],
  speaker_international: [
    'Speaker',

    'fellowship',
  ],
  delegate_local_board_pds: [
    null,

    'prc_number',
    'pma_number',
    'pds_id_number',
    'philippines',
    'fellowship',
    'precon',
    'category'
  ],
  delegate_local_member_pds: [
    'PDS Member',

    'prc_number',
    'pma_number',
    'pds_id_number',
    'philippines',
    'payment',
    'fellowship',
    'precon'
  ],
  delegate_local_resident_accredited: [
    'PDS Resident - Accredited Institution',

    'prc_number',
    'select_accredited_institution',
    'philippines',
    'payment'
  ],
  delegate_local_resident_pending: [
    'PDS Resident - Applicant Institution',

    'prc_number',
    'select_applicant_institution',
    'philippines',
    'payment'
  ],
  delegate_local_nonpds: [
    'Non-PDS',

    'prc_number',
    'prc_required',
    'pma_number',
    'upload_prc',
    'upload_required',
    'philippines',
    'payment',
  ],
  delegate_international_lads: [
    'LADS Member',

    'select_lads',
    'upload',
    'upload_required',
    'delegate_international_lads',
    'payment',
    // 'fellowship'
  ],
  delegate_international_non_lads: [
    'Non-LADS Member',

    'upload_required',
    'upload',
    'delegate_international_non_lads',
    'payment',
    // 'fellowship'
  ],
};
