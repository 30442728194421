import React, { useState, useEffect, useCallback } from 'react';
import env from 'react-dotenv';
import { Buffer } from 'buffer';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Cards from 'react-credit-cards-2';
import Iframe from '../util/iframe';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { registrationFields } from './constant';

export default function StepFour(props) {
  const [sourceId, setSourceId] = useState('');
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentType, setPaymentType] = useState('debitcredit');
  const [showNext, setShowNext] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [focus, setFocus] = useState('');
  const [month, setMonth] = useState('00');
  const [year, setYear] = useState('00');

  const [cvc, setCvc] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [price, setPrice] = useState(0);
  const [has3ds, setHas3ds] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState('');

  // const PAYMONGO_SECRET = 'sk_test_ChcgMSSkMAGdudX4ge7DwXRb';
  // const PAYMONGO_KEY = 'pk_test_EKS1RvRLgsgARn8tKVeHTJBr';
  const PAYMONGO_SECRET = "sk_live_hvH96Jhtovb3uJ1DWxrrhE8i"
  const PAYMONGO_KEY = "pk_live_De4avtWTQniYaAYeEN9zn4Li"

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const registerUser = useCallback(async () => {
    NotificationManager.success('Proceeding Registration', '', 5000);

    let converted = props.checkoutSelected.map((item) => {
      if (item.selectRegular) {
        return {
          "id": item._id,
          "type": "regular",
          "title": item.title,
          "assignment": item.assignment,
          "time": item.startTime + " - " + item.endTime
        }
      } else {
        return {
          "id": item._id,
          "type": "handson",
          "title": item.title,
          "assignment": item.assignment,
          "time": item.startTime + " - " + item.endTime
        }
      }

    })
    // const accessToken = localStorage.getItem('accessToken')

    // CREATE SOURCE
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'authorization': 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        workshops: converted,
      })
    };


    fetch(`${env.API_URL}/metadata/register/precon/${props.pds}`, options)
      .then((response) => response.json())
      .then((response) => {
        props.setIsLoading(false);
        if (response.error) {
          NotificationManager.error('Could not register. Contact the system administrator for help.', '', 5000);
          return;
        }
        // REGISTRATION SUCCESS
        props.setCreatedUser(response);
        props.handleNext();
      })
      .catch((err) => {
        props.setIsLoading(false);
        console.error(err);
      });
  });


  const getPrice = () => {
    const amount = props.data.total * 100;

    setPrice(amount)

    if (paymentType === 'debitcredit') {
      createPaymentIntent(amount + (amount * 0.045));
    } else {
      createSource(amount + (amount * 0.025));
    }
  };

  const createSource = async (amount) => {
    // CREATE SOURCE
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(`${PAYMONGO_KEY}:Luis Ramirez`).toString('base64')}`
      },
      body: JSON.stringify({
        data: {
          attributes: {
            redirect: {
              success: 'https://www.pdsconvention2023.com/register/success',
              failed: 'https://www.pdsconvention2023.com/register/success'
            },
            amount,
            currency: 'PHP',
            type: paymentType,
            billing: {
              name: props.data.firstName + props.data.lastName,
              email: 'info@pdsconvention2023.com'
            }
          }
        }
      })
    };
    // console.log(paymentType, props.data.firstName + props.data.lastName, props.data.email);

    fetch('https://api.paymongo.com/v1/sources', options)

      .then((response) => response.json())
      .then((response) => handleResponse(response.data.attributes.redirect.checkout_url, response.data.id, amount))
      .catch((err) => console.error(err));
  };
  const handleResponse = async (url, id, price) => {
    // console.log('handleResponse', id)
    setRedirectLink(url);
    setSourceId(id);
    setPaymentPending(true);
    await getStatusOfPayment(id, price);
  };

  const getStatusOfPayment = async (id, price) => {
    const interval = setInterval(() => {
      checkSource();
    }, 5000);

    let checkSource = async () => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${Buffer.from(`${PAYMONGO_KEY}:Luis Ramirez`).toString('base64')}`
        },
      };

      try {
        const response = await fetch(`https://api.paymongo.com/v1/sources/${id}`, options);
        const responseJson = await response.json();
        if (responseJson.data.attributes.status === 'chargeable') {
          // TRANSFER PAYMENT TO PAYMONGO
          props.setIsLoading(true);
          createPayment(id, price);
          clearInterval(interval);
        }
      } catch (e) { }
    };
  };

  const getStatusOfIntent = async (id, price) => {
    const interval = setInterval(() => {
      checkPaymentIntent();
    }, 5000);
    let checkPaymentIntent = async () => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${Buffer.from(`${PAYMONGO_KEY}:Luis Ramirez`).toString('base64')}`
        }
      };

      try {
        const url = `https://api.paymongo.com/v1/payment_intents/${paymentIntentId}?client_key=${clientKey}`;
        const response = await fetch(url, options);
        const responseJson = await response.json();
        const paymentIntentStatus = responseJson.data.attributes.status;

        if (paymentIntentStatus === 'succeeded') {
          props.setIsLoading(true);
          // createPayment(id, price)
          clearInterval(interval);
          setPaymentPending(false);
          setTimeout(() => {
            registerUser();
          }, 5 * 1000);

          // You already received your customer's payment. You can show a success message from this condition.
        } else if (paymentIntentStatus === 'awaiting_payment_method') {
          // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
        } else if (paymentIntentStatus === 'processing') {
          // You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
        }
      } catch (e) { }
    };
  };

  const createPayment = async (id, price) => {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(`${PAYMONGO_SECRET}:Luis Ramirez`).toString('base64')}`,
      },
      body: JSON.stringify(
        {
          data: {
            attributes: {
              amount: price,
              currency: 'PHP',
              source: { id, type: 'source' },
              description: 'PDS Convention Registration'
            }
          }
        }
      )
    };

    fetch('https://api.paymongo.com/v1/payments', options)
      .then((response) => response.json())
      .then((response) => {
        setPaymentPending(false);
        setTimeout(() => {
          registerUser();
          props.handleNext();
        }, 5 * 1000);
      })
      .catch((err) => console.error(err));
  };

  const createPaymentIntent = async (amount) => {
    setPaymentPending(true);

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(`${PAYMONGO_SECRET}:Luis Ramirez`).toString('base64')}`

      },
      body: JSON.stringify({
        data: {
          attributes: {
            amount,
            payment_method_allowed: ['atome', 'card', 'dob', 'paymaya'],
            payment_method_options: { card: { request_three_d_secure: 'any' } },
            currency: 'PHP',
            capture_type: 'automatic',
            description: 'PDS Convention Registration'
          }
        }
      })
    };

    fetch('https://api.paymongo.com/v1/payment_intents', options)
      .then((response) => response.json())
      .then((response) => {
        setPaymentIntentId(response.data.id);

        // createPaymentMethod(response.data.id)
        setClientKey(response.data.attributes.client_key);
      })
      .catch((err) => console.error(err));
  };

  const createPaymentMethod = async () => {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(`${PAYMONGO_SECRET}:Luis Ramirez`).toString('base64')}`,
      },
      body: JSON.stringify(
        {
          data: {
            attributes: {
              details: {
                card_number: number,
                exp_month: parseInt(month),
                exp_year: parseInt(year),
                cvc
              },
              billing: {
                name: props.data.firstName + props.data.lastName,
                email: props.data.email
              },
              type: 'card'
            }
          }
        }
      )
    };

    fetch('https://api.paymongo.com/v1/payment_methods', options)
      .then((response) => response.json())
      .then((response) => {
        attachPaymentMethod(response.data.id);
      })
      .catch((err) => console.error(err));
  };

  const attachPaymentMethod = (paymentMethodId) => {
    // This PaymentMethod ID must be created before the attach action. This is just a sample value to represent a PaymentMethod
    // PaymentIntent client_key example
    // Get the payment intent id from the client key
    const paymentIntentId = clientKey.split('_client')[0];
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${Buffer.from(`${PAYMONGO_SECRET}:Luis Ramirez`).toString('base64')}`,
      },
      body: JSON.stringify(
        {
          data: {
            attributes: {
              client_key: clientKey,
              payment_method: paymentMethodId,
              // currency: 'PHP',
              // amount: price,
              // payment_method_options: {card: {request_three_d_secure: 'any'}},
            }
          }
        }
      )
    };

    fetch(`https://api.paymongo.com/v1/payment_intents/${paymentIntentId}/attach`, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        const paymentIntentStatus = response.data.attributes.status;

        if (paymentIntentStatus === 'awaiting_next_action') {
          // Render your modal for 3D Secure Authentication since next_action has a value. You can access the next action via paymentIntent.attributes.next_action.
          setPaymentPending(false);
          setRedirectLink(response.data.attributes.next_action.redirect.url);
          setSourceId(response.data.id);
          setHas3ds(true);

          setTimeout(() => {
            setPaymentPending(true);
          }, 1000);
          getStatusOfIntent(response.data.id, price);

          // handleResponse(response.data.attributes.next_action, response.data.id, price)
        } else if (paymentIntentStatus === 'succeeded') {
          // You already received your customer's payment. You can show a success message from this condition.
          setPaymentPending(false);
          props.setIsLoading(true);
          setTimeout(() => {
            props.handleNext();
            registerUser();
          }, 5 * 1000);
        } else if (paymentIntentStatus === 'awaiting_payment_method') {
          // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
        } else if (paymentIntentStatus === 'processing') {
          // You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (props.currentForm === 3) {
      function skipPayment() {
        registerUser();
        props.handleNext()
      }

      const tempFields = registrationFields[props?.data?.selectedOptions.join('_')]
      if (!tempFields?.includes('payment')) {
        skipPayment()
        // alert('afaf')
      }
    }
  }, [props, props.data.selectedOptions, registerUser]);

  return (
    <div className={props.currentForm === 3 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">

      <Modal
        open={paymentPending}
        center
        onClose={() => {
          setPaymentPending(false);
        }}
        classNames={{
          modal: has3ds ? 'customModal' : paymentType === 'gcash' && 'customModal',
        }}
      >

        <div style={{
          height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
        }}
        >
          {paymentType === 'gcash'
            && <Iframe source={redirectLink} />}
          {paymentType === 'debitcredit'
            && (
              <>
                {has3ds
                  ? <Iframe source={redirectLink} />
                  : (
                    <div id="PaymentForm">
                      <h2>Input Card Information</h2>
                      <br />
                      <Cards
                        cvc={cvc}
                        expiry={month + year}
                        focused={focus}
                        name={name}
                        number={number}
                      />
                      <form>
                        <div className="mt-5">
                          <input
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            onChange={(e) => setNumber(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </div>
                        <div className="row ">
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6 pr-1">
                                <select required
                                  onChange={(e) => {
                                    setMonth(e.target.value);
                                  }}
                                  name="expireMM"
                                  id="expireMM"
                                >
                                  <option value="">Month</option>
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                              </div>
                              <div
                                onChange={(e) => {
                                  setYear(e.target.value);
                                }}
                                className="col-6 pl-1"
                              >
                                <select name="expireYY" id="expireYY">
                                  <option value="">Year</option>
                                  <option value="24">2024</option>
                                  <option value="25">2025</option>
                                  <option value="26">2026</option>
                                  <option value="27">2027</option>
                                  <option value="28">2028</option>
                                  <option value="29">2029</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <input
                              type="tel"
                              name="cvc"
                              placeholder="CVC"
                              onChange={(e) => setCvc(e.target.value)}
                              onFocus={(e) => setFocus(e.target.name)}
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          onChange={(e) => setName(e.target.value)}
                          onFocus={(e) => setFocus(e.target.name)}
                        />

                        <ul>
                          <li style={{ width: '100%' }}>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                createPaymentMethod();
                                // setOpen(false)
                                // props.handleNext()
                                // setPaymentPending(false)
                                // NotificationManager.error("Payment not received. Try again.", '', 5000);
                              }}
                              style={{ color: 'black' }}
                              className="w-100"
                            >
                              Submit

                            </button>
                          </li>
                        </ul>

                      </form>
                    </div>
                  )}
              </>
            )}
        </div>
      </Modal>

      <NotificationContainer />

      <div className="wizard-forms">
        <div className="inner pb-50 clearfix">
          <div className="form-content pera-content" style={{ width: '100%' }}>
            <div className="step-inner-content">
              <span className="step-no bottom-line">Step 4</span>
              <div className="step-progress float-right">
                <span>4 of 4 completed</span>
                <div className="step-progress-bar">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '100%' }} />
                  </div>
                </div>
              </div>

              <h2>Payment Method</h2>

              <div className="step-box">
                <div className="row">
                  <div className="col-md-6">
                    <label id="OptionGCASH" className="step-box-content bg-white text-center relative-position">
                      <span className="step-box-icon">
                        <img src="assets/img/registration/member.png" alt="" />
                      </span>
                      <span className="step-box-text">GCash</span>
                      <span className="service-check-option">
                        <span>
                          <input
                            // value="Female"
                            value={paymentType === 'gcash'}
                            onChange={() => {
                              document.getElementById('OptionGCASH').classList.add('active');
                              document.getElementById('OptionCreditCard').classList.remove('active');
                              // document.getElementById("OptionOnlineWallet").classList.remove("active");

                              setPaymentType('gcash');
                              setShowNext(true);
                              props.setPaymentType('gcash');
                            }}

                            type="radio"
                            name="service_name"
                          />
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label id="OptionCreditCard" className="step-box-content bg-white text-center relative-position">
                      <span className="step-box-icon">
                        <img src="assets/img/registration/nonemember.png" alt="" />
                      </span>
                      <span className="step-box-text">Credit Card</span>
                      <span className="service-check-option">
                        <span>
                          <input
                            value={paymentType === 'debitcredit'}
                            onChange={() => {
                              document.getElementById('OptionCreditCard').classList.add('active');
                              document.getElementById('OptionGCASH').classList.remove('active');

                              if (props.isLocal === 'local') {
                                document.getElementById('OptionGCASH').classList.remove('active');
                              }

                              setPaymentType('debitcredit');
                              setShowNext(true);
                              props.setPaymentType('debitcredit');
                            }}
                            type="radio"
                            name="service_name"
                          />
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <ul className='row'>
            <li className='col-lg-6 col-sm-12'>
              <button
                style={{ width: '100%' }}
                onClick={(e) => {
                  props.handleBack();
                }}
                className="themesflat-button bg-accent js-btn-prev"
              >
                BACK

              </button>
            </li>
            <li className='col-lg-6 col-sm-12'>
              <button
                style={{ width: '100%' }}
                disabled={!showNext}
                className={showNext ? 'button-next button-next-active' : 'button-next'}
                onClick={(e) => {
                  getPrice();

                  e.preventDefault();
                }}
              >
                Next

              </button>
            </li>
            {/* <li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
