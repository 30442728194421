import React, { useEffect, useCallback } from "react";

import env from 'react-dotenv';
import QRCode from "react-qr-code";
import { toJpeg } from 'html-to-image';



export default function StepFive(props) {
    const downloadImage = () => {
        toJpeg(document.getElementById('registrationID'), { quality: 0.95 })
            .then(function (dataUrl) {

                var link = document.createElement('a');
                link.download = `${props.data.certName}.jpeg`;
                link.href = dataUrl;
                link.click();
            });
    }

    useEffect(() => {

    }, []);

    return (
        <div className={props.currentForm === 4 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz">
            <div className="wizard-forms">
                <div className="inner pb-50 clearfix">
                    <div className="form-content pera-content" style={{ width: '100%' }}>
                        <div className="step-inner-content">
                            <span className="step-no bottom-line">Step 4</span>
                            <div className="step-progress float-right">
                                <span>4 of 4 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '100%' }}></div>
                                    </div>
                                </div>
                            </div>
                            <h4 style={{ marginTop: 20 }}>Registration Complete</h4>
                            <div className='container'>
                                <div className='row justify-content-md-center'>
                                    <div className='col-4'></div>
                                    <div className='col-4 mt-5'>
                                        <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/sign-check-icon.png"} alt="board" />
                                    </div>
                                    <div className='col-4'></div>
                                </div>
                                <div className='justify-content-md-center mt-5'>
                                    <h3 style={{textAlign: 'center'}}>Sucessfully registered your workshop/s</h3><br></br>
                                    <p style={{ marginTop: 5, textAlign: 'center'}}>Remember to present your ID to the admins<br></br> before entering the workshop</p>

                                </div>
                            </div>
                            <div className="session-group" style={{marginTop: 40}}>
                                <div className='card-deck justify-content-md-center' style={{ marginLeft: '2rem' }}>
                                    {props.checkoutSelected.map((session, index) => {
                                        return <div className='col-12 card' key={index}
                                            style={{
                                                marginTop: 10,
                                                padding: 20,
                                            }}>
                                           
                                            <div>
                                                <p style={{ marginRight: 10, textTransform: 'uppercase', fontWeight: 'bold' }}>{(index + 1) + ". " + session.title}</p>
                                                <p style={{ marginRight: 10 }}>{session.assignment} Room</p>
                                                <p style={{ marginRight: 10 }}>{session.startTime} - {session.endTime}</p>
                                                <p style={{ marginRight: 10 }}>{session.type}</p>
                                                <p style={{ marginRight: 10 }}>{session.price}</p>

                                            </div>
                                        </div>

                                    })}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
