import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import {
  Header, BottomBar,
} from '../layouts/general/index';
import {
  Slider,
} from '../layouts/home01/index';

class Awards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          logoweb: 'assets/img/logo-small.png',
          names: '2022 ASD Presentation Award',
        },
      ],
      banners: [
        {
          id: 1,
          links: '',
          titlelink: '',
          border: '',
          name: '2022 ASD Presentation Award',
          heading: '2022 ASD Presentation Award',
        },
      ],
    };
  }

  render() {
    const myStyle = {
      backgroundImage:
                "url('assets/img/slider/bg.png')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
    return (
      <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
        <div id="wrapper" className="animsition">
          <div id="page" className="clearfix">
            <div style={myStyle}>
              <Slider />
            </div>
            <div id="site-header-wrap">
              {/* <TopBar /> */}
              {
                                this.state.headers.map((data, idx) => (
                                  <Header data={data} key={idx} />
                                ))
                            }
            </div>
            {
                            this.state.banners.map((data) => (
                              <BannerTitle key={data.id} data={data} />
                            ))
                        }
            <div id="main-content" className="site-main clearfix">
              <div id="content-wrap">
                <div id="site-content" className="site-content clearfix">
                  <div id="inner-content" className="inner-content-wrap">
                    <div className="page-content">
                      <div className="row-contact">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            </div>
                          </div>
                          <div className="row">
                            <div style={{
                              height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                            }}
                            >
                              <h2 style={{
                                lineHeight: 1.5, margin: 10, textAlign: 'left', width: '100%',
                              }}
                              >
                                2022 ASD Presentation Award
                              </h2>
                              <p className="w-100">
                                <br />
                                <br />
                                ASD provides grants to outstanding online presentation in the ASD annual meeting for trainees or young doctors.
                                <br />
                                <br />
                                USD$200 each are awarded to qualified applicants for oral presentation (Maximum 3).
                                <br />
                                <br />
                                USD$100 each are awarded to qualified applicants for e-poster (Maximum 4).

                                <br />
                                <br />
                                Aim: to encourage presenting research results in the ASD annual meeting.
                                <br />
                                <br />
                                Closing date for application: August 30, 2022.
                              </p>
                              <p>
                                <br />
                                <br />
                                {' '}
                                <font style={{ fontWeight: 'bold' }}>Requirement: </font>
                                <br />
                                <br />
                                1. Recipient must be ASD member or associate member.
                                <br />
                                <br />
                                2. Recipient must submit an oral presentation and being accepted at the 2022 ASD annual meeting.
                                <br />
                                <br />
                                3. All applicants must be duly qualified physicians practicing/training in dermatology or pathology.
                                <br />
                                <br />
                                4. Candidates must show positive impact of his/her participation on the field of Dermatopathology in the recipient's country.
                                <br />
                                <br />
                                5. Age: 40 years old and below.
                              </p>
                              <p className="w-100">

                                <br />
                                <br />
                                {' '}
                                <font style={{ fontWeight: 'bold' }}>Priority:</font>
                                {' '}
                                to applicants from underdeveloped and developing countries.
                                <br />
                                <br />
                                <font style={{ fontWeight: 'bold' }}>Documents:</font>
                                {' '}
                                signed application form (or online form) and acceptance letter of presentation.
                                <br />
                                <br />
                                <font style={{ fontWeight: 'bold' }}>Email:</font>
                                {' '}
                                send your documents to asian.dermpath@gmail.com.
                                <br />
                                <br />
                                <font style={{ fontWeight: 'bold' }}>Link:</font>
                                {' '}
                                <a href="https://www.asdermpath.org/article/content.php?id=15">https://www.asdermpath.org/article/content.php?id=15</a>
                                .
                                <br />
                                <br />
                                Applicants can apply online or send application form downloaded from the website.
                              </p>

                            </div>

                            {/* <FormContact /> */}
                            {/* <Information /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
          <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />

          {/* <Footer /> */}
          {
                        this.state.headers.map((data, idx) => (
                          <BottomBar data={data} key={idx} />
                        ))
                    }
        </div>
      </div>
    );
  }
}

export default Awards;
