export const registrationOptions = {
  option_1: {
    details: {
      title: 'Are you a Speaker or a Delegate?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'speaker',
        label: 'Speaker',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_2_1'
      },
      {
        value: 'delegate',
        label: 'Delegate',
        icon: 'assets/img/registration/delegate.png',
        nextOption: 'option_2'
      }
    ]
  },
  option_2_1: {
    details: {
      title: 'Choose your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'local',
        label: 'Local',
        icon: 'assets/img/registration/local.png',
        nextOption: 'option_3_2'
      },
      {
        value: 'international',
        label: 'International',
        icon: 'assets/img/registration/international.png',
        nextOption: 'option_3_2'
      }
    ]

  },

  option_2: {
    details: {
      title: 'Choose your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'local',
        label: 'Local',
        icon: 'assets/img/registration/local.png',
        nextOption: 'option_4'
      },
      {
        value: 'international',
        label: 'International',
        icon: 'assets/img/registration/international.png',
        nextOption: 'option_3_2'
      }
    ]

  },
  option_3_1: {
    details: {
      title: 'Are you a PDS or Non-PDS?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'pds',
        label: 'PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'non_pds',
        label: 'Non-PDS',
        icon: 'assets/img/registration/speaker.png',
      }
    ]
  },
  option_3_2: {
    details: {
      title: 'Select your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.',
      lads: true
    },
    options: [
      {
        value: 'lads',
        label: 'LADS',
        icon: 'assets/img/registration/speaker.png',
      },
      {
        value: 'non_lads',
        label: 'Non-LADS',
        icon: 'assets/img/registration/speaker.png',
      }
    ]
  },
  option_4_5: {
    details: {
      title: 'Please enter your PDS ID for validation',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: []
  },
  option_4: {
    details: {
      title: 'Select your category',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'board',
        label: 'Board of Directors, COA, Org Com, Life Member',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'member',
        label: 'Member',
        icon: 'assets/img/registration/member.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'resident',
        label: 'Residents',
        icon: 'assets/img/registration/resident.png',
        nextOption: 'option_5'
      }
    ]
  },
  option_4_1: {
    details: {
      title: 'Are you a PDS or Non-PDS?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'pds',
        label: 'PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_4_5'
      },
      {
        value: 'non_pds',
        label: 'Non-PDS',
        icon: 'assets/img/registration/speaker.png',
        nextOption: 'option_3_2'
      }
    ]
  },
  option_5: {
    details: {
      title: 'Are you from an Accredited Institution or an Applicant Institution?',
      message: 'The information gathered from this site will be treated as highly confidential. The use of your contact details, such as your email address will be used primarily for communication purposes regarding your registration status and meeting updates.'
    },
    options: [
      {
        value: 'accredited',
        label: 'Accredited Institution',
        icon: 'assets/img/registration/speaker.png',
      },
      {
        value: 'pending',
        label: 'Applicant Institution',
        icon: 'assets/img/registration/speaker.png',
      }
    ]
  },
};

export const registrationFields = {
  board: [
    'payment',
  ],
  speaker: [
    'payment',
  ],


  speaker_local: [
    // 'prc_number',
    'pma_number',
    'pds_id_number',
    'philippines',
    'payment',
    'fellowship'
  ],
  speaker_international: [
    'fellowship'
  ],
  delegate_local_board_pds: [
    'prc_number',
    'pma_number',
    'pds_id_number',
    'philippines',
    'fellowship'
  ],
  delegate_local_member_pds: [
    'prc_number',
    'pma_number',
    'pds_id_number',
    'philippines',
    'payment',
    'fellowship'
  ],
  delegate_local_resident_accredited: [
    'prc_number',
    'select_accredited_institution',
    'philippines',
    'payment'
  ],
  delegate_local_resident_pending: [
    'prc_number',
    'select_applicant_institution',
    'philippines',
    'payment'
  ],
  delegate_local_non_pds: [
    'prc_number',
    'pma_number',
    'upload_prc',
    'philippines'
  ],
  delegate_international_lads: [
    'select_lads',
    'upload',
    'upload_required',
    'delegate_international_lads',
    'payment',
    'fellowship'
  ],
  delegate_international_non_lads: [
    'upload_required',
    'upload',
    'delegate_international_non_lads',
    'payment',
    'fellowship'
  ],
};
