import React from "react";
import { Card, Container, Row, Col, Button, Table, Accordion, Tab, Tabs } from 'react-bootstrap';


export default function Scipro3() {

    return (
        <Container>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <Card>
                        <Card.Text style={{ margin: "auto" }}>
                            <Table style={{ margin: "auto" }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Time</th>
                                        <th colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap" }}>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>7:00 AM - 8:00 AM</td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}>Registration</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>8:00 AM - 12:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}><a href="#symposia-content" style={{ color: "#F6AA1C" }}>Symposia</a></td>
                                    </tr>
                                    {/* <tr>
                        <td style={{textAlign:"center"}}>12:00 PM - 12:30 PM
</td>
                        <td colSpan={3} style={{textAlign:"center", whiteSpace:"nowrap", verticalAlign:"middle"}}>Break</td>
                    </tr> */}
                                    <tr>
                                        <td style={{ textAlign: "center" }}>12:45 PM - 1:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Platinum Lunch Symposium: Bayer
                                        </td>
                                    </tr>

                                    <tr>
                                        {/* <td style={{ textAlign: "center" }}>12:45 PM - 1:30 PM
                                        </td> */}
                                        <td colSpan={4} style={{ textAlign: "center", verticalAlign: "middle" }}>Afternoon Plenary Session
                                        <p>Moderators: Dr. Ma. Isabel Beatriz Puno-Gomez and Dr. Julie Anne Patricia Songco </p>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>1:30 PM - 1:50 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}><p>Research Awards</p>
                                            <p>(Dr. Bryan Guevara)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>1:50 PM - 2:10 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}><p>Social Media Navigating the Ethics of Influence on Public Trust</p>
                                            <p>(Dr. Iris Thiele Isip-Tan)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:10 PM - 2:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Exosomes On Greying Hair: A New Indication <br /> (Dr Iñigo de Felipe)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:30 PM - 2:50 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Updates on Combination Treatments with Injectables <br />                        <p>(Dr. Iñigo de Felipe)</p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>2:50 PM - 3:10 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Possibilities and Limitations of Ambulatory Dermatologic Surgery <br />(Dr. Martin Barsch)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>3:10 PM - 3:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center" }}>Optimizing Skin Cancer Outcomes with Mohs Micrographic Surgery <br />(Dr. David Ciocon)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>3:30 PM - 3:40 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>The Latest Therapeutic Options for Skin Cancers <br /> (Dr. Symon Concha)</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>3:40 PM - 3:50 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Practical Updates in Rheumatology for the Dermatologist <br />(Dr. Josef Symon Salgado Concha)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>3:50 PM - 4:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Emerging and Combination Therapy for Hair Loss <br />(Dr. Angeline Yong)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>4:00 PM - 4:10 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Endolift Procedures Using 1470-nm Diode Fibre Laser: A New Non-surgical Technique For Skin Tightening, Fat Reduction, And Facial Contouring <br />(Dr. Yuri Yogya)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>4:10 PM - 4:20 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Recent Trends in Aesthetics: Understanding Racial, Gender, and Cultural Differences Among Patients <br />(Dr. David Ciocon)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>4:20 PM - 4:30 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Open Forum
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>4:30 PM - 5:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", verticalAlign: "middle" }}>Gold Plus Symposium:  Venn Aesthetics
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>5:00 PM - 8:00 PM
                                        </td>
                                        <td colSpan={3} style={{ textAlign: "center", whiteSpace: "nowrap", verticalAlign: "middle" }}>Business Meeting
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Text>

                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={1}></Col>
                <Col md={10} id="symposia-content">
                    <h3 class="my-5">Symposia</h3>
                    <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0" >
                        <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner"> Symposium 1: Lasers and Pigmentary Disorders</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td><p className="mt-3"><b>LASER: ILLUMINATING PATHWAYS TO SUCCESS WITH LASERS AND ENERGY-BASED TECHNOLOGIES</b></p>
                                                            <p></p>
                                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Rhesa May Martinez and Dr. Ma. Assumpta Serrano</p>
                                                            <p><b><i>Co-chair:</i></b></p>
                                                            <p className="mb-3">Dr. Kathryn Anne Cembrano</p>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p className="mt-3"><b>Case Presentation 1:</b></p>
                                                            <p className="mb-3">From Thinning to Winning: Hair Regrowth/Stimulation</p>
                                                            <p><b><i>Presenter:</i></b></p>
                                                            <p className="mb-3">Dr. Ma. Assumpta Serrano</p>
                                                            <p><b><i>Panel:</i></b></p>
                                                            <p className="mb-3">Dr. Suzanne Datuin, Dr. Maria Franchesca Quinio-Calayag, Dr. Maria Rica S. Mallari</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mt-3"><b>Case Presentation 2:</b></p>
                                                            <p className="mb-3"> Eye-Deal: Periorbital Rejuvenation</p>
                                                            <p><b><i>Presenter:</i></b></p>
                                                            <p className="mb-3">Dr. Kathryn Anne Cembrano</p>
                                                            <p><b><i>Panel:</i></b></p>
                                                            <p className="mb-3">Dr. Yuri Yogya, Dr. Yvette Santiago-Gatmaitan, Dr. Ma. Isabel Mangubat</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="mt-3"><b>Case Presentation 3:</b></p>
                                                            <p className="mb-3">Brightening the Future of Melasma</p>
                                                            <p><b><i>Presenter:</i></b></p>
                                                            <p className="mb-3">Dr. Rhesa May Martinez</p>
                                                            <p><b><i>Panel:</i></b></p>
                                                            <p className="mb-3">Dr. Johannes Dayrit, Dr. Jacqueline Ty-So, Dr. Evangeline Handog</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className='container justify-content-md-center mt-3 mb-4'>
                                            <Table style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            <p><b>PIGMENTARY: BROWN SKIN MATTERS: PIGMENTARY CONUNDRUMS</b></p>
                                                            <p></p>
                                                            <p className="mt-3"><b><i>Chair:</i></b></p>
                                                            <p className="mb-3">Dr. Evangeline Handog</p>
                                                            <p><b><i>Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Peachy Paz Lao and Dr. Rosalina Nadela</p>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p className="mb-3"><b>Part 1: The Many Faces of Hyperpigmentation</b></p>
                                                            <p><b><i>Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Peachy Paz Lao</p>
                                                            <p><b><i>Welcome Remarks:</i></b></p>
                                                            <p className="mb-3">Dr. Evangeline Handog</p>
                                                            <p><b><i>Panel:</i></b></p>
                                                            <p className="mb-3">Dr. Flordeliz Casintahan, Dr. Johannes Dayrit, Dr. Evangeline Handog</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p>Open Forum</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p className="mb-3"><b>Part 2: When Pigments Dive Deeper</b></p>
                                                            <p><b><i>Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Rosalina Nadela</p>
                                                            <p><b><i>Panel:</i></b></p>
                                                            <p className="mb-3">Dr. Maria Teresita Gabriel, Dr. Maria Juliet Macarayo, Dr. Ma. Angela Lavadia</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p >Open Forum</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <p><b><i>Closing Remarks:</i></b></p>
                                                            <p className="mb-3">Dr. Maria Juliet Macarayo</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2} className="my-3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                            <b>LUNCH SYMPOSIUM (Gold):</b><br />Creative Skin
                                                        </td>
                                                    </tr>
                                                </thead>

                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">Symposium 2: Dermatopathology and Dermoscopy</span></h3>
                                    <div className="accordion-content" >
                                        <div className='container'>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}><p className="mt-3"><b>BEYOND THE SURFACE: ADVANCEMENTS IN DERMOSCOPY AND DERMATOPATHOLOGY</b></p>
                                                            <p></p>
                                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Eileen Cubillan and Dr. Arunee Siripunvarapon</p>

                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        {/* <td style={{textAlign:"center", verticalAlign:"middle"}}>08:10 AM - 08:25 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Advanced Diagnostics for Dermatopathology<br />(Dr. Terese Monette O. Aquino-Agas)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>08:25 AM - 08:40 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy in Pediatric Dermatology<br />(Dr. Gisella Umali Adasa)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>08:40 AM - 08:55 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermatopathology for the Clinician:<br />Improving Diagnostics Accuracy of Skin Biopsies<br />(Dr Mara Therese P. Evangelista-Huber)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>08:55 AM - 09:10 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy Mimickers<br />(Dr. Arunee H. Siripunvarapon)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>09:10 AM - 09:25 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>New Classification of Melanocytic Neoplasms<br />(Dr. Eileen Liesl Abesamis-Cubillan)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>09:25 AM - 09:40 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy of Common Skin Diseases Seen in Daily Practice<br />(Dr. Johannes Dayrit)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>09:40 AM - 09:55 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Lecture on AI in Dermatopathology: <br />Updates on Classifications and Definitions<br />(Dr. Marie Len Balmores)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>09:55 AM - 10:10 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Can Dermoscopy Help in Guiding Surgery?<br />(Dr. Krisinda Clare Dim-Jamora)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>10:25 AM - 10:40 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Basic Dermoscopic Structures with Histopathological Correlation in Common Neoplasms<br />(Dr. Elsie Reynosa P. Floreza)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>10:40 AM - 10:55 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy x Dermatopathology: Hair<br />(Dr. Adolfo Bormate, Jr)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>10:55 AM - 11:10 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy x Dermatopathology: Nails<br />(Dr. Val Constatine Cua and Dr. Jolene Kristine Dumlao)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>11:10 AM -11:25 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy x Dermatopathology: Interesting Case<br />(Dr. Erickah Dy-Calayag - PGH Dermpath fellow)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>11:25 AM - 11:40 AM */}
                                                        {/* </td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Dermoscopy x Dermatopathology: Interesting Case<br />(Dr. Zacaria Pario Jr - JRMMC Dermpath fellow)
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2} className="my-3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                            <b>LUNCH SYMPOSIUM (Gold):</b><br />Dmark Multisales Corporation and Hovid Inc.
                                                        </td>
                                                    </tr>
                                                </thead>

                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">Symposium 3: Dermatologic Surgery and Cosmetics in Cooperation with PAPRAS AND IMCAS</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2}><p className="mt-3"><b>TO CUT OR NOT TO CUT: INSIGHTS FROM EXPERTS</b></p>
                                                            <p></p>
                                                            <p className="mt-3"><b><i>Chairs and Moderators:</i></b></p>
                                                            <p className="mb-3">Dr. Ma. Cristina Puyat and Dr. Julie Anne Patricia Songco</p>

                                                        </td>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        {/* <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th> */}
                                                        <th style={{ textAlign: "left", verticalAlign: "middle" }}>Activity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>8:20 AM - 8:30 AM
</td> */}
                                                        <td>Acne Scars: Non-Surgical Approach (Dr. Marina Landau)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>8:30 AM - 8:40 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Acne Scars: Surgical Approach (Dr. Miriam Emily Soriano)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>8:50 AM - 9:00 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Hypertrophic Scars and Keloids: Non-Surgical Approach<br />(Dr. Ma. Cristina Puyat)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>9:00 AM - 9:10 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Hypertrophic Scars and Keloids: Surgical Approach<br />(Dr. Gene Gerald Tiongco)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>9:10 AM - 9:20 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Hair Loss: Non-Surgical Approach<br />(Dr. Mary Jo Kristine Bunagan)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>9:20 AM - 9:30 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Hair Loss: Surgical Approach<br />(Dr. Corazon Almira Mella)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>9:40 AM - 9:50 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Varicose Veins: Non-Surgical Approach<br />(Dr. Teresita Ferrariz)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>9:50 AM - 10:00 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Varicose Veins: Surgical Approach<br />(Dr. Martin Barsch)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>11:20 AM - 11:35 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>IMCAS Session<br />(Chair: Dr. Marina Landau and Co-chair: Dr. Teresita Ferrariz)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>11:05 AM - 11:20 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Managing Thread Complications<br />(Dr. Martin Barsch)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>10:50 AM - 11:05 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Managing Filler Complications<br />(Dr. Marina Landau)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        {/* <td style={{textAlign:"left", verticalAlign:"middle"}}>11:20 AM - 11:35 AM
</td> */}
                                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>Managing Laser Complications<br />(Dr. Iñigo de Felipe)
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2} className="my-3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                            <b>LUNCH SYMPOSIUM (Gold):</b><br />Mixexpert
                                                        </td>
                                                    </tr>
                                                </thead>

                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card style={{ marginBottom: "5px" }}>
                                <div className="accordion-item" >
                                    <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">Symposium 4: Pediatric Dermatology & Hair and Nails</span></h3>
                                    <div className="accordion-content">
                                        <div className='container justify-content-md-center'>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            <b><i>Chairs and Moderators:</i></b><br />Dr. Cindy Jao Tan and Dr. Claudine Yap Silva
                                                        </td>
                                                    </tr>
                                                </thead>

                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="my-3">
                                                            UPDATES ON THE MANAGEMENT OF PEDIATRIC SKIN, HAIR, AND NAIL CONCERNS
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Cosmeceuticals in Kids: Necessity vs Vanity<br />(Dr. Wilsie Walinsundin)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Hair Aware: Alopecia and Hair Disorders Among Kids<br />(Dr. Carmela Dayrit-Castro)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            You Nailed It! Common Pediatric Dermatoses with Nail Changes<br />(Dr. Gisella Adasa)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Red, White, and Blue: Vascular Conditions Out of the Blue<br />(Dr. Leong Kin Fon)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Sweat Pants: Prevention, Assessment, New Options, Tests, & Solutions<br />(Dr. Niña Gabaton)
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="my-3">
                                                            HAIR AND NAILS: MANAGEMENT UPDATES ON COMMON HAIR AND NAIL DISORDERS
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Updates on Management of Androgenetic Alopecia<br />(Dr. Friend Philemon Liwanag)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Updates on Management of Alopecia Areata<br />(Dr. Ana Aurelia Santos)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Exosomes for Androgenetic Alopecia<br />(Dr. Suzanne Datuin)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Treatment Options for Nail Psoriasis<br />(Dr. Carol Chua-Aguilera)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="my-3">
                                                            Updates on Treatment for Onychomycosis<br />(Dr. Mae Quizon)
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                            <Table className="my-4" style={{ margin: "auto", tableLayout: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={2} className="my-3" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                            <b>LUNCH SYMPOSIUM (Gold):</b><br />Pfizer
                                                        </td>
                                                    </tr>
                                                </thead>

                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </Card>




                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}