import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from "react-dotenv";
// import { TextArea } from '@react-ui-org/react-ui';
import Flyout, { anchorPoints } from "@hig/flyout";

export default function StepTwo(props) {
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)

    const [wordCount, setWordCount] = useState(0)
    const [wordError, setWordError] = useState("")
    const [authorCount, setAuthorCount] = useState([{}])
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [abstractData, setAbstractData] = useState(null)


    useEffect(async () => {
        // checkEmail(props.email)
        const accessToken = localStorage.getItem('accessToken')
        if (!accessToken) {
            setIsLoggedIn(false)
        } else {
            fetchAbstract(accessToken)
            setIsLoggedIn(true)
        }

        // document.getElementById("OptionB").classList.add("active");
        // document.getElementById("OptionA").classList.remove("active");
    }, [setIsLoggedIn])


    const handleSubmit = async e => {
        e.preventDefault();
        props.handleNext()
    }

    const fetchAbstract = (accessToken) => {
        // CHECK IF ALREADY REGISTERED
        axios.get(`${env.API_URL}/metadata/abstract`, {
            headers: {
                'authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res.data) {
                props.hasAbstract(true)
                props.handleSetAll(res.data)
                setAbstractData(res.data)
                let authc = 0
                const temp = []
                if (res.data.data['author_0_last_name'] !== "") {
                    authc = authc + 1
                    temp.push({})

                }
                if (res.data.data['author_1_last_name'] !== "") {
                    authc = authc + 1
                    temp.push({})

                }
                if (res.data.data['author_2_last_name'] !== "") {
                    authc = authc + 1
                    temp.push({})

                }
                if (res.data.data['author_3_last_name'] !== "") {
                    authc = authc + 1
                    temp.push({})

                }

                if (res.data.data['consenttoshare'])
                    document.getElementById("consenttoshare").checked = true;


                setAuthorCount(temp)

                // if (res.data.data['presentation'] === "freepaper") {
                //     document.getElementById("OptionA").classList.add("active");
                //     document.getElementById("OptionB").classList.remove("active");
                // } else {
                //     document.getElementById("OptionB").classList.add("active");
                //     document.getElementById("OptionA").classList.remove("active");
                // }
                // setAbstractData(res.data)
            }
        })
    }


    const handleKeyPress = (e, main) => {
        const count = e;

        const countWords = (count) => {
            if (count.length === 0) {
                return 0;
            } else {
                count = count.replace(/(^\s*)|(\s*$)/gi, "");
                count = count.replace(/[ ]{2,}/gi, " ");
                count = count.replace(/\n /, "\n");
                return count.split(' ').length;
            }
        }

        const countFinal = countWords(count)
        if (countFinal >= 250) {
            setWordError('Too many words')
        } else {
            setWordError('')
            handleChange(main)
        }
        setWordCount(countFinal)
    }



    const checkEmail = (e) => {
        if (e !== "") {

            // CREATE SOURCE
            let body = {
                "email": e.toLowerCase(),
            };
            axios.post(env.API_URL + "metadata/email", body).then((res) => {
                try {
                    if (res.data.error) {
                        setMessage(res.data.error)
                        setError(true)
                        return false
                    }
                    setMessage(res.data.message)
                    setError(false)
                    return true
                } catch (e) { }
            }).catch((e) => {
                setMessage(e.response.data.error)
                setError(true)
                return false
            })
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        props.setForm((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleChangeBoolean = (e) => {
        const { name, value } = e.target;
        props.setForm((prevValues) => ({
            ...prevValues,
            [name]: !prevValues[name],
        }));
    };

    const CorrespondingComponent = () => {
        return (
            <>
                <p>Name of Corresponding Author</p>
                <div className='w-100'>
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`corresponding_author_last_name`]} type="text" name={`corresponding_author_last_name`} id={`corresponding_author_last_name`} className="form-control required" minLength="2" placeholder="Surname *" required autocomplete="off" />
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`corresponding_author_first_name`]} type="text" name={`corresponding_author_first_name`} id={`corresponding_author_first_name`} className="form-control required" minLength="2" placeholder="First Name *" required autocomplete="off" />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`corresponding_author_middle_name`]} type="text" name={`corresponding_author_middle_name`} id={`corresponding_author_middle_name`} className="form-control required" placeholder="MI" required autocomplete="off" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <input disabled={abstractData} value={props.form['corresponding_author_email']} onChange={(e) => {
                                function validEmail(e) {
                                    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                                    return String(e).search(filter) != -1;
                                }
                                if (!validEmail(e.target.value)) {
                                    setMessage('Invalid Email')
                                    setError(true)
                                } else {
                                    setMessage('Valid Email')
                                    setError(false)
                                }
                                handleChange(e)
                            }} type="email" name="corresponding_author_email" id="corresponding_author_email" className="form-control required" minLength="5" placeholder="Email *" required autocomplete="off" />
                            <p style={{ color: error && 'red', marginBottom: 10 }}>{message}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            * All correspondences will be through email. Please ensure that you provide a valid email address
                        </div>
                    </div>

                </div>
            </>
        )
    }

    const AuthorComponent = (item, idx) => {
        return (
            <div key={idx} style={{ marginTop: 50, padding: 20, borderWidth: 1, borderColor: 'grey', borderStyle: 'dashed' }}>
                <p style={{ fontWeight: 'bold' }}>Author {idx + 1}*  {idx === 0 && "Primary Author"}</p>
                <div className='w-100'>
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`author_` + idx + `_last_name`]} type="text" name={`author_` + idx + `_last_name`} id={`author_` + idx + `_last_name`} className="form-control required" minLength="2" placeholder="Last Name *" required autocomplete="off" />
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`author_` + idx + `_first_name`]} type="text" name={`author_` + idx + `_first_name`} id={`author_` + idx + `_first_name`} className="form-control required" minLength="2" placeholder="First Name *" required autocomplete="off" />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            <input disabled={abstractData} onChange={handleChange} value={props.form[`author_` + idx + `_middle_name`]} type="text" name={`author_` + idx + `_middle_name`} id={`author_` + idx + `_middle_name`} className="form-control required" minLength="1" placeholder="MI *" required autocomplete="off" />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-12">
                            * Primary author, or presenting author for Free Paper Presentation
                        </div>
                    </div> */}

                    <div style={{ marginTop: 20 }} className="row mt-10">
                        <div className="col-12">
                            AFFILIATION:
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className='row w-50'>
                            <div className='col-6'>
                                <div className="row justify-content-center align-items-center">
                                    <div className='col-10'>
                                        <label for="yes">Are you an ASPCR member?</label>
                                    </div>
                                    <div className='col-2'>
                                        <input onChange={handleChangeBoolean} type="checkbox" style={{ marginBottom: 0 }} value={props.form[`author_` + idx + `_aspcr`]} name={`author_` + idx + `_aspcr`} id={`author_` + idx + `_aspcr`} />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <input disabled={abstractData} style={{ height: 50 }} onChange={handleChange} value={props.form[`author_` + idx + `_position`]} name={`author_` + idx + `_position`} id={`author_` + idx + `_position`} className="form-control required" minLength="5" placeholder="Position/Title *" required autocomplete="off" />
                            <input disabled={abstractData} style={{ height: 50 }} onChange={handleChange} value={props.form[`author_` + idx + `_institution`]} name={`author_` + idx + `_institution`} id={`author_` + idx + `_institution`} className="form-control required" minLength="5" placeholder="Institution/Hospital/Clinic" autocomplete="off" />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <input disabled={abstractData} style={{ height: 50 }} onChange={handleChange} value={props.form[`author_` + idx + `_department`]} name={`author_` + idx + `_department`} id={`author_` + idx + `_department`} className="form-control required" minLength="5" placeholder="Department/Section *" required autocomplete="off" />
                            <input disabled={abstractData} style={{ height: 50 }} onChange={handleChange} value={props.form[`author_` + idx + `_country`]} name={`author_` + idx + `_country`} id={`author_` + idx + `_country`} className="form-control required" minLength="5" placeholder="Country *" required autocomplete="off" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function customStylesheet(styles) {
        return {
            ...styles,
            flyoutContainer: {
                ...styles.flyoutContainer,
                opacity: "1"
            },
            panel: {
                ...styles.panel,
                backgroundColor: "white",
            },
            pointerBody: {
                fill: "white"
            }
        };
    }

    return (
        <form id="wizard" onSubmit={handleSubmit}>

            <div className={props.currentForm === 1 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz" >
                {/* div 2 */}
                <div className="wizard-forms">
                    <div className="inner pb-100 clearfix">
                        <div className="form-content pera-content w-100" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                            <div className="step-inner-content">
                                <h2 style={{ padding: 0 }}>Abstract submissions is now closed</h2>
                                <p style={{ paddingTop: 0 }}>
                                    <br />Thank you for your submissions. Stayed tuned for further updates.
                                </p>
                            </div>
                        </div>

                        {/* {isLoggedIn ?
                            <div className="form-content pera-content w-100" style={{paddingLeft: '15%', paddingRight: '15%'}}>
                                {abstractData &&
                                    <div className='w-100' style={{ backgroundColor: 'lightgreen', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <p style={{ color: 'black', margin: 20 }}> You have submitted your abstract. </p>
                                    </div>
                                }
                                <div className="step-inner-content">
                                    <h2 style={{ padding: 0 }}>ABSTRACT SUBMISSION</h2>

                                    <div className="row">
                                    
                                    </div>
                                    <p className='mt-4'>*  Choose the specific topic or category that best suits your original research</p>

                                    <div className="row">
                                        <div className="col-md-12" >

                                            <select required disabled={abstractData} onChange={handleChange} value={props.form[`research_category`]} type="text" name="research_category" id="research_category" style={{ border: '2px solid #9b9999', fontSize: 20 }}>
                                                <option value="">
                                                    SELECT RESEARCH CATEGORY
                                                </option>
                                                <option value="Acne">Acne</option>
                                                <option value="Aesthetic/Cosmetic Dermatology">Aesthetic/Cosmetic Dermatology</option>
                                                <option value="Aging/Geriatrics">Aging/Geriatrics</option>
                                                <option value="Autoimmune Disorders and Connective Tissue Diseases">Autoimmune Disorders and Connective Tissue Diseases</option>
                                                <option value="Basic Science">Basic Science</option>
                                                <option value="Community Dermatology">Community Dermatology</option>
                                                <option value="Contact Dermatitis:  Allergic & Irritant">Contact Dermatitis:  Allergic & Irritant</option>
                                                <option value="Dermatopathology">Dermatopathology</option>
                                                <option value="Dermatologic Surgery">Dermatologic Surgery </option>
                                                <option value="Diagnostic Techniques/Immunostaining">Diagnostic Techniques/Immunostaining</option>
                                                <option value="Digital/Electronic Technology (ie dermoscopy, digital imaging technology)">Digital/Electronic Technology (ie dermoscopy, digital imaging technology)</option>
                                                <option value="Disorders of the Subcutaneous Tissue">Disorders of the Subcutaneous Tissue</option>
                                                <option value="Eczema">Eczema</option>
                                                <option value="Epidemiology & Health Services Administration">Epidemiology & Health Services Administration</option>
                                                <option value="Genodermatoses">Genodermatoses</option>
                                                <option value="Hair & Nail Disorders">Hair & Nail Disorders</option>

                                                <option value="Immunodermatology & Blistering Disorders">Immunodermatology & Blistering Disorders </option>
                                                <option value="Infections">Infections  </option>
                                                <option value="Internal Medicine Dermatology">Internal Medicine Dermatology </option>
                                                <option value="Papulosquamous Disorders">Papulosquamous Disorders </option>
                                                <option value="Pediatric Dermatology">Pediatric Dermatology </option>
                                                <option value="Pharmacology">Pharmacology </option>
                                                <option value="Photobiology, Phototherapy & Photosensitivity Diseases">Photobiology, Phototherapy & Photosensitivity Diseases </option>
                                                <option value="Pigmented lesions, disorders & Pigmentary changes">Pigmented lesions, disorders & Pigmentary changes </option>
                                                <option value="Psychocutaneous and neurocutaneous Diseases">Psychocutaneous and neurocutaneous Diseases </option>
                                                <option value="Surgery (laser, cosmetic, dermatologic)">Surgery (laser, cosmetic, dermatologic) </option>
                                                <option value="Telemedicine">Telemedicine </option>
                                                <option value="Tumors">Tumors  </option>
                                                <option value="Vascular Anomalies/Disorders">Vascular Anomalies/Disorders </option>
                                                <option value="Wound Healing & Ulcers">Wound Healing & Ulcers </option>
                                            </select>
                                        </div>
                                    </div>
                                    <p>*  The research committee reserves the right to change the category of the abstract</p>

                                    <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                                    <div className="form-inner-area">

                                        {CorrespondingComponent()}

                                        {authorCount.map((item, idx) => {
                                            return (AuthorComponent(item, idx))
                                        })}

                                        {!abstractData &&

                                            <div className='row col-12' style={{ marginTop: 10 }}>
                                                {authorCount.length > 1 &&
                                                    <li>
                                                        <button style={{ color: "black" }} onClick={(e) => {
                                                            e.preventDefault();
                                                            let temp = [...authorCount]
                                                            temp.pop()
                                                            setAuthorCount(temp)
                                                        }} className="themesflat-button">Remove Author</button>
                                                    </li>
                                                }
                                                {authorCount.length < 5 &&
                                                    <li>
                                                        <button style={{ color: "black" }} onClick={(e) => {
                                                            e.preventDefault();
                                                            let temp = [...authorCount]
                                                            temp.push({})
                                                            setAuthorCount(temp)
                                                        }} className="themesflat-button bg-accent">Add Author</button>
                                                    </li>
                                                }
                                                <p style={{ marginTop: 10, marginLeft: 10 }}>(*if more than 5 authors, email research@pdsconvention2023.com directly.)</p>
                                            </div>
                                        }
                                        <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} />

                                    </div>

                                    <div className="form-inner-area">
                                       
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', }} >

                                            <select required disabled={abstractData} onChange={handleChange} value={props.form[`study_design`]} type="text" name="study_design" id="study_design" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, border: '2px solid #9b9999', borderRight: '0px transparent', fontSize: 20, height: 66, width: '100%' }}>
                                                <option value="">
                                                    SELECT STUDY DESIGN
                                                </option>
                                                <option value="Case Report">Case Report</option>
                                                <option value="Experimental Research">Experimental Research</option>
                                                <option value="Non-Experimental Research">Non-Experimental Research</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            <Flyout
                                                stylesheet={customStylesheet}
                                                anchorPoint={anchorPoints.RIGHT_CENTER}
                                                content={<p>For any inquiries, please email: research@pdsconvention2023.com</p>}
                                            >
                                                <div className='help-icon'><h1 style={{ fontSize: 30 }}>❔</h1></div>
                                            </Flyout>
                                        </div>




                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }} >
                                            <input style={{ borderRight: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disabled={abstractData} value={props.form[`title`]} onChange={handleChange} type="text" name="title" id="title" className="form-control required" minLength="2" placeholder="Title *" required autocomplete="off" />

                                            <Flyout
                                                stylesheet={customStylesheet}
                                                anchorPoint={anchorPoints.RIGHT_CENTER}
                                                content={<p>Capitalize the first word, nouns, pronouns, adjectives, verbs and adverbs. Do not capitalize articles, conjunctions or prepositions. <br></br><br></br> For any inquiries, please email: research@pdsconvention2023.com</p>}
                                            >
                                                <div className='help-icon'><h1 style={{ fontSize: 30 }}>❔</h1></div>
                                            </Flyout>
                                        </div>


                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <p>Structured Abstract* (maximum of 250 words)</p>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{wordCount}/250</p>
                                                <Flyout
                                                    stylesheet={customStylesheet}
                                                    anchorPoint={anchorPoints.RIGHT_CENTER}
                                                    content={<p>Titles (e.g., Introduction, Result, Discussion, Conclusion) will be included in the word count. <br></br><br></br> For any inquiries, please email: research@pdsconvention2023.com</p>}
                                                >
                                                    <div className='help-icon-solo'><h1 style={{ fontSize: 20 }}>❔</h1></div>
                                                </Flyout>
                                            </div>
                                        </div>

                                        <textarea value={props.form[`abstract`]} name="abstract" id="abstract" className="required" minLength="2" placeholder="Structured Abstract* (maximum of 250 words)" required autocomplete="off" style={{ border: '2px solid #9b9999', height: '250px', fontSize: 20 }} onChange={(e) => {
                                            handleKeyPress(e.target.value, e)
                                        }}>
                                        </textarea>
                                        <p>Keywords* (maximum of 5 words, separated by commas)</p>

                                        <input disabled={abstractData} value={props.form[`keywords`]} onChange={handleChange} type="text" name="keywords" id="keywords" className="form-control required" minLength="2" placeholder="Keywords *" required autocomplete="off" />
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }} >
                                            <input style={{ borderRight: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disabled={abstractData} value={props.form[`conflictofinterest`]} onChange={handleChange} type="text" name="conflictofinterest" id="conflictofinterest" className="form-control required" minLength="2" placeholder="Conflict of Interest *" required autocomplete="off" />
                                            <Flyout
                                                stylesheet={customStylesheet}
                                                anchorPoint={anchorPoints.RIGHT_CENTER}
                                                content={<p>Indicate if professional or personal considerations may compromise an investigator's professional judgment in conducting or reporting research. <br></br><br></br> For any inquiries, please email: research@pdsconvention2023.com</p>}
                                            >
                                                <div className='help-icon'><h1 style={{ fontSize: 30 }}>❔</h1></div>
                                            </Flyout>
                                        </div>
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }} >

                                            <input style={{ borderRight: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} disabled={abstractData} value={props.form[`commercialfunding`]} onChange={handleChange} type="text" name="commercialfunding" id="commercialfunding" className="form-control required" minLength="2" placeholder="Commercial Funding *" required autocomplete="off" />
                                            <Flyout
                                                stylesheet={customStylesheet}
                                                anchorPoint={anchorPoints.RIGHT_CENTER}
                                                content={<p>Indicate if financial considerations may compromise an investigator's professional judgment in conducting or reporting research. <br></br><br></br> For any inquiries, please email: research@pdsconvention2023.com</p>}
                                            >
                                                <div className='help-icon'><h1 style={{ fontSize: 30 }}>❔</h1></div>
                                            </Flyout>
                                        </div>

                                        <p className='w-100'>Please place N/A if no conflict of interest or source of commercial funding.</p> <br />


                                        <div className="session-form row col-12 p-2" >
                                            <div style={{ width: "5%", paddingLeft: 10 }}>
                                                <input
                                                    disabled={abstractData}
                                                    id={"consenttoshare"}
                                                    type="checkbox"
                                                    style={{ width: 30, height: 30 }}
                                                    className="radio mr-4"
                                                    value={props.form[`consenttoshare`]}
                                                    name={"consenttoshare"}
                                                    onChange={handleChangeBoolean}
                                                />
                                            </div>
                                            <div style={{ width: "95%" }}>
                                                <p style={{ marginLeft: 10 }}> I/we would like to include the submitted abstract in the e-Book of Abstracts.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <br />



                                </div>
                            </div>
                            :
                            <div className="form-content pera-content w-100" style={{paddingLeft: '15%', paddingRight: '15%'}}>
                                <div className="step-inner-content">
                                    <h2 style={{ padding: 0 }}>Login Required</h2>

                                    <div className="step-box">

                                        <p style={{ paddingTop: 20 }}>
                                            <br /> <br />You must login to be able to submit an abstract.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        } */}
                    </div>
                    {/*<!-- /.inner -->*/}

                    {!abstractData &&
                        <div className="actions">
                            <ul>
                                {/* {isLoggedIn ?

                                    <ul>
                                        <li>
                                            <button onClick={(e) => {
                                                // e.preventDefault();
                                                // props.handleNext()
                                            }} className="themesflat-button bg-accent">NEXT</button>
                                        </li>
                                    </ul>
                                    :
                                    <ul>
                                        <li>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                window.location = '/login'
                                            }}
                                                className={"button-next button-next-active"}>Login</button>
                                        </li>
                                    </ul>
                                } */}


                                {/* <li><span className="js-btn-prev" title="BACK"><i className="fa fa-arrow-left"></i> BACK </span></li> */}
                                {/* <li>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    props.handleBack()
                                }} className="themesflat-button bg-accent js-btn-prev">BACK</button>
                            </li> */}
                                {/* <li>
                                <button onClick={(e) => {
                                    // e.preventDefault();
                                    // props.handleNext()
                                }} className="themesflat-button bg-accent">NEXT</button>
                            </li> */}
                                {/* <li><span className="js-btn-next" title="NEXT">NEXT <i className="fa fa-arrow-right"></i></span></li> */}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </form>
    );
}

