import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';
import { Slider } from '../layouts/home01/index'

import BannerTitle from '../layouts/about/banner/BannerTitle'
import { Header, BottomBar } from '../layouts/general/index'
class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_mobile.png",
                    names: "Maps",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '/maps',
                    titlelink: 'Maps',
                    border: '|',
                    // name: ' Speaker',
                    heading: 'Maps'
                }
            ],
        }
    }
    render() {
        const myStyle = {
            backgroundImage:
                "url('assets/img/slider/bg.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };


        return (
            <div className="header-fixed page sidebar-right width-71 header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div style={myStyle} >
                            <Slider />
                        </div>


                        <div id="site-header-wrap" style={{height: "61px"}}>
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data, idx) => (
                                    <Header data={data} key={idx} />
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap" className="container">
                                {/* <SiteContent />
                                <SideBar /> */}

                                <LeaderTeam />
                            </div>
                        </div>

                    </div>
                    {/* <Footer /> */}
                    {
                        this.state.headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Maps;

class LeaderTeam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lead: [
                {
                    id: 1,
                    srcimg: 'assets/img/indexcarousel/1B.jpeg',
                    name: 'Martin Barsch, MD, MBA',
                    position: 'Linz, Austria',
                    image: 'assets/img/indexcarousel/1B.jpeg'
                },
                {
                    id: 1,
                    srcimg: 'assets/img/indexcarousel/2B.jpg',
                    name: 'Martin Barsch, MD, MBA',
                    position: 'Linz, Austria',
                    image: 'assets/img/indexcarousel/2B.jpg'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/indexcarousel/3B.png',
                    name: 'David H. Ciocon, MD',
                    position: 'New York City, NY, USA',
                    image: 'assets/img/indexcarousel/3B.png'
                },

                {
                    id: 3,
                    srcimg: 'assets/img/indexcarousel/4B.png',
                    name: 'Martin Barsch, MD, MBA',
                    position: 'Linz, Austria',
                    image: 'assets/img/indexcarousel/4B.png'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/indexcarousel/5B.png',
                    name: 'David H. Ciocon, MD',
                    position: 'New York City, NY, USA',
                    image: 'assets/img/indexcarousel/5B.png'
                },

                {
                    id: 5,
                    srcimg: 'assets/img/indexcarousel/6B.png',
                    name: 'David H. Ciocon, MD',
                    position: 'New York City, NY, USA',
                    image: 'assets/img/indexcarousel/6B.png'
                },
            ],
            fgd: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/fgd/Slide1.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/fgd/Slide2.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/fgd/Slide3.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide3.PNG'
                },
            ],
            symposias: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/symposium/Slide1.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/symposium/Slide2.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/symposium/Slide3.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/symposium/Slide4.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide4.PNG'
                },
            ],
            precon: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/precon/Slide1.PNG',
                    name: 'Teresita S. Ferrariz, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/precon/Slide2.PNG',
                    name: 'Arunee H. Siripunvarapon, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/precon/Slide3.PNG',
                    name: 'Krisinda Clare Dim-Jamora, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/precon/Slide4.PNG',
                    name: 'Charlene Ang-Tiu, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide4.PNG'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/speakers/precon/Slide5.PNG',
                    name: 'Julie Anne Patricia Songco, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide5.PNG'
                },

            ],
            speakers: [
                {
                    id: 1,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 2,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 3,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 4,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
            ],
            showModal: false,
            imageUrl: ""
        }
    }

    render() {
        return (
            <div className="row-team">
                <Modal
                    open={this.state.showModal}
                    center
                    onClose={() => this.setState({
                        showModal: false
                    })}
                >


                    <div style={{ height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ maxWidth: 'none', width: '100%' }} src={this.state.imageUrl} alt="imagealt" />
                    </div>
                </Modal>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">PDS Convention Maps</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        this.state.lead.map(data => (
                                            <div className="d-flex col-xs-12 col-md-12 themesflat-team style-1 align-center justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{
                                                                    color: "white", width: "100%",
                                                                    height: "100%"
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            {/* <h6 className="name">{data.name}</h6> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div>

                        {/* <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Case Discussion Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row">
                                    {
                                        this.state.speakers.map(data => (
                                            <div className="col-md-3 themesflat-team style-1 align-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div >
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{ color: "white" }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            <h6 className="name">{data.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div> */}

                        {/* <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Focus Group Discussion Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                {
                                        this.state.fgd.map(data => (
                                            <div className="d-flex col-xs-4 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{ color: "white" }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div> */}

                        {/* <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Symposia Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                {
                                        this.state.symposias.map(data => (
                                            <div className="d-flex col-xs-4 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{ color: "white" }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div> */}

                        {/* <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Precon Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        this.state.precon.map(data => (
                                            <div className="d-flex col-xs-2 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{ color: "white" }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div> */}


                    </div>
                </div>
            </div>
        );
    }
}