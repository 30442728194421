import React, { Component } from 'react';
import {Card ,Container,Row,Col,Button,Table, Accordion, Tab, Tabs} from 'react-bootstrap';
import Scipro1 from './scipro1';
import Scipro2 from './scipro2';
import Scipro3 from './scipro3';

class SiteContent2 extends Component {
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />


                    <div>
                        <div class="container text-center" style={{margin:"25px"}}>
                            <h3 class="fw-bold ">Philippine Dermatological Society 46th Annual Convention</h3>
                            {/* <p class="h4 fw-bold d-inline-block">THEME: "</p> */}
                            <p class="h4 color-293E6C fw-bold d-inline-block">Future Forward: Fundamentals to Advances
                            </p>
                            <p class="h4 fw-bold">
                            November 5-7, 2023
                            </p>                            
                        </div>

                        <Row>
                            <Col md={12}>
                                
                                <Tabs defaultActiveKey="workshop1">
                                    <Tab eventKey="workshop1" title="PreCon" >
                                        <Card style={{margin: "10px"}}>
                                            <Card.Title>
                                            <div class="row justify-content-md-center mb-3 corner-rounded bg-203664">
                                                <div class="col-6">
                                                    <div class="corner-rounded text-white p-3">
                                                        <p class="h4 text-center dmsans-regular mb-0">November 5, 2023</p>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="corner-rounded text-white py-3">
                                                        <p class="h4 text-start dmsans-regular mb-0">Preconvention Workshops</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </Card.Title>
                                            <Card.Text style={{paddingBottom:"25px"}}>
                                                <Scipro1 />
                                            </Card.Text>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="workshop2" title="DAY 1">
                                        <Card style={{margin: "10px"}}>
                                            <Card.Title>
                                                <div class="row justify-content-md-center mb-3 corner-rounded bg-203664">
                                                    <div class="col-6">
                                                        <div class="corner-rounded text-white p-3">
                                                            <p class="h4 text-center dmsans-regular mb-0">November 6, 2023</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="corner-rounded text-white py-3">
                                                            <p class="h4 text-start dmsans-regular mb-0">Day 1 - Plenary and Focus Group Discussion</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Title>
                                            <Card.Text style={{paddingBottom:"25px"}}>
                                                <Scipro2 />
                                            </Card.Text>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="workshop3" title="DAY 2">
                                        <Card style={{margin: "10px"}}>
                                            <Card.Title>
                                                <div class="row justify-content-md-center mb-3 corner-rounded bg-203664">
                                                    <div class="col-6">
                                                        <div class="corner-rounded text-white p-3">
                                                            <p class="h4 text-center dmsans-regular mb-0">November 7, 2023</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="corner-rounded text-white py-3">
                                                            <p class="h4 text-start dmsans-regular mb-0">Day 2 - Plenary and Symposia</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Title>
                                            <Card.Text style={{paddingBottom:"25px"}}>
                                                <Scipro3 />
                                            </Card.Text>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Col>
                        
                        </Row>

                        <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />

                        <div class="container mt-10 pt-10">
                        {/* <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0" >
                                                        <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">

                                                            <div className="accordion-item" >
                                                                
                                                                <div className="accordion-heading">                                                                    
                                                                        <p class="h4 text-start dmsans-regular pt-3 text-center"><span className="inner">
                                                                        November 5, 2023 Day 1 - Preconvention Workshops</span></p>                                                                    
                                                                </div>
                                                                
                                                                <div className="accordion-content">
                                                                    <Scipro1 />                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        
                        </div>
                        
                        {/* <div class="container mt-10 pt-10">
                            <div class="row justify-content-start mb-3 corner-rounded bg-203664">
                                <div class="col-5">
                                    <div class="corner-rounded text-white p-3">
                                        <p class="h4 text-center dmsans-regular mb-0">Day 1</p>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="corner-rounded text-white py-3">
                                        <p class="h4 text-start dmsans-regular mb-0">
                                            November 5, 2023 SUNDAY
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="container">
                            <div class="row ">
                                <div class="col-12">
                                    <div class="row justify-content-start mb-4 ">
                                        <div class="col-lg-5">
                                            <div class="corner-rounded p-3">
                                                <p class="fs-5 text-center dmsans-regular mb-0">
                                                    08:00 - 10:00 AM
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 custom-text-center">
                                            <div class="corner-rounded p-3">
                                                <p class="fs-5 text-start dmsans-regular mb-0">STI & Leprosy</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Contact & Environmental Dermatology</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Functional Dermatology & Psychodermatology</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Photodermatology & Immunodermatology</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br /><br />
                        <div class="container custom-header">
                            <div class="row justify-content-start mb-3 bg-203664 corner-rounded">
                                <div class="col-5">
                                    <div class="corner-rounded text-white p-3">
                                        <p class="h4 text-center dmsans-regular mb-0">
                                            Day 2
                                        </p>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="corner-rounded text-white py-3">
                                        <p class="h4 text-start dmsans-regular mb-0">
                                            NOVEMBER 6, 2023 MONDAY
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row ">
                                <div class="col-12">
                                    <div class="row justify-content-start mb-4 ">
                                        <div class="col-lg-5">
                                            <div class="corner-rounded p-3">
                                                <p class="fs-5 text-center dmsans-regular mb-0">
                                                    08:00 - 12:00 NN
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 custom-text-center">
                                            <div class="corner-rounded p-3">
                                                <p class="fs-5 text-start dmsans-regular mb-0">Laser & Pigmentary</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Dermatopathology & Dermoscopy</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Cosmetic & Dermatologic Surgery</p>
                                                <p class="fs-5 text-start dmsans-regular mb-0">Pediatric Dermatology & Hair and Nails</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
           
                        <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />

                    </div>
                </div>
            </div>
        );
    }
}

export default SiteContent2;