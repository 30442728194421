
import React, { useState } from 'react';
import Step1 from './step/step-1';
import Step2 from './step/step-2';
import "../assets/scss/style.scss";
// import "../assets/js/main.js"
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

export default function Login(props) {

    const [currentForm, setCurrentForm] = useState(1)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [letterUrl, setLetterUrl] = useState("")


    const handleNext = () => {
        // console.log(firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution)
        setCurrentForm(currentForm + 1)

    }
    const handleBack = () => {
        setCurrentForm(currentForm - 1)
    }

    return (

        <div className="wrapper">
            {isLoading &&
                <div style={{ backgroundColor: '#000000a8', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', flexDirection: 'column', zIndex: 20000, position: 'absolute' }}>
                    <h6 style={{ color: 'white', margin: 20 }}>LOADING...</h6>
                    <Dots color={'white'} size={50} />
                </div>
            }



            {/* <div className=" steps-area-fixed w-50 " >
                <div >
                    <img className='d-none d-md-none d-lg-block d-sm-none' src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1686619755/PDS/sidebanner_clean_nagapu_ib59cc.jpg"} alt="" style={{ width: '100%', maxWidth: 'none' }} />
                    <img className='d-block d-xs-block d-sm-block d-md-block d-lg-none' src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1686619755/PDS/sidebanner_clean_nagapu_ib59cc.jpg"} alt="" style={{ width: '100%', maxWidth: 'none' }} />
                </div>
            </div> */}

            <div className='d-none d-md-none d-lg-block d-sm-none'>
                <div className="multisteps-form__form w-100" style={{ float: "center" }} >
                    <div className="form-area position-relative">
                        <Step2
                            email={email}
                            setEmail={setEmail}
                            setPassword={setPassword}
                            currentForm={currentForm}
                            setIsLoading={setIsLoading}
                            handleNext={handleNext}
                            handleBack={handleBack}
                        />
                    </div>
                </div>
            </div>
            <div className='d-block d-xs-block d-sm-block d-md-block d-lg-none'>
                <div className="multisteps-form__form w-100" style={{ float: "right" }} >
                    <div className="form-area position-relative">
                        <Step2
                            email={email}
                            password={password}
                            setEmail={setEmail}
                            setPassword={setPassword}
                            currentForm={currentForm}
                            setIsLoading={setIsLoading}
                            handleNext={handleNext}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
};



