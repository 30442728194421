/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import { Slider } from './index'
import { Modal } from 'react-responsive-modal';
import BannerTitle from '../../layouts/about/banner/BannerTitle'
import {Card ,Container,Row,Col,Button,Table, Accordion, Tab, Tabs} from 'react-bootstrap';

class SubContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            imageUrl: "",
            banners: [
                {
                    id: 1,
                    links: '/',
                    titlelink: 'Event Replay',
                    border: '|',
                    heading: 'Video On Demand'
                }
            ]
        }
    }

    render() {
        const accessToken = localStorage.getItem('accessToken')
        return (
            <div className="row-about">
                <Modal
                    open={this.state.showModal}
                    center
                    showCloseIcon={false}
                    onClose={() => this.setState({
                        showModal: false
                    })}
                >


                    <div style={{ height: '620px', 
                    // width: '1180px', 
                    margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                        <iframe src="https://res.cloudinary.com/development-mar/video/upload/v1683235143/PDS/finally_we_are_back_at_shang_mgcn3e.mp4" width="1180" height="620" allow="autoplay"></iframe>
                    </div>
                </Modal>

                

                <div className="container-fluid">
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-12 half-background style-4" >
                            <Slider data={"slider-small"} />
                        </div>
                    </div>                    
                </div>

                <div className='video-banner' 
                    onClick={() => this.setState({
                        showModal: true
                    })}
                >
                    <Container className='d-flex justify-content-center align-items-center'>
                    <img src="https://res.cloudinary.com/development-mar/image/upload/v1683283565/PDS/kindpng_18387_1_esqtkr.png" alt="play button" />
                    <div className="wrap">
                        <h3><small>Promotional Video</small></h3>
                        <h2><small>The 46th PDS annual convention 2023</small></h2>
                    </div>
                    </Container>
                </div>

                <Container>
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                    <Row style={{marginTop:"5vh"}}>
                        <Col md={12}>
                        <h2 className="heading" style={{ textAlign: 'center' }}>OUR PARTNERS</h2>
                        <p style={{ textAlign: 'center' }}>Thank you for the support of our industry partners through their educational grants</p>
                        </Col>
                    </Row>
                    {/* ------->Diamond Sponsors */}
                    <Container style={{
                        // marginTop:"10vh"
                        }}>
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                    <Row style={{marginTop:"5vh"}}>
                        <Col>
                        <h2 className="heading" style={{ textAlign: 'center' }}>Diamond Sponsor</h2>
                        <div className="container " >
                            <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:"20vh" }}>
                                <div className="col-md-8 d-flex">
                                    <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683238143/PDS/sponsors/diamond/diamond.galderma_etisg4.jpg"} style={{ height: 'auto', maxWidth: '200ox !important' }} alt="altimage" data-bgposition="center center" data-no-retina />
                                </div>
                            </div>
                        </div>                       
                        </Col>
                    </Row>
                    </Container>
                    {/* ------->Platinum Sponsors */}
                    <Container style={{
                        // marginTop:"10vh"
                        }}>
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                    <Row style={{marginTop:"5vh"}}>
                        <Col xs={12} sm={12} md={12}>
                        <h2 className="heading mb-5" style={{ textAlign: 'center' }}>Platinum Sponsors</h2>                        
                        </Col>                                                
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center' style={{height:"30vh"}}>
                        <Col xs={6} sm={6} md={4}>
                        <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683237500/PDS/sponsors/platinum/platinum.bayer_shttqt.png"} alt="altimage" data-bgposition="center center" data-no-retina />
                        </Col>      
                        <Col xs={9} sm={9} md={5}>
                        <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683237500/PDS/sponsors/platinum/platinum.novartis_iba9nc.jpg"} style={{ height: 'auto' }} alt="altimage" data-bgposition="center center" data-no-retina />
                        </Col>
                    </Row>
                    </Container>
                    {/* ------->Gold Sponsors */}
                    <Container style={{
                        // marginTop:"10vh"
                        }}>
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                    <Row style={{marginTop:"5vh"}}>
                        <Col md={12}>
                        <h2 className="heading mb-5" style={{ textAlign: 'center' }}>Gold Sponsors</h2>                        
                        </Col>                    
                    </Row>                    
                    <Row className='d-flex justify-content-center align-items-center' style={{height:"20vh"}}>
                        <Col xs={5} sm={5} md={2}>
                            <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683235321/PDS/sponsors/gold/CSMI_logo_Gold_chqwbm.png"} style={{ height: 'auto' }} alt="CSMI" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        <Col xs={5} sm={5} md={2}>
                            <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683235321/PDS/sponsors/gold/gold.mixexpert_onwu8p.png"} style={{ height: 'auto' }} alt="MixExpert" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        <Col xs={5} sm={5} md={2}>
                            <img className='logosub ' src={"assets/img/indexcarousel/venn0.jpg"} style={{ height: 'auto' }} alt="VennAesthetics" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        <Col xs={6} sm={6} md={2}>
                            <img className='logosub ' src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1685351401/PDS/logo/gold.pfizer_ym7vqq_vol3wq.png"} style={{ height: 'auto' }} alt="Pfizer" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        <Col xs={2} sm={2} md={1} style={{marginTop:'3vh'}}>
                            <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683235321/PDS/sponsors/gold/gold_plus.sun_pharma_qeecbb.png"} style={{ height: 'auto' }} alt="SunPharma" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        <Col xs={2} sm={2} md={1} style={{marginTop:'3vh'}}>
                            <img className='logosub ' src={"https://res.cloudinary.com/development-mar/image/upload/v1683235319/PDS/sponsors/gold/goldplus.Unilever_kcupmn.png"} style={{ height: 'auto' }} alt="Unilever" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col>
                        {/* <Col xs={6} sm={6} md={2} style={{marginTop:'3vh'}}>
                        <img className='logosub ' src={"assets/img/indexcarousel/zpt.png"} style={{ height: 'auto' }} alt="ZPT" data-bgposition="d-flex align-items-center" data-no-retina />
                        </Col> */}
                    </Row>
                    </Container>
                      

                    
                                      
                    <Row style={{marginTop:"20vh"}}>
                        <Col>
                        <a href="https://www.imcas.com/en/attend/imcas-asia-2023" target="_blank">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        margin: '20px 0'
                                    }}
                                >
                                    <img src="https://res.cloudinary.com/development-mar/image/upload/v1683266862/PDS/promotion/600_160_aznenb.jpg" alt="altimage" data-bgposition="center center" data-no-retina style={{
                                        maxWidth: 'none',
                                        width: '70%',
                                    }} />
                                </div>
                                </a>
                        </Col>
                    </Row>
                </Container>                  
            </div>
        );
    }
}

export default SubContent;