/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'

export default function Template(props) {
  const [showNext, setShowNext] = useState(false);
  const [selected, setSelected] = useState(null);
  const [nextOption, setNextOption] = useState(null);

  const [pds, setPds] = useState("")
  const [email, setEmail] = useState("")

  const [message, setMessage] = useState("")
  const [isboard, setIsBoard] = useState(false)
  const [speakerSession, setSpeakerSession] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loginWithEmail, setLoginWithEmail] = useState(false)

  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState("")
  const [error, setError] = useState(false);

  const resetState = () => {
    setShowNext(false);
    setSelected(null);
    setNextOption(null);
  };

  useEffect(() => {
    if (props) {
      resetState();
    }
  }, [props]);

  const handleNextOption = () => {
    if (props.selectedOptions.includes('speaker') && props.selected === 'local') {
      // return props.handleNext(selected, 'option_4_5');
      return props.handleNext(props.selected, null);
    }

    if (props.selectedOptions.includes('speaker') && props.selected === 'international') {
      return props.handleNext(props.selected, null);
    }


    return props.handleNext(props.selected, nextOption);
  }

  const handleBackOption = () => {
    return props.handleBack();
  }

  const checkPDS = (e) => {
    setIsLoading(true)

    if (e !== "" && e.length === 6) {
      axios.get(`${env.API_URL}/metadata/pds/${e}`).then((res) => {
        try {

          // CHECK IF ALREADY REGISTERED
          axios.get(`${env.API_URL}/metadata/checkifregistered/${e}`).then((dt) => {
            props.setPds(e)
            setOpen(true)
            setUserData(res.data)
            props.setUserData(res.data)
            setMessage(res.data.firstName + ' ' + res.data.lastName)
            if (res.data.speakerSession) {
              setSpeakerSession(res.data.speakerSession)
            }
            if (res.data.isBoard) {
              setIsBoard(true)
            }
            setIsLoading(false)
          }).catch((err) => {
            setIsLoading(false)
            NotificationManager.error("You are not registered to the PDS Convention. Register for the event first before reserving workshops.", '', 5000);
          })

        } catch (e) {
          setIsLoading(false)
          setMessage(e)
        }
      }).catch((e) => {
        setIsLoading(false)
        NotificationManager.error("Please type in your correct PDS ID#", '', 5000);
        setMessage("Sorry, your PDS ID number is not found in our PDS membership list. You might have typed the wrong PDS ID number or you did not qualify as a member with good standing. ")
      })
    }
  }


  const checkEmail = (e) => {
    setIsLoading(true)

    if (e !== "" && e.length > 6) {
      try {
        // CHECK IF ALREADY REGISTERED
        axios.get(`${env.API_URL}/metadata/checkifregisteredemail/${e.toLowerCase()}`).then((dt) => {
          setError(false)
          props.setPds(dt.data.email)
          setOpen(true)
          setUserData(dt.data)
          props.setUserData(dt.data)

          setMessage(dt.data.firstName + ' ' + dt.data.lastName)
          if (dt.speakerSession) {
            setSpeakerSession(dt.speakerSession)
          }
          if (dt.isBoard) {
            setIsBoard(true)
          }
          setIsLoading(false)
        }).catch((err) => {
          setIsLoading(false)
          NotificationManager.error("You are not registered to the PDS Convention. Register for the event first before reserving workshops.", '', 5000);
        })

      } catch (e) {
        setIsLoading(false)
        setMessage(e)
      }
    } else {
      setIsLoading(false)
      NotificationManager.error("Please type in your correct email", '', 5000);
    }
  }

  const checkIfRegisteredWorkshop = (e) => {
    setIsLoading(true)
    // const accessToken = localStorage.getItem('accessToken')
    // if (!accessToken) {
    //   return NotificationManager.error("You need to login first to continue.", '', 5000);
    // }

    // UPDATE WORKSHOPS
    if (loginWithEmail) {
      let temp = []
      props.workshops.map((item) => {
        let regular = item.regular
        let handsOn = item.handsOn
        switch (props.userData.categoryId) {
          case "PDS Resident - Accredited Institution":
            handsOn = item.residentsAccreditedHandson
            regular = item.residentsAccreditedRegular
            break;
          case "PDS Resident - Applicant Institution":
            handsOn = item.residentsApplicantHandson
            regular = item.residentsApplicantRegular
            break;
          default:
            break;
        }

        temp.push({
          ...item,
          regular,
          handsOn,
        })
      })
      props.setWorkshops(temp)


    }
    const url = !loginWithEmail ? `${env.API_URL}/metadata/register/precon/${props.pds}` : `${env.API_URL}/metadata/register/preconemail/${email.toLowerCase()}`
    axios.get(url, {
      headers: {
        // 'authorization': 'Bearer ' + accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    ).then((dt) => {
      setIsLoading(false)

      // if (dt.message === "No workshops yet") {
      // Proceed
      console.log(dt.data)
      props.setEmail(dt.data.user.email)

      props.setFirstName(userData.firstName)
      props.setLastName(userData.lastName)
      props.setPdsNumber(pds)
      props.setSpeakerSession(userData.speakerSession)
      props.setBoardMember(isboard)
      if (isboard) {
        props.handleNext('board')
      } else {
        props.handleNext('speaker')
      }
      // return
      // }
      if (dt.data.data.workshops.length > 2) {
        props.setNoSlotsAvailable(true)
      }

      const temp = []

      props.workshops.map((item) => {
        let selectedHandsOn = false
        let selectRegular = false
        dt.data.data.workshops.map((dt) => {

          if (dt.id === item._id) {
            if (dt.type === "regular") {
              selectRegular = true
              selectedHandsOn = false
            } else {
              selectRegular = false
              selectedHandsOn = true
            }

            props.setSelected((prev) => [
              ...prev,
              {
                ...item,
                selectedHandsOn: selectedHandsOn,
                selectRegular: selectRegular
              }
            ])

            props.setDisabledList((prev) => [
              ...prev,
              item._id,
              ...item.disabledList
            ])

          }
        })
        temp.push({
          ...item,
          selectedHandsOn: selectedHandsOn,
          selectRegular: selectRegular
        })
      })
      props.setWorkshops(temp)

      props.setRegisteredWorkshops(dt.data.data.workshops)
      setOpen(false)

    }).catch((err) => {
      setIsLoading(false)
      setOpen(false)

      // console.log(err.data)
      if (err.error === 'jwt expired') {
        NotificationManager.error("Session Expired. You need to login to continue.", '', 5000);
      }
      // NotificationManager.error("Something went wrong.", '', 5000);
    })
  }

  return (
    <>
      {!props.loading && (
        <div className="animate">
          <NotificationContainer />

          <Modal
            open={open}
            onClose={() => {
              NotificationManager.error("Please type in your correct PDS ID#", '', 5000);
              setOpen(false)
            }}
            center
          >
            <div style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

              <h2 style={{ textAlign: 'center', lineHeight: 1.5 }}>Is this you?</h2>
              <p style={{ margin: 20, marginBottom: 5, fontSize: 'x-large', textAlign: 'center' }} >{message}</p>
              <p style={{ margin: 20, marginTop: 5, fontSize: 'x-large', textAlign: 'center' }} >{pds}</p>
              <div className="actions" style={{ display: "flex", marginTop: 10, bottom: 0, right: 0 }}>
                <ul>
                  <li style={{ width: "65%" }}>
                    <button onClick={(e) => {

                      checkIfRegisteredWorkshop()
                      // props.setFirstName(userData.firstName)
                      // props.setLastName(userData.lastName)
                      // // console.log('userData', userData)
                      // props.setPdsNumber(pds)
                      // props.setSpeakerSession(userData.speakerSession)
                      // props.setBoardMember(isboard)
                      // // console.log('userData.speakerSession', userData.speakerSession)
                      // if (isboard) {
                      //   props.handleNext('board')
                      // } else {
                      //   props.handleNext('speaker')
                      // }
                    }}
                      className="button-next-active" >{isLoading ? 'Loading' : 'Confirm'}</button>
                  </li>
                </ul>
              </div>
            </div>
          </Modal>

          <div className="wizard-forms">
            <div className="inner pb-50 clearfix">
              <div className="form-content pera-content" style={{ width: '100%' }}>
                <div className="step-inner-content">
                  <span className="step-no">Step 1</span>
                  <div className="step-progress float-right">
                    <span>1 of 5 completed</span>
                    <div className="step-progress-bar">
                      <div className="progress">
                        <div className="progress-bar" style={{ width: '10%' }} />
                      </div>
                    </div>
                  </div>

                  <h2>{!loginWithEmail ? props.details.title : "Please enter your email for validation"}</h2>
                  <div className="step-box">
                    <div className="row">
                      {props.currentOption === 'option_4_5' && (
                        <div className="col-md-12">
                          <input
                            type="text"
                            autoComplete="off"
                            maxLength={!loginWithEmail ? "6" : "50"}
                            value={!loginWithEmail ? pds : email}
                            className="form-control required"
                            minLength="2"
                            placeholder={!loginWithEmail ? "PDS Number *" : "Email*"}
                            required
                            onChange={(e) => {
                              function validEmail(e) {
                                var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                                return String(e).search(filter) !== -1;
                              }
                              if (!loginWithEmail) {
                                setPds(e.target.value.toUpperCase())
                                e.target.value.length === 6 && setShowNext(true)
                              } else {
                                setEmail(e.target.value.toUpperCase())
                                if (validEmail(e.target.value)) {
                                  setMessage('')
                                  setError(false)
                                  e.target.value.length > 6 && setShowNext(true)
                                } else {
                                  setMessage('Invalid Email')
                                  setError(true)
                                }
                              }

                            }}
                            style={{ textTransform: 'uppercase', fontSize: '20px' }}
                          />
                        </div>
                      )}
                      {error &&
                        <p style={{ color: error && 'red', marginBottom: 10 }}>{message}</p>
                      }
                      {props.options.length > 0 && props.options.map((option, index) => (
                        <div className={`col-md-${12 / props.options.length}`} key={index} data-animation="slideHorz">
                          <label className={`${props.selected === option.value ? 'active' : ''} step-box-content bg-white text-center relative-position`}>
                            <span className="step-box-icon">
                              <img src={option.icon} style={{ height: '80px' }} alt="" />
                            </span>
                            <span className="step-box-text">{option.label}</span>
                            <span className="service-check-option">
                              <span>
                                <input
                                  id={index}
                                  value={option.value}
                                  onChange={() => {
                                    setShowNext(true);
                                    setSelected(option.value);
                                    setNextOption(option.nextOption ?? null);
                                  }}
                                  type="radio"
                                  name={props.currentOption ?? null}
                                />
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                    {props.details.lads && (
                      <p style={{ paddingTop: 20 }}>
                        <font style={{ fontWeight: 'bold' }}>League of ASEAN Dermatological Societies (LADS)</font><br></br>

                        1. Indonesian Society for Dermatology and Venereology <br></br>
                        2. Dermatological Society of Malaysia<br></br>
                        3. Dermatological Society of Singapore<br></br>
                        4. Dermatological Society of Thailand<br></br>
                        5. Vietnam Society of Dermatology and Venereology<br></br>
                        6. Cambodia Dermatological Society<br></br>
                      </p>
                    )}
                    <p style={{ paddingTop: 20 }}>
                      {props.details.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="actions" style={{ display: 'flex' }}>
            <ul>
              <li>
                <button
                  // style={{ width: !loginWithEmail && 420 }}
                  onClick={() => {
                    setLoginWithEmail(!loginWithEmail)
                    // handleBackOption()
                    // props.currentOption === 'option_4_5'
                    //   ? checkPDS(pds)
                    //   : handleNextOption();
                  }}
                  className={`button-next-active`}
                >
                  {!loginWithEmail ? "Use email" : "Use PDS ID"}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    if (props.currentOption === 'option_4_5') {
                      if (!loginWithEmail) {
                        checkPDS(pds)
                      } else {
                        checkEmail(email)
                      }
                    } else {
                      handleNextOption()
                    }
                  }}
                  disabled={!showNext}
                  className={`${showNext ? 'button-next-active' : ''} button-next`}
                >
                  {props.currentOption === 'option_4_5' ? !isLoading ? 'Validate' : 'Loading' : 'Next'}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
