const sliderSmall = [
  
  {
    id: 5,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/1B.jpeg',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 6,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/2B.jpg',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },{
    id: 7,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/3B.png',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 8,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/4B.png',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 9,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/5B.png',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 10,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/6B.png',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 11,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350926/PDS/slide_1_xzyyjm.jpg',
    srcimg: 'assets/img/indexcarousel/1A.jpg',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
    datax: "['center','center','center','center']",
    datahset: "['0','0','0','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-center'
  },
  {
    id: 12,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_2_oyud0j.jpg',
    srcimg: 'assets/img/indexcarousel/2.jpg',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
  {
    id: 13,
    // srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1685350927/PDS/slide_3_se9tr9.jpg',
    srcimg: 'https://res.cloudinary.com/duuldyiu5/image/upload/v1688969396/PDS/3_1_sqka5b.jpg',
    classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
    classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
    datax: "['right','right','right','center']",
    datahset: "['-14','-14','-14','0']",
    datavset1: "['-134','-134','-134','-134']",
    datavset2: "['-63','-63','-63','-63']",
    datavset3: "['2','2','2','2']",
    datavset: "['106','106','106','106']",
    classbtn: 'tp-caption text-right'
  },
];

export default sliderSmall;
