const menus = [
  {
    id: 1,
    name: 'Home',
    link: '/',
  },
  // {
  //   id: 2,
  //   name: 'About Us',
  //   link: '/about-us',
  // },
  // {
  //   id: 4,
  //   name: 'Programme',
  //   link: '/programme',
  // }, 
  // {
  //   id: 5,
  //   name: 'Speakers',
  //   link: '/speakers',
  // }, 
  {
    id: 7,
    name: 'Register',
    link: null,
    namesub: [
      {
        id: 7,
        sub: 'Convention',
        links: '/register'
      },
      {
        id: 8,
        sub: 'Precon',
        links: '/precon'
      },
    ]
  },
  {
    id: 10,
    name: 'Contact Us',
    link: '/contact-us'
  }
];

export default menus;
