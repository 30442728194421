import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IconBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconbox: [
                {
                    id: 1,
                    icon: "autora-icon-quality",
                    title: "EARLY BIRD RATE",
                    desc: "(Until August 15, 2022)",
                    text: "USD 100 (ASD Member)",
                    text2: "USD 200 (Non-ASD Member)",
                    text3: "USD 50 (Resident/Fellow)"
                },
                {
                    id: 2,
                    icon: "autora-icon-quality",
                    title: "ON TIME",
                    desc: "(From August 16, 2022 to September 24, 2022)",
                    text: "USD 150 (ASD Member)",
                    text2: "USD 250 (Non-ASD Member)",
                    text3: "USD 100 (Resident/Fellow)"
                },
                // {
                //     id: 3,
                //     icon: "autora-icon-author",
                //     title: "EXPERIENCED",
                //     text: "Autora Construction Services are committed to meeting the highest quality standards without compromising our safety culture.."
                // }
            ]
        }
    }

    render() {
        return (
            <div className="themesflat-row separator drank clearfix row">
                {
                    this.state.iconbox.map(data => (
                        <div
                            className="col-md-6 pd33"
                            key={data.id}>
                            <div
                                className="themesflat-icon-box icon-top align-center has-width circle light-bg accent-color border-light padding-inner pd33 m10 style-1 clearfix">
                                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                                <div className="themesflat-content-box clearfix" data-margin="0 5px 0 5px" data-mobilemargin="0 0 0 0">
                                    <div className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
                                        <div className="icon-wrap">
                                            <i className={data.icon} />
                                        </div>
                                        <div className="text-wrap">
                                            <h5 className="heading"><Link to="#">{data.title}</Link></h5>
                                            <p className="sub-heading">{data.desc}</p>
                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />

                                            <div className="sep clearfix" />
                                            <p className="sub-heading">{data.text}</p>
                                            <p className="sub-heading">{data.text2}</p>
                                            <p className="sub-heading">{data.text3}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))
                }
            </div>
        );
    }
}

export default IconBox;