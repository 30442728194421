import React, { useState } from 'react';

import Template from './sub/template';
import { registrationOptions } from './constant';

export default function StepOne(props) {
  const [currentOption, setCurrentOption] = useState('option_1');
  const [prevOption, setPrevOption] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentOptionPath, setCurrentOptionPath] = useState(['option_1']);


  const handleNext = (value, nextOption) => {
    if (!nextOption) return props.handleNext('step_2', [...selectedOptions.filter(Boolean), value]);


    setSelectedOptions([...selectedOptions.filter(Boolean), value]);
    setCurrentOption(nextOption);
    setCurrentOptionPath([...currentOptionPath, nextOption])
    setPrevOption([...prevOption, nextOption])
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  // console.log(selectedOptions)

  const handleBack = () => {

    selectedOptions.pop()
    // setSelectedOptions(temp);

    if (currentOptionPath.length < 3) {
      setCurrentOption('option_1')
      setCurrentOptionPath(['option_1'])
      return
    }
    let curScreen = currentOptionPath[currentOptionPath.length - 2]
    setCurrentOption(curScreen);

    const updatedArray = currentOptionPath.slice(0, -1);
    setCurrentOptionPath(updatedArray);
  };


  return (
    <div className={`${props.currentForm === 0 ? 'js-active' : ''} multisteps-form__panel`}>
      <div className="wizard-forms">
        <Template
          loading={loading}
          showBack={currentOptionPath.length < 2}
          selectedOptions={selectedOptions}
          currentOption={currentOption}
          details={registrationOptions[currentOption].details}
          options={registrationOptions[currentOption].options}
          handleNext={handleNext}
          handleBack={handleBack}

          setFirstName={props.setFirstName}
          setLastName={props.setLastName}
          setPdsNumber={props.setPdsNumber}
          setEmail={props.setEmail}

        />
      </div>
    </div>

  );
}
