import React, { useState, useEffect } from 'react';

export default function Success(props) {
  const [currentForm, setCurrentForm] = useState(0);

  useEffect(async () => {
    window.close('', '_parent', '');
  }, []);

  const handleNext = () => {
    // console.log(firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution)
    setCurrentForm(currentForm + 1);
  };
  const handleBack = () => {
    setCurrentForm(currentForm - 1);
  };

  return (

    <div className="wrapper">
      <div className="multisteps-form__form">
        <div style={{ display: 'flex', alignItems: 'center', justifyContents: 'center' }}>
          Close this window
        </div>
      </div>
    </div>

  );
}
