import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Counter, LeaderTeam } from '../layouts/about'
import BannerTitle from '../layouts/about/banner/BannerTitle'
import { Slider, TitleHeading, IconBox, About, Overview, Faq, Partner } from '../layouts/home01/index'
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index'

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo_mobile.png",
                    names: "About Us",
                }
            ],
            headingchoose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'YOUR BEST CHOOSE',
                    text: 'More than 500 projects completed in Autora - With over 25 years of combined experience, we have the knowledge to serve you.',
                    classtext: 'sub-heading',
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'About Us',
                    heading: 'About Us'
                }
            ],
            accordions: [
                {
                    id: 1,
                    classnames: 'accordion-item',
                    title: 'ASD Board of Directors 2021-2022',
                    text: 'Yu-Hung Wu, MD President M. Ramam, MD Vice-President Suat Hoon Tan, MD Secretary-General Thuy L. Phung, MD, PhD Treasurer'
                },
                {
                    id: 2,
                    classnames: 'accordion-item',
                    title: 'ASD Executive Committee',
                    text: 'Phyu P. Aung, MD, Myanmar Steven Chow, MD, Kuala Lumpur Santosh Upadhyaya Kafle, MD, Biratnagar Dong-Yuon Lee, MD, Seoul Nopadon Noppakun, MD, Bangkok Arnelfa C. Paliza, MD, Manila Thuy L. Phung, MD, PhD Vietnam M. Ramam, MD, New Delhi Sondang Aemilia Pandjaitan Sirait, MD, Jakarta Jianfang Sun, MD, Nanjing Suat Hoon Tan, MD, Singapore Daisuke Tsuruta, MD, PhD Osaka Yu-Hung Wu, MD, Taiwan'
                },
            ],
            accordions2: [
                {
                    id: 1,
                    classnames: 'accordion-item',
                    title: 'Board of Directors, 2021-2022',
                    text: 'President: Arnelfa C. Paliza, MD, FPDS Vice President: Georgina C. Pastorfide, MD, FPDS Secretary: Mara P. Evangelista-Huber, MD, FPDS Treasurer: Eileen Liesl A. Cubillan, MD, FPDS PRO: Johannes F. Dayrit, MD, FPDS'
                },
                {
                    id: 2,
                    classnames: 'accordion-item',
                    title: 'Committee Chairs',
                    text: 'Constitution & By-Laws: Daisy K. Ismael, MD, FPDS Continuing Medical Education: Ma. Flordeliz A. Casintahan, MD, FPDS Ethics: Filomena Legarda-Montinola, MD, FPDS Membership: Gwendolyn Y. Wong, MD, FPDS Public Relations & External Affairs: Kara Melissa T. Torres-Culala, MD, FPDS Research: Maria Jasmin I. Jamora, MD, FPDS'
                },
                {
                    id: 3,
                    classnames: 'accordion-item',
                    title: 'Board of Examiners, 2021-2022',
                    text: 'Chair: Georgina C. Pastorfide, MD, FPDS Members: Eileen Liesl A. Cubillan, MD, FPDS Daisy K. Ismael, MD, FPDS'
                },
            ],
            accordions3: [
                {
                    id: 1,
                    classnames: 'accordion-item',
                    title: 'Organizing Committee Members',
                    text: 'OVERALL CHAIR  Arnelfa C. Paliza, MD, FPDS    SCIENTIFIC  Maria Jasmin J. Jamora, MD, FPDS - Chair  Ma. Flordeliza Casintahan, MD, FPDS  Bryan Guevara, MD, FPDS  Maria Isabel Beatriz Puno-Gomez, MD, FPDS    WAYS & MEANS  Georgina C. Pastorfide, MD, FPDS - Chair  Maria Franchesca S. Quinio, MD, FPDS    PHYSICAL ARRANGEMENTS  Andrea Marie B. Bernales- Mendoza, MD, DPDS - Chair  Johaness F. Dayrit. MD, FPDS  Erin Jane L. Tababa- Santos, MD, FPDS    PUBLICITY & AWARDS  Kara Melissa Torres-Culala, MD, FPDS - Chair  Terese Monette Aquino-Agas, MD, FPDS  Maria Isabel Beatriz Puno-Gomez, MD, FPDS  Mary Grace Anne Calvarido, MD, DPDS    REGISTRATION  Mary Elizabeth Danga, MD, FPDS - Chair  Tanya Perez-Chua, MD, FPDS    RESEARCH  Mara Therese P. Evangelista-Huber, MD, FPDS - Chair  Eileen Liesl A. Cubillan, MD, FPDS  Riza R. Milante, MD, FPDS'
                },
            ]
        }
    }
    render() {
        const myStyle = {
            backgroundImage:
                "url('assets/img/slider/bg.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };


        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div style={myStyle} >
                            <Slider />
                        </div>

                        <div id="site-header-wrap" style={{height: "61px"}}>
                            {/* <TopBar /> */}
                            {
                                this.state.headers.map((data, idx) => (
                                    <Header data={data} key={idx} />
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data => (
                                <BannerTitle key={data.id} data={data} />
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <div className="container">
                                                <div id="inner-content" className="inner-content-wrap">
                                                    <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                                                    <div className="themesflat-headings style-2 clearfix">
                                                        <h2 className="heading">About the Philippine Dermatological Society (PDS)</h2>
                                                        <div className="sep has-width w80 accent-bg clearfix" />
                                                        <div className="container">
                                                            <div className="row justify-content-md-center">
                                                                <div className="col-md-4" style={{display: 'flex'}}>
                                                                    <img className='logosub2' src={"https://res.cloudinary.com/development-mar/image/upload/v1683273300/PDS/PDS_LOGO-02_wmhc0a.png"} alt="altimage" data-bgposition="center center" data-no-retina style={{margin: 'auto', display:'block'}}/>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <p style={{ lineHeight: 1.5, textAlign: 'justify' }} className="sub-heading line-height-40 text-777 margin-top-28 margin-right-12">
                                                                        From its establishment in 1952 up to the present, the Philippine Dermatological Society (PDS) continually strengthens its mandate to be the respected and recognized leader in Dermatology. The PDS aims to be a highly recognized society, locally and globally, striving towards excellence in the attainment of healthy skin for all.  The society’s progress is guided by the five pillars of education, research, outreach, engagement, and advocacy.
                                                                        The core advocacy of the PDS is “Skin Health and Skin Safety” which is why the slogan, “ PDS DermAuthority” was coined to perfectly define a PDS dermatologist - a properly trained, competent and ethical physician who is a true expert on skin, hair, and nails.
                                                                        Ultimately, the aim of the Philippine Dermatological Society (PDS) is to be the leading organization that promotes the advancement of dermatology as a medical specialty and the highest standard of skin health care in the Philippines.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="container">
                                                            <div className="row">
                                                                <div>
                                                                    <p style={{ lineHeight: 1.5, textAlign: 'justify' }} className="sub-heading line-height-40 text-777 margin-top-28 margin-right-12">
                                                                        The only specialty society in DERMATOLOGY recognized by the Philippine Medical Association and the Philippine College of Physicians.
                                                                    </p>
                                                                </div>                                                                
                                                            </div>
                                                            <div className='row justify-content-md-center'>
                                                                <div className="col-md-6 mt-5">
                                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1685408948/PDS/logo/logos_cxdmeq.png"} alt="altimage" data-bgposition="center center" data-no-retina />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                    <div className="themesflat-spacer clearfix" data-desktop={45} data-mobile={35} data-smobile={35} />

                                                    <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0" >
                                                        <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">

                                                            <div className="accordion-item" >
                                                                <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                                                                    Board of Directors
                                                                </span></h3>
                                                                <div className="accordion-content">
                                                                    <div className='container'>
                                                                        <div className='row justify-content-md-center'>
                                                                            <div className='col-md-8 mt-5'>
                                                                                <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/board0.jpg"} alt="board" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ lineHeight: 1.5, color: 'black' }} >
                                                                            <br />
                                                                            <font style={{ fontWeight: 'bold' }}> President </font> Cynthia Ciriaco-Tan, MD, FPDS  <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Vice President </font> Maria Jasmin J. Jamora, MD, FPDS  <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Secretary </font> Camille Berenguer-Angeles, MD, FPDS  <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Treasurer </font>Antonio C. Sison, MD, FPDS  <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Immediate Past President </font>Francisco D. Rivera IV, MD, FPDS  <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Zharlah Gulmatico-Flores, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Claudine Rae Lagman-Javier, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Ma. Michellende Dolores G. Gatchalian, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Maria Cristina A. Puyat, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Ma. Deanna S. Ramiscal, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Liza Marie R. Paz-Tan, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Mary Jane G. Uy, MD, FPDS <br /><br />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            {/* <div className="accordion-item" >
                                                                <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                                                                    Members
                                                                </span></h3>
                                                                <div className="accordion-content">
                                                                    <div>
                                                                        <p style={{ lineHeight: 1.5, color: 'black' }} >
                                                                            <br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Zharlah Gulmatico-Flores, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Claudine Rae Lagman-Javier, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Ma. Michellende Dolores G. Gatchalian, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Maria Cristina A. Puyat, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Ma. Deanna S. Ramiscal, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Liza Marie R. Paz-Tan, MD, FPDS <br /><br />
                                                                            <font style={{ fontWeight: 'bold' }}> Member </font>Mary Jane G. Uy, MD, FPDS <br /><br />

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                                                </div>


                                                <div className="themesflat-headings style-2 clearfix">
                                                    <h2 className="heading">About the Annual Convention</h2>
                                                    <div className="sep has-width w80 accent-bg clearfix" />
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <img className='logosub2' src={"https://res.cloudinary.com/duuldyiu5/image/upload/v1685409625/PDS/logo/eventlogo_wfxrzb.png"} alt="altimage" data-bgposition="center center" data-no-retina />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <p style={{ lineHeight: 1.5, textAlign: 'justify' }} className="sub-heading line-height-40 text-777 margin-top-28 margin-right-12">
                                                                    Our theme this year is "Future Forward: Fundamentals to Advances". We aim to provide our members with knowledge regarding advances in dermatology, and still include the must-know principles. The three years of the pandemic affected the training and exposure specifically of our young members. We hope to address this gap and provide them with new cutting-edge techniques in medical and procedural dermatology and at the same time reinforce the basics.
                                                                    <br></br><br></br>
                                                                    Expand your expertise through many of expert-led lectures, workshops and make lasting IN-PERSON connections this year!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />


                                                <div id="inner-content" className="inner-content-wrap">
                                                    {/* <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} /> */}
                                                    <div className="themesflat-headings style-2 clearfix">
                                                        <h2 className="heading">PDS Annual Convention Organizing Committee</h2>
                                                        <div className="sep has-width w80 accent-bg clearfix" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="container " >
                                            <div className="row">
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/1.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/2A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/3A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/4A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/5A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/6.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/7A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/8.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/9.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/10B.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/11.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/12B.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/13.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/14A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/15A.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/16.jpg"} alt="board" />
                                                </div>
                                                <div className="col-md-12 p-2 ">
                                                    <img style={{ width: '100%', maxWidth: '100%' }} src={"assets/img/aboutus/17A.jpg"} alt="board" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                    {
                        this.state.headers.map((data, idx) => (
                            <BottomBar data={data} key={idx} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default AboutUs;