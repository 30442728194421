import React, { useState, useEffect } from "react";
import axios from 'axios';
import env from 'react-dotenv';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Modal } from 'react-responsive-modal'

export default function StepTwoSession(props) {
    const [rsvp, setRSVP] = useState(false)
    const [modified, setModified] = useState(0);
    const [open, setOpen] = useState(false);

    const [userLabel, setUserLabel] = useState("Delegate");

    const [fellowshipList, setFellowshipList] = useState([{
        "title": "Free Fellowship Night Slot",
        "firstname_0": props.firstName !== "" ? props.firstName : "",
        "lastname_0": props.lastName !== "" ? props.lastName : "",
        "isSelected_0": props.fellowship
    },
    {
        "title": "Accompanying Person for Fellowship",
        "firstname_1": "",
        "lastname_1": "",
        "email": "",
        "isSelected_1": props.accompany
    }
    ]);

    useEffect(() => {
    }, [setUserLabel])

    const getName = () => {
        if (props.speakerSession) {
            // setUserLabel("Speaker")
            return ("Speaker")
        }
        if (props.boardMember) {
            // setUserLabel("Board Member")
            return ("Board Member")
        }
    }

    const getFormattedString = (value) => {
        var re = '\\d(?=(\\d{' + 3 + '})+' + '\\D' + ')';
        var num = value.toFixed(Math.max(0, ~~2));
        var str = num.replace(new RegExp(re, 'g'), '$&' + ',');
        return str;
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        let temp = []

        fellowshipList.map((item) => {
            let tempItem = item
            if (item.hasOwnProperty(name)) {
                tempItem = { ...item, [name]: value }
            }
            temp.push(tempItem)
        })
        setFellowshipList(temp);
    };

    const getTotal = () => {
        let price = 0
        if (fellowshipList[1].isSelected_1) {
            price = 3500
        }
        return price
    }

    const shouldNext = () => {
        let res = 0
        if (props?.userData?.fellowship) {
            res++
        }

        if (props?.userData?.accompany) {
            res++
        }

        if (props?.userData?.businessMeeting) {
            res++
        }
        return res < 3
    }

    const getAccompany = () => {
        return fellowshipList[1]['isSelected_1'] | props?.userData?.accompany ? true : false
    }

    const getFellowship = () => {
        return props?.userData?.fellowship | fellowshipList[0]['isSelected_0'] ? true : false
    }

    const getHasEdit = () => {
        let res = 0

        if (fellowshipList[0].isSelected_0) {
            res++
        }
        if (fellowshipList[1].isSelected_1) {
            if (fellowshipList[1].firstname_1 !== "" && fellowshipList[1].lastname_1 !== "" && fellowshipList[1].email !== "") {
                res++
            }
        }
        if (rsvp) {
            res++
        }
        return res > 0 ? true : false
    }

    const getEnableCompany = () => {
        return !props?.userData?.accompany ? props?.userData?.fellowship : fellowshipList[0]['isSelected_0']
    }

    return (
        <div className={props.currentForm === 0.5 ? 'multisteps-form__panel js-active' : 'multisteps-form__panel'} data-animation="slideHorz">
            <NotificationContainer />

            <Modal
                open={open}
                onClose={() => { setOpen(false) }}
                center
            >
                <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    <h2 style={{ textAlign: 'center', lineHeight: 1.5, marginTop: '2rem', marginBottom: '2rem' }}>Select delegate type</h2>
                    <fieldset>
                        <div className="row">

                            <div className="col-6">
                                <div className="session-wrapper" style={{
                                    height: '100%',
                                    display: 'grid'
                                }}>

                                    <div className="session-group">

                                        <div className="session-form col-12 p-2" style={{ opacity: 1 }}>
                                            <div style={{ width: "10%" }}>
                                                <input
                                                    type="radio"
                                                    style={{ width: 30, height: 30 }}
                                                    className="radio mr-4"
                                                    name='delegate_select'
                                                    // name={workshop.assignment}
                                                    // disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                    onChange={(e) => {

                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: "90%" }}>
                                                <p style={{ marginLeft: 10 }}> <font style={{ fontSize: 30 }}>Regular Delegate</font><br></br><br></br> <font>Participants will be able to learn and observe workshops onsite and through live streaming via LED screen</font></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="session-wrapper">
                                    <div className="session-group">

                                        <div className="session-form col-12 p-2" style={{ opacity: 1 }}>
                                            <div style={{ width: "10%" }}>
                                                <input
                                                    type="radio"
                                                    style={{ width: 30, height: 30 }}
                                                    className="radio mr-4"
                                                    name='delegate_select'
                                                    // name={workshop.assignment}
                                                    // disabled={!isInDisabledList ? workshop.slots === 0 : true}
                                                    onChange={(e) => {

                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: "90%" }}>
                                                <p style={{ marginLeft: 10 }}> <font style={{ fontSize: 30 }}>Hands-On Delegate</font><br></br><br></br> <font >Participants have the unique opportunity to learn and perform the procedures on live patients with direct supervision by speakers</font></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </Modal>

            <div className="wizard-forms">
                <div className="inner pb-30 clearfix">
                    <div className="form-content pera-content">
                        <div className="step-inner-content">
                            <span className="step-no bottom-line">Step 2</span>
                            <div className="step-progress float-right">
                                <span>2 of 4 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: '40%' }} />
                                    </div>
                                </div>
                            </div>
                            <h2>Hello, you are registering for fellowship night and business meeting</h2>
                            <p>(You have one free slot. The entrance fee for plus ones are Php 3,500.00)</p>
                            {/* <br></br> */}
                            {/* <p style={{ marginLeft: 10 }}><font > <font style={{ fontSize: 30 }}>Hands-On Delegate </font>  </font><br></br> <font >Participants have the unique opportunity to learn and perform the procedures on live patients with direct supervision by speakers</font></p> */}

                            <div className="form-inner-area">
                                <div className="row py-3" style={{ marginTop: 16 }}>

                                    <div className="col-12">

                                        {props.noSlotsAvailable &&
                                            <p style={{ color: 'red' }}>You have exceeded the maximum slots</p>

                                        }
                                        <div className="session-group">

                                            {/* FIRST FIELD */}
                                            <div style={{
                                                pointerEvents: 'none',
                                                opacity: 0.5,
                                                // pointerEvents: fellowshipList[0]['isSelected_0'] ? 'none' : 'all', opacity: fellowshipList[0]['isSelected_0'] ? 0.5 : 1,
                                                marginBottom: 10, padding: 20, borderWidth: 1, borderColor: 'grey', borderStyle: 'dashed'
                                            }}>

                                                <div className="session-form row col-12 p-2" style={{ opacity: 1 }}>
                                                    <div className="col-lg-6 col-xs-12" style={{ paddingRight: 10, marginBottom: 0 }}>
                                                        <p >{fellowshipList[0].title}<br></br>
                                                            {/* <font style={{ fontSize: 15 }}>600 slots remaining</font> */}
                                                        </p>
                                                        {/* {index === 0 &&
                                                            <>
                                                                {props?.userData?.fellowship &&
                                                                    <p style={{ color: 'green' }}>Reserved</p>
                                                                }
                                                            </>
                                                        } */}
                                                    </div>


                                                    <div className="col-lg-6 col-xs-6" >
                                                        <div className="w-100" style={{
                                                            // pointerEvents: fellowshipList[0]['isSelected_0'] ? 'none' : 'all', 
                                                            display: 'flex', flexDirection: 'row-reverse'
                                                        }} >

                                                            {/* <div
                                                                type="checkbox"
                                                                checked={fellowshipList[0]['isSelected_0']}
                                                                className={`mr-4 checkbox-custom ${props?.userData?.fellowship | fellowshipList[0]['isSelected_0'] ? 'checked' : ''}`}
                                                                name={fellowshipList[0].title}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (props?.userData?.fellowship) {
                                                                        return
                                                                    }

                                                                    if (!fellowshipList[0]['isSelected_1']) {
                                                                        let temp = [...fellowshipList]
                                                                        temp[1].firstname_1 = ""
                                                                        temp[1].lastname_1 = ""
                                                                        temp[1].email = ""
                                                                        temp[1].isSelected_1 = ""
                                                                        setFellowshipList(temp)
                                                                    }

                                                                    handleChange({ target: { name: 'isSelected_0', value: !fellowshipList[0]['isSelected_0'] } })
                                                                }}
                                                            /> */}
                                                            <p style={{ marginRight: 10 }}>{fellowshipList[0]['isSelected_0'] && 'Claim slot'}</p>

                                                        </div>
                                                    </div>
                                                </div>

                                                {getFellowship() &&
                                                    <div className='w-100' style={{ marginTop: 10 }}>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-sm-12">
                                                                <input style={{ height: 50 }} onChange={handleChange} value={props.firstName} type="text" name={`firstname_0`} id={`firstname_0`} className="form-control required" minLength="2" placeholder="First Name *" required autocomplete="off" />
                                                            </div>
                                                            <div className="col-lg-6 col-sm-12">
                                                                <input style={{ height: 50 }} onChange={handleChange} value={props.lastName} type="text" name={`lastname_0`} id={`lastname_0`} className="form-control required" minLength="2" placeholder="Last Name *" required autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {props?.userData?.fellowship &&
                                                    <p style={{ color: 'green' }}>Reserved</p>
                                                }
                                                <p style={{ color: 'green' }}>Slots are full</p> */}


                                                {props?.userData?.fellowship ?
                                                    <p style={{ color: 'green' }}>Reserved</p>
                                                    :
                                                    <p style={{ color: 'green' }}>Slots are full</p>
                                                }

                                            </div>
                                            {/* FIRST FIELD */}



                                            {/* SECOND FIELD */}
                                            <div style={{
                                                pointerEvents: 'none',
                                                // pointerEvents: !getEnableCompany() ? 'none' : 'all',
                                                opacity: !getEnableCompany() ? 0.5 : 1,
                                                marginBottom: 10, padding: 20, borderWidth: 1, borderColor: 'grey', borderStyle: 'dashed'
                                            }}>

                                                <div className="session-form row col-12 p-2" style={{ opacity: 1 }}>
                                                    <div className="col-lg-6 col-xs-12" style={{ paddingRight: 10, marginBottom: 0 }}>
                                                        <p >{fellowshipList[1].title}<br></br>
                                                            {/* <font style={{ fontSize: 15 }}>600 slots remaining</font> */}
                                                        </p>
                                                    </div>


                                                    <div className="col-lg-6 col-xs-6" >
                                                        <div className="w-100" style={{
                                                            // pointerEvents: fellowshipList[1]['isSelected_1'] ? 'none' : 'all',
                                                            display: 'flex', flexDirection: 'row-reverse'
                                                        }} >

                                                            {/* <div
                                                                type="checkbox"
                                                                checked={fellowshipList[1]['isSelected_1']}
                                                                className={`mr-4 checkbox-custom ${props?.userData?.accompany ? 'checked' : fellowshipList[1]['isSelected_1'] ? 'checked' : ''}`}
                                                                name={fellowshipList[1].title}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (props?.userData?.accompany) {
                                                                        return
                                                                    }

                                                                    if (!fellowshipList[1]['isSelected_1']) {
                                                                        let temp = [...fellowshipList]
                                                                        temp[1].firstname_1 = ""
                                                                        temp[1].lastname_1 = ""
                                                                        temp[1].email = ""
                                                                        setFellowshipList(temp)
                                                                    }

                                                                    if (fellowshipList[0]['isSelected_0']) {
                                                                        handleChange({ target: { name: 'isSelected_0', value: !fellowshipList[0]['isSelected_0'] } })
                                                                    }

                                                                    handleChange({ target: { name: 'isSelected_1', value: !fellowshipList[1]['isSelected_1'] } })
                                                                }}
                                                            /> */}
                                                            <p style={{ marginRight: 10 }}>{fellowshipList[1]['isSelected_1'] && 'Claim slot'}</p>

                                                        </div>
                                                    </div>
                                                </div>

                                                {getAccompany() &&
                                                    <div className='w-100' style={{ marginTop: 10 }}>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-sm-12">
                                                                <input style={{ height: 50 }} onChange={handleChange} value={props?.userData?.accompany_first_name !== "" ? props?.userData?.accompany_first_name : fellowshipList[1]['firstname_1']} type="text" name={`firstname_1`} id={`firstname_1`} className="form-control required" minLength="2" placeholder="First Name *" required autocomplete="off" />
                                                            </div>
                                                            <div className="col-lg-6 col-sm-12">
                                                                <input style={{ height: 50 }} onChange={handleChange} value={props?.userData?.accompany_last_name !== "" ? props?.userData?.accompany_last_name : fellowshipList[1]['lastname_1']} type="text" name={`lastname_1`} id={`lastname_1`} className="form-control required" minLength="2" placeholder="Last Name *" required autocomplete="off" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-sm-12">
                                                                <input style={{ height: 50 }} onChange={handleChange} value={props?.userData?.accompany_email !== "" ? props?.userData?.accompany_email : fellowshipList[1].email} type="text" name={`email`} id={`email`} className="form-control required" minLength="2" placeholder="Email *" required autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {props?.userData?.accompany &&
                                                    <p style={{ color: 'green' }}>Reserved</p>
                                                }
                                                <p style={{ color: 'green' }}>Slots are full</p> */}

                                                {props?.userData?.accompany ?
                                                    <p style={{ color: 'green' }}>Reserved</p>
                                                    :
                                                    <p style={{ color: 'green' }}>Slots are full</p>
                                                }

                                            </div>
                                            {/* } */}
                                            {/* </>
                                            } */}
                                            {/* SECOND FIELD */}




                                            <div style={{
                                                pointerEvents: 'none',
                                                // pointerEvents: !getEnableCompany() ? 'none' : 'all',
                                                opacity: !getEnableCompany() ? 0.5 : 1,
                                                marginBottom: 10, padding: 20, borderWidth: 1, borderColor: 'grey', borderStyle: 'dashed'
                                            }}>

                                                <div className="session-form row col-12 p-2">


                                                    <div className="col-lg-6 col-xs-12" style={{ paddingRight: 10, marginBottom: 0 }}>
                                                        <p >I will be attending the PDS Business Meeting on November 7th<br></br></p>
                                                        {/* {props?.userData?.businessMeeting &&
                                                            <p style={{ color: 'green' }}>Reserved</p>
                                                        }
                                                        <p style={{ color: 'green' }}>Slots are full</p> */}

                                                        {props?.userData?.businessMeeting ?
                                                            <p style={{ color: 'green' }}>Reserved</p>
                                                            :
                                                            <p style={{ color: 'green' }}>Slots are full</p>
                                                        }
                                                    </div>


                                                    <div className="col-lg-6 col-xs-6" >
                                                        <div className="w-100" style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                                            <div
                                                                type="checkbox"
                                                                checked={!props?.userData?.businessMeeting ? rsvp : true}
                                                                className={`mr-4 checkbox-custom ${props?.userData?.businessMeeting ? 'checked' : rsvp ? 'checked' : ''}`}
                                                                name={'rsvp'}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setRSVP(!rsvp)
                                                                    props.setRSVP(!rsvp)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {getTotal() > 0 &&

                                                <div style={{ marginTop: 20 }} className="row col-12 p-2">
                                                    <div className="col-lg-6 col-sm-12">
                                                        {/* <p>Workshops</p> */}
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12" >
                                                        <h3 style={{ textAlign: 'right', width: '100%' }}>Total: Php {getFormattedString(getTotal())}</h3>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="actions">
                    <ul>
                        {shouldNext() &&
                            <>
                                {getHasEdit() &&
                                    <li>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.setSessions([{ ...fellowshipList[0], firstname_0: props.firstName, lastname_0: props.lastName }, { ...fellowshipList[1] }]);
                                                props.handleNext('step_2_5');
                                                props.setTotal(getTotal())
                                                // setOpen(true)
                                            }}
                                            className={'themesflat-button bg-accent'}
                                        // disabled={props.noSlotsAvailable ? true : props.checkoutSelected.length === 0}
                                        >
                                            NEXT

                                        </button>
                                    </li>
                                }
                            </>
                        }
                        {/* }
                            </>

                        } */}
                    </ul>
                </div>
            </div>
        </div>
    )
}