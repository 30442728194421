import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';

class LeaderTeam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lead: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/plenary/Slide18.PNG',
                    name: 'Martin Barsch, MD, MBA',
                    position: 'Linz, Austria',
                    image: 'assets/img/speakers/plenary/Slide18.PNG'
                },
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/plenary/Slide19.PNG',
                    name: 'Martin Barsch, MD, MBA',
                    position: 'Linz, Austria',
                    image: 'assets/img/speakers/plenary/Slide19.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/plenary/Slide2.PNG',
                    name: 'David H. Ciocon, MD',
                    position: 'New York City, NY, USA',
                    image: 'assets/img/speakers/plenary/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/plenary/Slide3.PNG',
                    name: 'Chandra Co',
                    position: '',
                    image: 'assets/img/speakers/plenary/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/plenary/Slide4.PNG',
                    name: 'Iñigo De Felipe',
                    position: '',
                    image: 'assets/img/speakers/plenary/Slide4.PNG'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/speakers/plenary/Slide5.PNG',
                    name: 'Rowena Natividad Genuino',
                    position: '',
                    image: 'assets/img/speakers/plenary/Slide5.PNG'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/speakers/plenary/Slide6.PNG',
                    name: 'Chao-Kai Hsu, MD, PhD',
                    position: 'Tainan, Taiwan',
                    image: 'assets/img/speakers/plenary/Slide6.PNG'
                    
                },
                {
                    id: 7,
                    srcimg: 'assets/img/speakers/plenary/Slide7.PNG',
                    name: 'Daisy King Ismael',
                    position: '',
                    image: 'assets/img/speakers/plenary/Slide7.PNG'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/speakers/plenary/Slide8.PNG',
                    name: 'Marina Landau, MD',
                    position: 'Herzliya, Israel',
                    image: 'assets/img/speakers/plenary/Slide8.PNG'
                },
                {
                    id: 9,
                    srcimg: 'assets/img/speakers/plenary/Slide9.PNG',
                    name: 'Leong Kin Fon, MD',
                    position: 'Kuala Lumpur, Malaysia',
                    image: 'assets/img/speakers/plenary/Slide9.PNG'
                },
                {
                    id: 10,
                    srcimg: 'assets/img/speakers/plenary/Slide10.PNG',
                    name: 'Henry W. Lim, MD',
                    position: 'Detroit, Michigan, USA',
                    image: 'assets/img/speakers/plenary/Slide10.PNG'
                },
                {
                    id: 11,
                    srcimg: 'assets/img/speakers/plenary/Slide11.PNG',
                    name: 'Maria Tricia Malahito',
                    position: '',
                    image: 'assets/img/speakers/plenary/Slide11.PNG'
                },
                {
                    id: 12,
                    srcimg: 'assets/img/speakers/plenary/Slide12.PNG',
                    name: 'Angeline Yong, MBBS, MPH',
                    position: 'Singapore',
                    image: 'assets/img/speakers/plenary/Slide12.PNG'
                },
                {
                    id: 13,
                    srcimg: 'assets/img/speakers/plenary/Slide13.PNG',
                    name: 'Angeline Yong, MBBS, MPH',
                    position: 'Singapore',
                    image: 'assets/img/speakers/plenary/Slide13.PNG'
                },
                // {
                //     id: 14,
                //     srcimg: 'assets/img/speakers/plenary/Slide14.PNG',
                //     name: 'Angeline Yong, MBBS, MPH',
                //     position: 'Singapore',
                //     image: 'assets/img/speakers/plenary/Slide14.PNG'
                // },
                {
                    id: 15,
                    srcimg: 'assets/img/speakers/plenary/Slide15.PNG',
                    name: 'Angeline Yong, MBBS, MPH',
                    position: 'Singapore',
                    image: 'assets/img/speakers/plenary/Slide15.PNG'
                },
                {
                    id: 16,
                    srcimg: 'assets/img/speakers/plenary/Slide16.PNG',
                    name: 'Angeline Yong, MBBS, MPH',
                    position: 'Singapore', 
                    image: 'assets/img/speakers/plenary/Slide16.PNG'
                },
                {
                    id: 17,
                    srcimg: 'assets/img/speakers/plenary/Slide17.PNG',
                    name: 'Angeline Yong, MBBS, MPH',
                    position: 'Singapore',
                    image: 'assets/img/speakers/plenary/Slide17.PNG'
                }
            ],
            fgd: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/fgd/Slide1.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/fgd/Slide2.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/fgd/Slide3.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/fgd/Slide4.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide4.PNG'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/speakers/fgd/Slide5.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide5.PNG'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/speakers/fgd/Slide6.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide6.PNG'
                },
                {
                    id: 7,
                    srcimg: 'assets/img/speakers/fgd/Slide7.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide7.PNG'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/speakers/fgd/Slide8.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide8.PNG'
                },
                {
                    id: 9,
                    srcimg: 'assets/img/speakers/fgd/Slide9.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide9.PNG'
                },
                {
                    id: 10,
                    srcimg: 'assets/img/speakers/fgd/Slide10.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide10.PNG'
                },
                {
                    id: 11,
                    srcimg: 'assets/img/speakers/fgd/Slide11.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide11.PNG'
                },
                {
                    id: 12,
                    srcimg: 'assets/img/speakers/fgd/Slide12.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide12.PNG'
                },
                {
                    id: 13,
                    srcimg: 'assets/img/speakers/fgd/Slide13.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide13.PNG'
                },
                {
                    id: 14,
                    srcimg: 'assets/img/speakers/fgd/Slide14.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide14.PNG'
                },
                {
                    id: 15,
                    srcimg: 'assets/img/speakers/fgd/Slide15.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide15.PNG'
                },
                {
                    id: 16,
                    srcimg: 'assets/img/speakers/fgd/Slide16.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide16.PNG'
                },
                {
                    id: 17,
                    srcimg: 'assets/img/speakers/fgd/Slide17.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide17.PNG'
                },
                {
                    id: 18,
                    srcimg: 'assets/img/speakers/fgd/Slide18.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide18.PNG'
                },
                {
                    id: 19,
                    srcimg: 'assets/img/speakers/fgd/Slide19.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide19.PNG'
                },
                {
                    id: 20,
                    srcimg: 'assets/img/speakers/fgd/Slide20.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide20.PNG'
                },
                {
                    id: 21,
                    srcimg: 'assets/img/speakers/fgd/Slide21.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/fgd/Slide21.PNG'
                }
            ],
            symposias: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/symposium/Slide1.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/symposium/Slide2.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide2.PNG'
                },
                {
                    id: 3,
                   srcimg: 'assets/img/speakers/symposium/Slide3.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/symposium/Slide4.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide4.PNG'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/speakers/symposium/Slide5.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide5.PNG'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/speakers/symposium/Slide6.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide6.PNG'
                },
                {
                    id: 7,
                    srcimg: 'assets/img/speakers/symposium/Slide7.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide7.PNG'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/speakers/symposium/Slide8.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide8.PNG'
                },
                {
                    id: 9,
                    srcimg: 'assets/img/speakers/symposium/Slide9.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide9.PNG'
                },
                {
                    id: 10,
                    srcimg: 'assets/img/speakers/symposium/Slide10.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide10.PNG'
                },
                {
                    id: 11,
                    srcimg: 'assets/img/speakers/symposium/Slide11.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide11.PNG'
                },
                {
                    id: 12,
                    srcimg: 'assets/img/speakers/symposium/Slide12.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide12.PNG'
                },
                {
                    id: 13,
                    srcimg: 'assets/img/speakers/symposium/Slide13.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide13.PNG'
                },
                {
                    id: 14,
                    srcimg: 'assets/img/speakers/symposium/Slide14.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide14.PNG'
                },
                {
                    id: 15,
                    srcimg: 'assets/img/speakers/symposium/Slide15.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide15.PNG'
                },
                {
                    id: 16,
                    srcimg: 'assets/img/speakers/symposium/Slide16.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide16.PNG'
                },
                {
                    id: 17,
                    srcimg: 'assets/img/speakers/symposium/Slide17.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide17.PNG'
                },
                {
                    id: 18,
                    srcimg: 'assets/img/speakers/symposium/Slide18.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide18.PNG'
                },
                {
                    id: 19,
                    srcimg: 'assets/img/speakers/symposium/Slide19.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide19.PNG'
                },
                {
                    id: 20,
                    srcimg: 'assets/img/speakers/symposium/Slide20.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide20.PNG'
                },
                {
                    id: 21,
                    srcimg: 'assets/img/speakers/symposium/Slide21.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide21.PNG'
                },
                {
                    id: 22,
                    srcimg: 'assets/img/speakers/symposium/Slide22.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide22.PNG'
                },
                {
                    id: 23,
                    srcimg: 'assets/img/speakers/symposium/Slide23.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide23.PNG'
                },
                {
                    id: 24,
                    srcimg: 'assets/img/speakers/symposium/Slide24.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide24.PNG'
                },{
                    id: 25,
                    srcimg: 'assets/img/speakers/symposium/Slide25.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide25.PNG'
                },{
                    id: 26,
                    srcimg: 'assets/img/speakers/symposium/Slide26.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide26.PNG'
                },{
                    id: 27,
                    srcimg: 'assets/img/speakers/symposium/Slide27.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide27.PNG'
                },{
                    id: 28,
                    srcimg: 'assets/img/speakers/symposium/Slide28.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide28.PNG'
                },{
                    id: 29,
                    srcimg: 'assets/img/speakers/symposium/Slide29.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide29.PNG'
                },{
                    id: 30,
                    srcimg: 'assets/img/speakers/symposium/Slide30.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide30.PNG'
                },{
                    id: 31,
                    srcimg: 'assets/img/speakers/symposium/Slide31.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide31.PNG'
                },{
                    id: 32,
                    srcimg: 'assets/img/speakers/symposium/Slide32.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide32.PNG'
                },{
                    id: 33,
                    srcimg: 'assets/img/speakers/symposium/Slide33.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide33.PNG'
                },{
                    id: 34,
                    srcimg: 'assets/img/speakers/symposium/Slide34.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide34.PNG'
                },{
                    id: 35,
                    srcimg: 'assets/img/speakers/symposium/Slide35.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide35.PNG'
                },{
                    id: 36,
                    srcimg: 'assets/img/speakers/symposium/Slide36.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide36.PNG'
                },{
                    id: 37,
                    srcimg: 'assets/img/speakers/symposium/Slide37.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide37.PNG'
                },{
                    id: 38,
                    srcimg: 'assets/img/speakers/symposium/Slide38.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide38.PNG'
                },{
                    id: 39,
                    srcimg: 'assets/img/speakers/symposium/Slide39.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide39.PNG'
                },{
                    id: 40,
                    srcimg: 'assets/img/speakers/symposium/Slide40.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide40.PNG'
                },{
                    id: 41,
                    srcimg: 'assets/img/speakers/symposium/Slide41.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide41.PNG'
                },{
                    id: 42,
                    srcimg: 'assets/img/speakers/symposium/Slide42.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide42.PNG'
                },{
                    id: 43,
                    srcimg: 'assets/img/speakers/symposium/Slide43.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide43.PNG'
                },{
                    id: 44,
                    srcimg: 'assets/img/speakers/symposium/Slide44.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide44.PNG'
                },{
                    id: 45,
                    srcimg: 'assets/img/speakers/symposium/Slide45.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide45.PNG'
                },{
                    id: 46,
                    srcimg: 'assets/img/speakers/symposium/Slide46.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide46.PNG'
                },{
                    id: 47,
                    srcimg: 'assets/img/speakers/symposium/Slide47.PNG',
                    name: '',
                    position: '',
                    image: 'assets/img/speakers/symposium/Slide47.PNG'
                },
            ],
            precon: [
                {
                    id: 1,
                    srcimg: 'assets/img/speakers/precon/Slide1.PNG',
                    name: 'Teresita S. Ferrariz, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide1.PNG'
                },
                {
                    id: 2,
                    srcimg: 'assets/img/speakers/precon/Slide2.PNG',
                    name: 'Arunee H. Siripunvarapon, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide2.PNG'
                },
                {
                    id: 3,
                    srcimg: 'assets/img/speakers/precon/Slide3.PNG',
                    name: 'Krisinda Clare Dim-Jamora, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide3.PNG'
                },
                {
                    id: 4,
                    srcimg: 'assets/img/speakers/precon/Slide4.PNG',
                    name: 'Charlene Ang-Tiu, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide4.PNG'
                },
                {
                    id: 5,
                    srcimg: 'assets/img/speakers/precon/Slide5.PNG',
                    name: 'Julie Anne Patricia Songco, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide5.PNG'
                },
                {
                    id: 6,
                    srcimg: 'assets/img/speakers/precon/Slide6.PNG',
                    name: 'Sandy Chris L. Tan, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide6.PNG'
                    
                },
                {
                    id: 7,
                    srcimg: 'assets/img/speakers/precon/Slide7.PNG',
                    name: 'Stephen Lacson, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide7.PNG'
                },
                {
                    id: 8,
                    srcimg: 'assets/img/speakers/precon/Slide8.PNG',
                    name: 'Emy Onishi-Limchoa, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide8.PNG'
                },
                {
                    id: 9,
                    srcimg: 'assets/img/speakers/precon/Slide9.PNG',
                    name: 'Irene Gaile Robredo-Vitas, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide9.PNG'
                },
                {
                    id: 10,
                    srcimg: 'assets/img/speakers/precon/Slide10.PNG',
                    name: 'Beverly Ong-Amoranto, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide10.PNG'
                },
                {
                    id: 11,
                    srcimg: 'assets/img/speakers/precon/Slide11.PNG',
                    name: 'Arnel Quiambao, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide11.PNG'
                },
                {
                    id: 12,
                    srcimg: 'assets/img/speakers/precon/Slide12.PNG',
                    name: 'Zharlah Gulmatico-Flores, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide12.PNG'
                },
                {
                    id: 13,
                    srcimg: 'assets/img/speakers/precon/Slide13.PNG',
                    name: 'Marina Landau, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide13.PNG'
                },
                {
                    id: 14,
                    srcimg: 'assets/img/speakers/precon/Slide14.PNG',
                    name: 'Cyril Mitchel Agan, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide14.PNG'
                },
                {
                    id: 15,
                    srcimg: 'assets/img/speakers/precon/Slide15.PNG',
                    name: 'Alvin Jorge, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide15.PNG'
                },
                {
                    id: 16,
                    srcimg: 'assets/img/speakers/precon/Slide16.PNG',
                    name: 'Jaime Arzadon, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide16.PNG'
                },
                {
                    id: 17,
                    srcimg: 'assets/img/speakers/precon/Slide17.PNG',
                    name: 'Davin Lim, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide17.PNG'
                },
                {
                    id: 18,
                    srcimg: 'assets/img/speakers/precon/Slide18.PNG',
                    name: 'Rob Knight, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide18.PNG'
                },
                {
                    id: 19,
                    srcimg: 'assets/img/speakers/precon/Slide19.PNG',
                    name: 'Ma. Christina L. Tantiangco-Javier, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide19.PNG'
                },
                {
                    id: 20,
                    srcimg: 'assets/img/speakers/precon/Slide20.PNG',
                    name: 'Loverna A. Suratos, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide20.PNG'
                },
                {
                    id: 21,
                    srcimg: 'assets/img/speakers/precon/Slide21.PNG',
                    name: 'Loverna A. Suratos, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide21.PNG'
                },{
                    id: 22,
                    srcimg: 'assets/img/speakers/precon/Slide22.PNG',
                    name: 'Loverna A. Suratos, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide22.PNG'
                },{
                    id: 23,
                    srcimg: 'assets/img/speakers/precon/Slide23.PNG',
                    name: 'Loverna A. Suratos, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide23.PNG'
                },{
                    id: 24,
                    srcimg: 'assets/img/speakers/precon/Slide24.PNG',
                    name: 'Loverna A. Suratos, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide24.PNG'
                },{
                    id: 25,
                    srcimg: 'assets/img/speakers/precon/Slide25.PNG',
                    name: 'Deo Wong, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide25.PNG'
                },
                {
                    id: 26,
                    srcimg: 'assets/img/speakers/precon/Slide26.PNG',
                    name: 'Deo Wong, MD',
                    position: '',
                    image: 'assets/img/speakers/precon/Slide26.PNG'
                },

            ],
            speakers: [
                {
                    id: 1,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 2,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 3,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
                {
                    id: 4,
                    srcimg: 'https://res.cloudinary.com/development-mar/image/upload/v1683271606/PDS/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman_hcxata.webp',
                    name: 'Test User',
                    position: 'Project Manager',
                    image: 'https://res.cloudinary.com/development-mar/image/upload/c_scale,h_638/v1683278297/PDS/Background_Template_zf2lci.jpg'
                },
            ],
            showModal: false,
            imageUrl: ""
        }
    }

    render() {
        return (
            <div className="row-team">
                <Modal
                    open={this.state.showModal}
                    center
                    onClose={() => this.setState({
                        showModal: false
                    })}
                >


                    <div style={{ height: '100%', margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ maxWidth: 'none', width: '100%' }} src={this.state.imageUrl} alt="imagealt" />
                    </div>
                </Modal>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Plenary Session Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        this.state.lead.map(data => (
                                            <div className="d-flex col-xs-2 col-md-6 themesflat-team style-1 align-center justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                            <li className="facebook" style={{
                                                                    color: "white", width: "100%",
                                                                    height: "100%"
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            {/* <h6 className="name">{data.name}</h6> */}
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div>

                        {/* <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Case Discussion Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row">
                                    {
                                        this.state.speakers.map(data => (
                                            <div className="col-md-3 themesflat-team style-1 align-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div >
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                                <li className="facebook" style={{ color: "white" }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            <h6 className="name">{data.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div> */}

                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Focus Group Discussion Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                {
                                        this.state.fgd.map(data => (
                                            <div className="d-flex col-xs-4 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                            <li className="facebook" style={{
                                                                    color: "white", width: "100%",
                                                                    height: "100%"
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            {/* <h6 className="name">{data.name}</h6> */}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div>

                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Symposia Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                {
                                        this.state.symposias.map(data => (
                                            <div className="d-flex col-xs-4 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                            <li className="facebook" style={{
                                                                    color: "white", width: "100%",
                                                                    height: "100%"
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            {/* <h6 className="name">{data.name}</h6> */}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div>

                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Precon Speakers</h2>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                            <div className=" data-effect  clearfix"
                                data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                                <div className="row d-flex justify-content-center align-items-center">
                                    {
                                        this.state.precon.map(data => (
                                            <div className="d-flex col-xs-2 col-md-6 themesflat-team style-1 align-center  justify-content-center clearfix" key={data.id}>
                                                <div className="team-item">
                                                    <div>
                                                        <div className="thumb data-effect-item">
                                                            <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                                                            <ul className="socials clearfix">
                                                            <li className="facebook" style={{
                                                                    color: "white", width: "100%",
                                                                    height: "100%"
                                                                }} onClick={() => {
                                                                    this.setState({
                                                                        showModal: true,
                                                                        imageUrl: data.image
                                                                    })
                                                                }}>More Info</li>
                                                            </ul>
                                                            <div className="overlay-effect bg-color-4" onClick={() => {
                                                                this.setState({
                                                                    showModal: true,
                                                                    imageUrl: data.image
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="text-wrap">
                                                            {/* <h6 className="name">{data.name}</h6>                                                            */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                        </div>

                        
                    </div>
                </div>
            </div>
        );
    }
}

                        // <div className="col-md-12">
                        //     <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                        //     <div className="themesflat-headings style-1 text-center clearfix">
                        //         <h2 className="heading mt-5">Convention Speakers</h2>
                        //     </div>
                        //     <div className="themesflat-content-box" data-margin="auto" data-mobilemargin="auto" >
                        //         <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">

                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                     Dr. Belen Dofitas
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >Skin Neglected Tropical Diseases (NTDs) in the Philippines</p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Tricia Malahito
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >GUT-SKIN AXIS: Impact of the Gut Microbiome on Skin Health</p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Chandra Co
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                             Toxic Beauty: Are We Polluting the Environment with Our Personal Skin Care?
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Malaya Santos
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Mental Health and Psychosocial Impact of Leprosy and STI
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Belen Dofitas
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Management of Challenging Lepra Reactions
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Luisa Venida 
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         PGL-1 as a Leprosy Biomarker
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Dessi Roman 
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Overview of the Current STI Situation in the Philippines
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Gilbert Yang
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Always Think of STI (Great Mimicker of many Dermatologic Conditions)
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Rodelio Lim
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         New Trends and Updates on the Diagnostic Approach in STI
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Jamaine Cruz-Regalado
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Drug Reactions (SJS-TEN)
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Cybill Uy
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Hidradenitis Suppurativa/Pyoderma Gangrenosum
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Rogelio Balagat
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Vasculitis
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Angeli Torres-Paulino
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Optimizing Phototherapy for Vitiligo
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Giselle Tioleco-Ver
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         When It’s Complicated: Use of Light Therapy in Psoriasis 
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Josef Symon Concha
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Photosensitivity in Autoimmune Skin Diseases
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Val Cua
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Updates on the Management of Androgenetic Alopecia 
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Ana Santos
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Updates on the Management of Alopecia Areata
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Suzanne Datuin
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Exosomes for Androgenetic Alopecia
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Carol Chua-Aguilera
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Treatment Options for Nail Psoriasis
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Mae Ramirez Quizon
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Update on Treatment of Onychomycosis
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Wilsie Walinsundin
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Cosmeceuticals in Kids: Necessity vs. Vanity
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Miya Dayrit
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Hair Aware! Alopecia and Hair Disorders Amongst Children
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Gisella Adasa
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         You Nailed It! Common Pediatric Dermatoses with Nail Changes
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //             <div className="accordion-item">
                        //                 <h3 className="accordion-heading" style={{ color: 'black' }}><span className="inner">
                        //                 Dr. Nina Gabaton
                        //                 </span></h3>
                        //                 <div className="accordion-content">
                        //                     <div>
                        //                         <p style={{ lineHeight: 1.5, color: 'black' }} >
                        //                         Sweat PANTS: Prevention, Assessment, New Options, Tests, Solutions
                        //                         </p>
                        //                     </div>
                        //                 </div>
                        //             </div>

                        //         </div>
                        //     </div>                            
                            
                        //     <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={35} data-smobile={35} />
                        //     {/* <div className=" data-effect  clearfix"
                        //         data-gap={30} data-column={3} data-column2={2} data-column3={1} data-auto="true">
                        //         <div className="row">
                        //             {
                        //                 this.state.speakers.map(data => (
                        //                     <div className="col-md-3 themesflat-team style-1 align-center clearfix" key={data.id}>
                        //                         <div className="team-item">
                        //                             <div >
                        //                                 <div className="thumb data-effect-item">
                        //                                     <img style={{ maxWidth: 'none', width: '100%' }} src={data.srcimg} alt="imagealt" />
                        //                                     <ul className="socials clearfix">
                        //                                         <li className="facebook" style={{ color: "white" }} onClick={() => {
                        //                                             this.setState({
                        //                                                 showModal: true,
                        //                                                 imageUrl: data.image
                        //                                             })
                        //                                         }}>More Info</li>
                        //                                     </ul>
                        //                                     <div className="overlay-effect bg-color-4" onClick={() => {
                        //                                         this.setState({
                        //                                             showModal: true,
                        //                                             imageUrl: data.image
                        //                                         })
                        //                                     }} />
                        //                                 </div>
                        //                                 <div className="text-wrap">
                        //                                     <h6 className="name">{data.name}</h6>
                        //                                 </div>
                        //                             </div>
                        //                         </div>
                        //                     </div>
                        //                 ))
                        //             }
                        //         </div>
                        //     </div>
                        //     <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} /> */}
                            


                        // </div>

export default LeaderTeam;