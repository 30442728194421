/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import menus from '../menu';
import menuLogged from '../menuLogged';

class Header extends Component {
  render() {
    const accessToken = localStorage.getItem('accessToken');
    let headerItems;
    if (!accessToken) {
      headerItems = menus;
    } else {
      headerItems = menuLogged;
    }
    return (
      <header id="site-header">
        <div>
          <div className="wrap-inner">
            <div id="site-logo" className="clearfix d-block d-sm-block d-md-none">
              <div id="site-log-inner" >
                <Link to="/" onClick={() => { window.location.href = '/'; }} rel="home" className="main-logo">
                  <img style={{ transform: 'translateY(-31px)', width: 65 }} src={this.props.data.logoweb} alt="Autora" height={39} data-retina="assets/img/logo_mobile.png" data-width={186} data-height={39} />
                </Link>
              </div>

            </div>
            <div className="mobile-button">
              <span />
            </div>
            <nav id="main-nav" className="main-nav">
              <ul id="menu-primary-menu" className="menu main-nav-menu" style={{ justifyContent: 'center' }}>
                {
                  headerItems.map((data) => (
                    <li className={data.name === this.props.data.names ? 'menu-item menu-item-has-children current-menu-item' : 'menu-item menu-item-has-children'} key={data.id}>
                      {data.link
                        ? (
                          <Link onClick={() => {
                            if (data.link) {
                              if (!data.namesub) {
                                window.location = data.link;
                                if (data.link === 'logout') {
                                  localStorage.removeItem('accessToken');
                                  window.location = '/';
                                }
                              }
                            } else {

                            }
                          }}
                          >
                            {data.name}

                          </Link>
                        )

                        : <a>{data.name}</a>}
                      {data.namesub
                        && (
                          <ul className="sub-menu">
                            {
                              data.namesub.map((submenu) => (
                                <li className="menu-item" key={submenu.id}>
                                  <Link
                                    to={submenu.links}
                                    onClick={() => {
                                      window.location = submenu.links;
                                    }}
                                  >
                                    {submenu.sub}
                                  </Link>

                                </li>
                              ))
                            }
                          </ul>
                        )}
                    </li>
                  ))
                }
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
