import React, { Component } from 'react';
import { Slider } from './index'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

class MainContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Completing projects on time & Following budget guidelines'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Elevated quality of workmanship'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Meeting diverse supplier requirements'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Implementing appropriate safety precautions and procedures'
                },
            ]
        }
    }

    render() {
        return (
            <div className="row-about">
                <div className="container-fluid">
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />

                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-7 half-background style-4" >
                            <Slider data={"slider-small"} />
                        </div>
                        <div className="col-md-5">
                            {/* <div className="themesflat-spacer clearfix" data-desktop={64} data-mobile={60} data-smobile={60} /> */}
                            <div className="themesflat-content-box clearfix" data-margin="0 5% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">Announcements</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />

                                    <h2 className="margin-top-30 heading" >Register Now</h2>
                                    <p > You may register until September 30, 2022 (Click to know more)</p>
                                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />

                                    {/* <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> */}
                                    <h2 className="margin-top-30 heading" >Abstract Submission Is Closed</h2>
                                    {/* <p > Only registered attendees can submit an abstract (Click to know more)</p>
                                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} /> */}

                                    {/* <div className="sep has-width w80 accent-bg margin-top-11 clearfix" /> */}
                                    {/* <h2 className="margin-top-30 heading" >ASD Presentation Awards</h2>
                                    <p > Apply for ASD Presentation Awards (Click to know more)</p> */}
                                </div>
                                {/* <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} /> */}
                                {/* <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} /> */}
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={75} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />

                </div>
            </div>
        );
    }
}

export default MainContent;