/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import env from "react-dotenv";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'

export default function Template(props) {
  const [showNext, setShowNext] = useState(false);
  const [selected, setSelected] = useState(null);
  const [nextOption, setNextOption] = useState(null);
  const [afterOption, setAfterOption] = useState(null);

  const [pds, setPds] = useState("")
  const [email, setEmail] = useState("")

  const [message, setMessage] = useState("")
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState("")
  const [error, setError] = useState(false);

  const resetState = () => {
    setShowNext(false);
    setSelected(null);
    setNextOption(null);
  };

  useEffect(() => {
    if (props) {
      resetState();
    }
  }, [props]);

  const handleNextOption = () => {
    // if (props.selectedOptions.includes('speaker') && selected === 'local') {
    //   // return props.handleNext(selected, 'option_4_5');
    //   return props.handleNext(selected, null);
    // }

    // if (props.selectedOptions.includes('speaker') && selected === 'international') {
    //   return props.handleNext(selected, null);
    // }


    return props.handleNext(selected, nextOption);
  }

  const handleBackOption = () => {
    props.handleBack();
  }

  const checkPDS = (e) => {
    if (e !== "" && e.length === 6) {
      axios.get(`${env.API_URL}/metadata/pds/${e}`).then((res) => {
        try {
          setOpen(true)
          setUserData(res.data)
          setMessage(res.data.firstName + ' ' + res.data.lastName)
        } catch (e) {
          setMessage(e)
        }
      }).catch((e) => {
        console.log(e.response.data.error)
        NotificationManager.error(e?.response?.data?.error, '', 5000);
        // setMessage("Sorry, your PDS ID number is not found in our PDS membership list. You might have typed the wrong PDS ID number or you did not qualify as a member with good standing. ")
      })
    }
  }
  const checkSpeaker = (e) => {
    let body = {
      "email": e.toLowerCase(),
    };
    axios.post(`${env.API_URL}/metadata/speaker`, body).then((res) => {
      try {
        // console.log(res.data)
        setOpen(true)
        setUserData(res.data)
        setEmail(e)
        setMessage(res.data.firstName + ' ' + res.data.lastName)
      } catch (e) {
        setMessage(e)
      }
    }).catch((e) => {
      NotificationManager.error("Please type in your correct email", '', 5000);
      setMessage("You are not eligible for this category.")
    })
  }
  return (
    <>
      {!props.loading && (
        <div className="animate">
          <NotificationContainer />

          {props.currentOption === 'option_4_5' && (

            <Modal
              open={open}
              onClose={() => {
                NotificationManager.error("Please type in your correct PDS ID#", '', 5000);
                setOpen(false)
              }}
              center
            >
              <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                <h2 style={{ textAlign: 'center', lineHeight: 1.5 }}>Is this you?</h2>
                <p style={{ margin: 20, marginBottom: 5, fontSize: 'x-large' }} >{message}</p>
                <p style={{ margin: 20, marginTop: 5, fontSize: 'x-large' }} >{pds}</p>
                <div className="actions" style={{ display: "flex", marginTop: 10, bottom: 0, right: 0 }}>
                  <ul>
                    <li style={{ width: "65%" }}>
                      <button onClick={(e) => {
                        setOpen(false)
                        props.setFirstName(userData.firstName)
                        props.setLastName(userData.lastName)
                        props.setPdsNumber(pds)
                        // console.log('Log', selected, props.selectedOptions, !props.selectedOptions.includes('speaker') && 'option_4')

                        if (afterOption) {
                          // console.log(selected, afterOption)
                          return props.handleNext(selected, null);
                        }
                        if (props.selectedOptions.includes('board')) {
                          // CHECK IF ACCOUNT HAS BOARD
                          if (!userData.isBoard) {
                            return alert('You are not eligible for this category.')
                          } else {
                            props.handleNext('pds')
                          }
                        } else {
                          props.handleNext('pds')
                        }
                        // props.handleNext('pds')

                      }}
                        className="button-next-active" >Confirm</button>
                    </li>
                  </ul>
                </div>
              </div>
            </Modal>
          )}

          {props.currentOption === 'validate_email' && (
            <Modal
              open={open}
              onClose={() => {
                NotificationManager.error("Please type in your correct email", '', 5000);
                setOpen(false)
              }}
              center
            >
              <div style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                <h2 style={{ textAlign: 'center', lineHeight: 1.5 }}>Is this you?</h2>
                <p style={{ margin: 20, marginBottom: 5, fontSize: 'x-large' }} >{userData.firstName + " " + userData.lastName}</p>
                <p style={{ margin: 20, marginTop: 5, fontSize: 'x-large' }} >{email}</p>
                <div className="actions" style={{ display: "flex", marginTop: 10, bottom: 0, right: 0 }}>
                  <ul>
                    <li style={{ width: "65%" }}>
                      <button onClick={(e) => {
                        setOpen(false)
                        props.setFirstName(userData.firstName)
                        props.setLastName(userData.lastName)
                        props.setEmail(email)

                        if (afterOption) {
                          return props.handleNext(selected, null);
                        }
                        if (props.selectedOptions.includes('board')) {
                          // CHECK IF ACCOUNT HAS BOARD
                          if (!userData.isBoard) {
                            return alert('You are not eligible for this category.')
                          } else {
                            props.handleNext('pds')
                          }
                        } else {
                          props.handleNext('pds')
                        }
                        // props.handleNext('pds')

                      }}
                        className="button-next-active" >Confirm</button>
                    </li>
                  </ul>
                </div>
              </div>
            </Modal>
          )}

          <div className="wizard-forms">
            <div className="inner pb-50 clearfix">
              <div className="form-content pera-content" style={{ width: '100%' }}>
                <div className="step-inner-content">
                  <span className="step-no">Step 1</span>
                  <div className="step-progress float-right">
                    <span>1 of 5 completed</span>
                    <div className="step-progress-bar">
                      <div className="progress">
                        <div className="progress-bar" style={{ width: '10%' }} />
                      </div>
                    </div>
                  </div>

                  <h2>{props.details.title}</h2>
                  <div className="step-box">
                    <div className="row">
                      {props.currentOption === 'validate_email' && (
                        <div className="col-md-12">
                          <input
                            type="text"
                            autoComplete="off"
                            maxLength="40"
                            value={email}
                            className="form-control required"
                            minLength="2"
                            placeholder="Enter your email *"
                            required

                            onChange={(e) => {
                              function validEmail(e) {
                                var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
                                return String(e).search(filter) !== -1;
                              }

                              if (validEmail(e.target.value)) {
                                // checkEmail(e.target.value)
                                setShowNext(true)
                                setError(false)
                                setMessage("")
                              } else {
                                setMessage('Invalid Email')
                                setError(true)
                              }

                              setEmail(e.target.value);
                            }}

                            style={{ textTransform: 'uppercase', fontSize: '20px' }}
                          />
                          <p style={{ color: error && 'red', marginBottom: 10 }}>{message}</p>
                        </div>
                      )}

                      {props.currentOption === 'option_4_5' && (
                        <div className="col-md-12">
                          <input
                            type="text"
                            autoComplete="off"
                            maxLength="6"
                            value={pds}
                            className="form-control required"
                            minLength="2"
                            placeholder="PDS Number *"
                            required
                            onChange={(e) => {
                              setPds(e.target.value.toUpperCase())
                              e.target.value.length === 6 && setShowNext(true)
                            }}
                            style={{ textTransform: 'uppercase', fontSize: '20px' }}
                          />
                        </div>
                      )}

                      {props.options.length > 0 && props.options.map((option, index) => (
                        <div style={{ marginBottom: 20, display: 'grid' }} className={`col-md-${props.options.length > 3 ? 6 : 12 / props.options.length}`} key={index} data-animation="slideHorz">
                          <label className={`${selected === option.value ? 'active' : ''} step-box-content bg-white text-center relative-position`}>
                            <span className="step-box-icon">
                              <img src={option.icon} style={{ height: '200px' }} alt="" />
                            </span>
                            <span className="step-box-text">{option.label}</span>
                            <span className="service-check-option">
                              <span>
                                <input
                                  id={index}
                                  value={option.value}
                                  onChange={() => {
                                    setShowNext(true);
                                    setSelected(option.value);
                                    setNextOption(option.nextOption ?? null);
                                    setAfterOption(option.afterOption ?? null)
                                  }}
                                  type="radio"
                                  name={props.currentOption ?? null}
                                />
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                    {props.details.lads && (
                      <p style={{ paddingTop: 20 }}>
                        <font style={{ fontWeight: 'bold' }}>League of ASEAN Dermatological Societies (LADS)</font><br></br>

                        1. Indonesian Society for Dermatology and Venereology <br></br>
                        2. Dermatological Society of Malaysia<br></br>
                        3. Dermatological Society of Singapore<br></br>
                        4. Dermatological Society of Thailand<br></br>
                        5. Vietnam Society of Dermatology and Venereology<br></br>
                        6. Cambodia Dermatological Society<br></br>
                      </p>
                    )}
                    <p style={{ paddingTop: 20 }}>
                      {props.details.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="actions" style={{ display: 'flex' }}>
            <ul>
              {!props.showBack &&
                <li>
                  <button
                    onClick={() => {
                      handleBackOption()
                      // props.currentOption === 'option_4_5'
                      //   ? checkPDS(pds)
                      //   : handleNextOption();
                    }}
                    className={`button-next-active`}
                  >
                    Back
                  </button>
                </li>
              }
              <li>
                <button
                  onClick={() => {
                    if (props.currentOption === 'validate_email') {
                      return checkSpeaker(email)
                    }
                    props.currentOption === 'option_4_5'
                      ? checkPDS(pds)
                      : handleNextOption();
                  }}
                  disabled={!showNext}
                  className={`${showNext ? 'button-next-active' : ''} button-next`}
                >
                  {props.currentOption === 'option_4_5' ? 'Validate' : 'Next'}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
