/* eslint-disable react/jsx-pascal-case */
/* eslint-disable import/order */
import React, { useState } from 'react';
import Step1 from './step/step-1';
import Step2_5 from './step/step-2-session';
import Step3 from './step/step-3';
import Step4 from './step/step-4';
import Step5 from './step/step-5';
import '../assets/scss/style.scss';
// import "../assets/js/main.js"
import { Dots } from 'react-activity';
import 'react-activity/dist/library.css';

export default function Fellowship(props) {
  const [createdUser, setCreatedUser] = useState(null);
  const [currentForm, setCurrentForm] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSpeaker, setIsSpeaker] = useState('');
  const [isLocal, setIsLocal] = useState('');
  const [asd, setAsd] = useState('');
  const [pds, setPds] = useState('');
  const [pdsNumber, setPdsNumber] = useState('');
  const [speakerSession, setSpeakerSession] = useState('');
  const [boardMember, setBoardMember] = useState(false);
  const [registeredWorkshops, setRegisteredWorkshops] = useState([]);

  const [asdEmail, setAsdEmail] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [proSuffix, setProSuffix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [certName, setCertName] = useState('');
  const [email, setEmail] = useState('');
  const [prcNumber, setPrcNumber] = useState('');
  const [country, setCountry] = useState('');
  const [institution, setInstitution] = useState('');

  const [hasUpload, setHasUpload] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');
  const [sessions, setSessions] = useState([]);
  const [pmaNumber, setPmaNumber] = useState('');
  const [category, setCategory] = useState('');
  const [total, setTotal] = useState(0);
  const [workshops, setWorkshops] = useState([]);
  const [disabledList, setDisabledList] = useState([]);
  const [selected, setSelected] = useState([]);

  const [checkoutSelected, setCheckoutSelected] = useState([]);

  const [noSlotsAvailable, setNoSlotsAvailable] = useState(false);


  const [userData, setUserData] = useState(null);

  const [fellowship, setFellowship] = useState(null);
  const [rsvp, setRSVP] = useState(false);

  const handleNext = (step, value) => {
    console.log(step, value)
    switch (step) {
      case 'step_2':
        // GO TO SELECT WORKSHOP
        setCurrentForm(0.5);

        // setCurrentForm(1)
        setSelectedOptions(value.filter(Boolean));
        break;

      case 'step_2_5':
        return setCurrentForm(2);

      case 'step_3':
        // GO TO  PAYMENT
        return setCurrentForm(3);

      default:
        return setCurrentForm(currentForm + 1);
    }
  };


  const handleBack = (step) => {
    switch (step) {
      case 'step_2_5':
        return setCurrentForm(0.5);

      case 'step_2':
        return setCurrentForm(1);

      default:
        return setCurrentForm(currentForm - 1);
    }
  };
  console.log(sessions)

  return (

    <div className="wrapper">
      {isLoading
        && (
          <div style={{
            backgroundColor: '#000000a8', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', flexDirection: 'column', zIndex: 20000, position: 'absolute'
          }}
          >
            <h6 style={{ color: 'white', margin: 20 }}>LOADING...</h6>
            <Dots color="white" size={50} />
          </div>
        )}

      <div className="steps-area steps-area-fixed">
        <div className="image-holder">
          <img src="https://res.cloudinary.com/duuldyiu5/image/upload/v1710139698/GV%20Registration/vertical-01.jpg" alt="" style={{
            maxWidth: '100%',
            height: '100vh'
          }} />
        </div>
        <div className="steps clearfix">
          <ul className="tablist multisteps-form__progress">
            <li className={currentForm > -1 ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'}>
              <span>1</span>
            </li>
            <li className={currentForm > 0 || currentForm > 0.5 ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'}>
              <span>2</span>
            </li>
            <li className={currentForm > 1 ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'}>
              <span>3</span>
            </li>
            <li className={currentForm > 2 ? 'multisteps-form__progress-btn js-active last' : 'multisteps-form__progress-btn last'}>
              <span>4</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="multisteps-form__form">
        <div className="form-area position-relative">
          <Step1
            pds={pds}
            isSpeaker={isSpeaker}
            pdsNumber={pdsNumber}
            asdEmail={asdEmail}
            asd={asd}
            isLocal={isLocal}
            currentForm={currentForm}
            handleNext={handleNext}
            setIsSpeaker={setIsSpeaker}
            setIsLocal={setIsLocal}
            setAsd={setAsd}
            setPds={setPds}
            setPdsNumber={setPdsNumber}
            setAsdEmail={setAsdEmail}
            setHasUpload={setHasUpload}

            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}

            setSpeakerSession={setSpeakerSession}
            setBoardMember={setBoardMember}

            setRegisteredWorkshops={setRegisteredWorkshops}

            setWorkshops={setWorkshops}
            workshops={workshops}
            disabledList={disabledList}
            setDisabledList={setDisabledList}

            setSelected={setSelected}
            selected={selected}

            setNoSlotsAvailable={setNoSlotsAvailable}

            setUserData={setUserData}
          />
          <Step2_5
            currentForm={currentForm}
            setSessions={setSessions}
            setTotal={setTotal}

            handleNext={handleNext}
            handleBack={handleBack}
            pds={pds}

            speakerSession={speakerSession}
            boardMember={boardMember}

            registeredWorkshops={registeredWorkshops}

            setWorkshops={setWorkshops}
            workshops={workshops}
            disabledList={disabledList}
            setDisabledList={setDisabledList}

            setSelected={setSelected}
            selected={selected}

            checkoutSelected={checkoutSelected}
            setCheckoutSelected={setCheckoutSelected}

            noSlotsAvailable={noSlotsAvailable}

            firstName={firstName}
            lastName={lastName}
            email={email}
            fellowship={fellowship}
            setRSVP={setRSVP}

            userData={userData}
          />
          <Step3
            data={
              {
                rsvp, total, firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution, prcNumber, pdsNumber, pmaNumber, sessions, selectedOptions, fellowship
              }
            }
            currentForm={currentForm}
            isLocal={isLocal}
            handleNext={handleNext}
            handleBack={handleBack}

            setWorkshops={setWorkshops}
            workshops={workshops}
            checkoutSelected={checkoutSelected}
            pds={pds}

          // setSelected={setSelected}
          // selected={selected}
          />
          <Step4
            data={
              {
                userData, workshops, rsvp, total, firstName, lastName, middleName, proSuffix, suffix, certName, email, country, institution, isLocal, asd, pds, prcNumber, letterUrl, pdsNumber, selectedOptions, pmaNumber, sessions, fellowship, category
              }
            }
            isLocal={isLocal}
            setCreatedUser={setCreatedUser}
            setIsLoading={setIsLoading}
            asd={asd}
            pds={pds}

            currentForm={currentForm}
            setCategory={setCategory}
            setPaymentType={setPaymentType}
            handleNext={handleNext}
            handleBack={handleBack}
            checkoutSelected={checkoutSelected}

          />
          <Step5 checkoutSelected={checkoutSelected} currentForm={currentForm} handleNext={handleNext} handleBack={handleBack} data={{ certName, createdUser, category, firstName, lastName, email }} />
        </div>
      </div>
    </div>

  );
}
