import React from "react";
import {Card ,Container,Row,Col,Button,Table, Accordion, Tab, Tabs} from 'react-bootstrap';


export default function Scipro1(){    

    return (
        <Container>
            <Row>
            <Col md={1}></Col>
            <Col md={10}>
            <h3 class="my-5">Workshops</h3>
            <div className="themesflat-content-box" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0" >
                <div className="themesflat-accordions style-3 has-icon icon-left iconstyle-2 our-background clearfix">
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 1: Basic Injectables (8:00 am to 12:00 nn - Mactan)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Participants have the opportunity to learn how to use injectables to improve skin texture and tone, increase skin glow, hydration and overall skin quality as well as the basics in volume restoration.</p>
                            <p className="mt-3"><b><i>Chairs:</i></b></p>
                            <p>Dr. Stephen Lacson and Dr. Emy Christine Onishi-Limchoa</p>
                            <p className="mt-3"><b><i>Faculty:</i></b></p>
                            <p className="mb-3">Dr. Sandy Tan</p>
                            <Table style={{margin: "auto", textAlign:"center"}}> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th  style={{textAlign:"center", verticalAlign:"middle"}} colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>8:00 AM - 9:20 AM</td>
                                            <td colSpan={2}>Polynucleotide your way to younger skin <b>(Dr. Sandy Tan)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>9:20 AM - 10:40 AM</td>
                                            <td colSpan={2}>How to be you Noona? (Korean Glass Skin) <b>(Dr Emy Onishi-Limchoa and Dr. Stephen Lacson)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>10:40 AM - 12:00 NN</td>
                                            <td colSpan={2}>The 1cc Full Face Challenge <b>(Dr. Jaime Arzadon)</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>                                
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 2: MASTERCLASS: ADVANCED INJECTABLES (1:00 pm to 5:00 pm - Mactan)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Participants who have previous clinical experience on injectables will be able to further enhance their skills for targeted treatments such as neuromodulators for the shoulders and arms, facial reshaping using fillers and toxin, jaw augmentation and fillers for neocollagenesis. </p>
                            <p className="mt-3"><b><i>Chairs:</i></b></p>
                            <p className="mb-3">Dr. Beverly Ong-Amoranto and Dr. Gaile Robredo-Vitas</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th  style={{textAlign:"center", verticalAlign:"middle"}} colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>1:00 PM - 2:00 PM</td>
                                            <td colSpan={2}>How to Bring the Sexy Back - body toxin <b>(Dr Beverly Ong- Amoranto and Dr.  Irene Gaile Robredo-Vitas)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}>How to Reshape the Chubby Face <b>(Dr. Christine Javier)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>3:00 PM - 4:00 PM</td>
                                            <td colSpan={2}>Immortal Eyes - Unveiling the Sparkle behind those Bright Eyes <b>(Dr. Cyril Agan)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>4:00 PM - 5:00 PM</td>
                                            <td colSpan={2}>BIO-what?: Trends for Collagen Renewal <b>(Dr. Alvin Jorge)</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>                               
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 3: Lasers and Energy-Based Devices (8:00 am to 12:00 nn - Batanes)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Participants will be able to observe and experience the latest lasers and energy-based devices to improve skin texture and scars, skin tightening and lifting as well as how to combine technologies for a synergistic outcome. </p>
                            <p className="mt-3"><b><i>Chairs:</i></b></p>
                            <p className="mb-3">Dr. Claudine Javier and Dr. Ailynne Wyjangco</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th colSpan={2} style={{textAlign:"center", verticalAlign:"middle"}}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>8:00 AM - 9:00 AM</td>
                                            <td colSpan={2}>The Rocky Road: Paving the Way to Smoother Skin <b>(Dr. Deo Adiel Wong)</b></td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>9:00 AM - 10:00 AM</td>
                                            <td colSpan={2}>
                                                <p>When Gravity Has Taken its Toll</p>
                                                <p>(Tightening Machines) <b>(Dr. Angeline Yong)</b></p>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>10:00 AM - 11:00 AM</td>
                                            <td colSpan={2}>
                                                <p>She’s Perfect, What’s Next?</p>
                                                <p>(Maintenance Machines) <b>(Dr. Irene Gaile Robredo-Vitas)</b></p>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>11:00 AM - 12:00 NN</td>
                                            <td colSpan={2}>
                                                <p>Mix and Match Game </p>
                                                <p>(Combining Technologies and How) <b>(Dr. Irene Gaile Robredo-Vitas)</b></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>                               
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 4: Suspension Threads (1:00 pm to 3:00 pm - Batanes)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Participants have the opportunity to learn how to safely perform the latest techniques on suspension threads for face and neck lift as well as nose augmentation.</p>
                            <p className="mt-3"><b><i>Chair:</i></b></p>
                            <p className="mb-3">Dr. Teresita Ferrariz</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th colSpan={2} style={{textAlign:"center", verticalAlign:"middle"}}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>1:00 PM - 2:00 PM</td>
                                            <td colSpan={2}>
                                                <p>Hanging on for Dear Life (Full Face and Neck Lift) <b>(Dr. Cyril Agan)</b></p>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}><p>Keep your Nose in my Business (Nose Lift)<b> (Dr. Alvin Jorge)</b></p>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </Table>                               
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 5: Sclerotherapy and Chemical Peels (3:00 pm to 5:00 pm - Batanes) </span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Participants have the opportunity to learn from a world renowned expert on Chemical peels and Sclerotherapy.</p>
                            <p className="mt-3"><b><i>Chair:</i></b></p>
                            <p>Dr. Agnes Thaebtharm</p>
                            <p></p>
                            <p className="mt-3"><b><i>Faculty:</i></b></p>
                            <p className="mb-3">Dr. Marina Landau</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th colSpan={2} style={{textAlign:"center", verticalAlign:"middle"}}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>3:00 PM - 4:00 PM</td>
                                            <td colSpan={2}>Life is Like an Onion: Peeling the Layers Safely </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>4:00 PM - 5:00 PM</td>
                                            <td colSpan={2}>
                                                <p>Fire in my Heart, Ice in my Veins:</p> 
                                                <p>Sclerotherapy for Beginners</p>
                                                 
                                            </td>
                                            </tr>                                            
                                        </tbody>
                                    </Table>                               
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 6: Regenerative Medicine (8:00 am to 12:00 NN - BORACAY)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Attendees will be exposed to various regenerative techniques using stem cells, growth factors and  exosomes for hair restoration, wound healing, melasma, anti-aging and overall skin health. </p>
                            <p className="mt-3"><b><i>Chairs:</i></b></p>
                            <p>Dr. Ma. Cristina Puyat and Dr. Suzanne Datuin</p>
                            <p className="mt-3"><b><i>Faculty:</i></b></p>
                            <p className="mb-3">Dr. Teresita Ferrariz and Dr. Loverne Suratos</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th colSpan={2} style={{textAlign:"center", verticalAlign:"middle"}}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>8:00 AM - 9:00 AM</td>
                                            <td colSpan={2}>The Avatars of Regenerative Dermatology <b>(Dr. Rosario Salud Abaya Blas)</b> </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>9:00 AM - 10:00 AM</td>
                                            <td colSpan={2}>Nanobiotechnology: The Genie in a Bottle <b>(Dr. Teresita Ferrariz)</b> 
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>10:00 AM - 11:00 AM</td>
                                            <td colSpan={2}>The EX Factor and More <b>(Dr. Rob Knight and Dr. Franchesca Quinio-Calayag)</b> </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>11:00 AM - 12:00 NN</td>
                                            <td colSpan={2}><p>Beyond the Crowning Glory <b>(Dr. Loverne Suratos)</b></p>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </Table>                               
                            </div>
                        </div>
                    </div>
                    </Card>
                    <Card style={{marginBottom:"5px"}}>
                    <div className="accordion-item" >
                        <h3 className="accordion-heading" style={{textAlign:"center",  color: 'black' }}><span className="inner">Workshop 7: Dermoscopy and Flaps (1:00 pm to 3:00 pm - BORACAY)</span></h3>
                        <div className="accordion-content">
                            <div className='container justify-content-md-center my-5'>
                            <p><b><i>Course Description:</i></b></p>
                            <p>Attendees will learn dermatologic surgery techniques through case presentations and be able to observe or try (hands-on) various dermoscopes on actual cases. </p>
                            <p className="mt-3"><b><i>Chairs:</i></b></p>
                            <p className="mb-3">Dr. Krisinda Dim-Jamora and Dr. Arunee Siripunvarapon</p>
                            <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th style={{textAlign:"center", verticalAlign:"middle"}}>Time</th>
                                            <th colSpan={2} style={{textAlign:"center", verticalAlign:"middle"}}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>1:00 PM - 1:45 PM</td>
                                            <td colSpan={2} style={{verticalAlign:"middle"}}>
                                                <p><b>All About Flaps!</b></p>
                                                <p>1. East West Flap - Dr. Julie Anne Patricia Songco</p>
                                                <p>2. Bilobed Flap - Dr. Charlene Ang-Tiu</p>
                                                <p>3. V to Y Advancement Flap - Dr. Krisinda Dim-Jamora</p>
                                                <p>4. Combination Flaps - Dr. Zharlah Gulmatico-Flores</p>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>1:45 PM - 2:00 PM</td>
                                            <td colSpan={2}> The Truth Behind the Lens <b>(Dr. Arunee Siripunvarapon)</b>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td style={{textAlign:"center", verticalAlign:"middle"}}>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}> <b>Dermoscopy Cases (Mentors)</b>
                                            <p>1. Dr. Guia Avecilla</p>
                                            <p>2. Dr. Joshua Arcaira</p>
                                            <p>3. Dr. Martha Tapales</p>
                                            <p>4. Dr. Elisa Coo</p>
                                            <p>5. Dr. Gisella Adasa</p>
                                            <p>6. Dr. Arunee Siripunvarapon</p>
                                            <p>7. Dr. Jay-V James Barit</p>
                                            <p>8. Dr. Val Constantine Cua</p>

                                            </td>
                                            </tr>                                            
                                        </tbody>
                                    </Table>                              
                            </div>
                        </div>
                    </div>
                    </Card>
                    
                </div>
            </div>
            </Col>
            </Row>
                {/* <div class="row justify-content-md-center mb-3 corner-rounded bg-203664">
                    <div class="col-6">
                        <div class="corner-rounded text-white p-3">
                            <p class="h4 text-center dmsans-regular mb-0">November 5, 2023</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="corner-rounded text-white py-3">
                            <p class="h4 text-start dmsans-regular mb-0">Day 1 - Preconvention Workshops</p>
                        </div>
                    </div>
                </div> */}
            
            {/* <Row>
                <Col md={12}>
                    <h3 class="mt-5">Tabbed Version</h3>
                    <Tabs defaultActiveKey="workshop1">
                        <Tab eventKey="workshop0" title="Workshops: " disabled>
                            
                        </Tab>
                        <Tab eventKey="workshop1" title="1" >
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">Basic Injectables (8:00 am to 12:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto", textAlign:"center"}}> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>8:00 AM - 9:00 AM</td>
                                            <td colSpan={2}>The Picasso Patient: Tricky Forehead and Uneven Eyes</td>
                                            </tr>
                                            <tr>
                                            <td>9:00 AM - 10:30 AM</td>
                                            <td colSpan={2}>How to be you Noona? The Secret to Korean Glass Skin</td>
                                            </tr>
                                            <tr>
                                            <td>10:30 AM - 12:00 PM</td>
                                            <td colSpan={2}>The 1cc Full Face Challenge</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop2" title="2">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3"> Advanced Injectables (1:00 pm to 5:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>1:00 PM - 2:00 PM</td>
                                            <td colSpan={2}>How to Bring the Sexy Back - Neuromodulators for Shoulder and Arms</td>
                                            </tr>
                                            <tr>
                                            <td>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}>How to Reshape the Chubby Face</td>
                                            </tr>
                                            <tr>
                                            <td>3:00 PM - 4:00 PM</td>
                                            <td colSpan={2}>Sharp Jawline: The Edge Everyone Wants</td>
                                            </tr>
                                            <tr>
                                            <td>4:00 PM - 5:00 PM</td>
                                            <td colSpan={2}>BIO-what?: Trends for Collagen Renewal</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop3" title="3">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">  Lasers and Energy Based Devices (8:00 am to 12:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>8:00 AM - 9:00 AM</td>
                                            <td colSpan={2}>The Rocky Road: Paving the Way to Smoother Skin</td>
                                            </tr>
                                            <tr>
                                            <td>9:00 AM - 10:00 AM</td>
                                            <td colSpan={2}>When Gravity Has Taken its Toll (Tightening Machines)
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>10:00 AM - 11:00 AM</td>
                                            <td colSpan={2}>She’s Perfect, What’s Next? (Maintenance Machines)</td>
                                            </tr>
                                            <tr>
                                            <td>11:00 AM - 12:00 PM</td>
                                            <td colSpan={2}>Mix and Match Game (Combining Technologies and How)</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop4" title="4">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">Suspension Threads (1:00 pm to 3:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>1:00 PM - 2:00 PM</td>
                                            <td colSpan={2}>Hanging on for Dear Life (Full Face and Neck Lift)</td>
                                            </tr>
                                            <tr>
                                            <td>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}>Keep your Nose in my Business (Nose Lift)
                                            </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop5" title="5">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">Sclerotherapy and Chemical Peels (3:00 pm to 5:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>3:00 PM - 4:00 PM</td>
                                            <td colSpan={2}>Life is Like an Onion: Peeling the Layers Safely</td>
                                            </tr>
                                            <tr>
                                            <td>4:00 PM - 5:00 PM</td>
                                            <td colSpan={2}>Fire in my Heart, Ice in my Veins: Sclerotherapy for Beginners
                                            </td>
                                            </tr>                                            
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop6" title="6">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">Regenerative Medicine (8:00 am to 12:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>8:00 AM - 9:00 AM</td>
                                            <td colSpan={2}>The Avatars of Regenerative Dermatology</td>
                                            </tr>
                                            <tr>
                                            <td>9:00 AM - 10:00 AM</td>
                                            <td colSpan={2}>Nanobiotechnology: The Genie in a Bottle
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>10:00 AM - 11:00 AM</td>
                                            <td colSpan={2}>The Restorative Power of a Hybrid EX Factor</td>
                                            </tr>
                                            <tr>
                                            <td>11:00 AM - 12:00 PM</td>
                                            <td colSpan={2}>Beyond the Crowning Glory</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                        <Tab eventKey="workshop7" title="7">
                            <Card style={{margin: "10px"}}>
                                <Card.Title>
                                <h3 class="text-center mt-3">Dermoscopy and Flaps (1:00 pm to 3:00 pm)</h3>
                                </Card.Title>
                                <Card.Text>
                                    <Table style={{margin: "auto"}} responsive> 
                                        <thead>
                                            <tr>
                                            <th>Time</th>
                                            <th colSpan={2}>Activity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>1:00 PM - 2:00 PM</td>
                                            <td colSpan={2}>The Truth Behind the Lens</td>
                                            </tr>
                                            <tr>
                                            <td>2:00 PM - 3:00 PM</td>
                                            <td colSpan={2}>All About Flaps!
                                            </td>
                                            </tr>                                            
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card>
                        </Tab>
                    </Tabs>
                </Col>
            
            </Row> */}
                        
        </Container>
        
    )
}