import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class BottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: [
                {
                    id: 1,
                    name: 'Home',
                    links: '/'
                },
                {
                    id: 2,
                    name: 'About Us',
                    links: '/about-us'
                },
                {
                    id: 3,
                    name: 'Services',
                    links: '/services'
                },
                {
                    id: 4,
                    name: 'Projects',
                    links: '/project'
                },
                {
                    id: 5,
                    name: 'Page',
                    links: '/page-testimonial'
                },
                {
                    id: 6,
                    name: 'Blog',
                    links: '/blog'
                },
                {
                    id: 7,
                    name: 'Contact',
                    links: '/contact-01'
                }
            ]
        }
    }

    render() {
        return (
            <div id="bottom" className="clearfix has-spacer">
                <div id="bottom-bar-inner" className="container">
                    <div className="bottom-bar-inner-wrap">
                        <div className='col-12'>

                            <div className='row'>
                                <div className='col-3 d-sm-none d-xs-none d-md-block'>
                                </div>
                                <div className='col-xs-12 col-md-6'>
                                    {/* <div className='row'>
                                        <div className='col-4 justify-content-center d-flex'>
                                            <a href="https://pds.org.ph/" target="_blank">
                                                <img alt="pds" src='https://res.cloudinary.com/development-mar/image/upload/v1683273300/PDS/PDS_LOGO-02_wmhc0a.png' />
                                            </a>                                            
                                        </div>
                                        <div className='col-4 justify-content-center d-flex'>
                                            <img alt="fb-pds" src='assets/img/slider/fb.png' style={{cursor:"pointer"}} onClick={()=>{ window.open("https://www.facebook.com/profile.php?id=100090672214162")}}/>
                                        </div>
                                        <div className='col-4 justify-content-center d-flex'>
                                            <img src="assets/img/slider/ig.png" alt="ig-pds" style={{cursor:"pointer"}} onClick={()=>{ window.open("https://www.instagram.com/pdsconvention2023/")}}/>
                                        </div>                                        
                                    </div> */}
                                    {/* <div className='row'>
                                        <div className='col-4 justify-content-center d-flex' >
                                            <img style={{ cursor: "pointer" }} alt="asd_image" src='assets/img/slider/fb.png' onClick={() => {
                                                // window.location = "https://www.facebook.com/ASDMeeting2022"
                                            }} />
                                        </div>
                                        <div className='col-4 justify-content-center d-flex'>
                                            <img style={{ cursor: "pointer" }} alt="asd_image" src='assets/img/slider/tw.png' onClick={() => {
                                                // window.location = "https://twitter.com/ASDMeeting2022"
                                            }} />
                                        </div>
                                        <div className='col-4 justify-content-center d-flex'>
                                            <img style={{ cursor: "pointer" }} alt="asd_image" src='assets/img/slider/ig.png' onClick={() => {
                                                // window.location = "https://www.instagram.com/asdmeeting2022/"
                                            }} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col-3 d-sm-none d-xs-none d-md-block'>
                                </div>
                            </div>
                            <div className='row'>

                                {/* <div className="bottom-bar-content"> */}
                                <div id="copyright" style={{ width: '100%', display: "flex", marginTop: 10 }}><span style={{ width: '100%', textAlign: 'center' }} className="text">2024 All Rights Reserved <Link to="#"
                                >© Go Virtual Technology Solutions</Link> | November 5 - 7, 2023</span>
                                    {/* </div> */}
                                </div>{/* /.bottom-bar-content */}
                            </div>
                        </div>

                        {/* <div className="bottom-bar-menu">
                            <ul className="bottom-nav">
                                {
                                    this.state.menus.map(data => (
                                        <li className={data.name === this.props.data.names ? "menu-item current-menu-item" : "menu-item"} key={data.id}>
                                            <Link to={data.links} onClick={() => { window.location.href = data.links }}>{data.name}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BottomBar);