import React, { useState, useEffect } from 'react';

export default function StepFour(props) {
    return (
        <div className={props.currentForm === 3 ? "multisteps-form__panel js-active" : "multisteps-form__panel"} data-animation="slideHorz" >

            <div className="wizard-forms">
                <div className="inner pb-50 clearfix">
                    <div className="form-content pera-content" style={{ width: '100%', paddingLeft: '15%', paddingRight: '15%' }}>
                        <div className="step-inner-content">
                            <h2 style={{ padding: 0 }}>ABSTRACT SUBMISSION</h2>

                            <div className="step-box">

                                <p style={{ paddingTop: 20 }}>
                                    <br /> <br />Thank you for submitting your Abstract to the<font style={{ fontWeight: "bold" }}> 46th Annual Meeting of the Philippine Dermatological Society (PDS).</font>
                                </p>
                                <p style={{ paddingTop: 20 }}>
                                    We will email you once your abstract has been reviewed.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <ul>
                        <li>
                            <button onClick={() => {
                                window.location = '/';
                            }} className="themesflat-button bg-accent">Home</button>
                        </li>
                    </ul>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={200} data-mobile={150} data-smobile={150} />

            </div>
        </div>
    );
}