import React, { useState } from 'react';

import Template from './sub/template';
import { registrationOptions } from './constant';

export default function StepOne(props) {
  const [currentOption, setCurrentOption] = useState('option_4_5');
  const [prevOption, setPrevOption] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNext = (value, nextOption) => {
    if (!nextOption) return props.handleNext('step_2', [...selectedOptions.filter(Boolean), value]);

    console.log(value, nextOption)

    setSelectedOptions([...selectedOptions.filter(Boolean), value]);
    setCurrentOption(nextOption);
    setPrevOption([...prevOption, nextOption])
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  // console.log(registrationOptions[currentOption])

  const handleBack = () => {
    // setSelectedOptions([...selectedOptions].pop())
    // setCurrentOption(prevOption[prevOption.length - 1]);
    // setLoading(true);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 10);
  };


  return (
    <div className={`${props.currentForm === 0 ? 'js-active' : ''} multisteps-form__panel`}> 
      <div className="wizard-forms">
        <Template
          loading={loading}
          selectedOptions={selectedOptions}
          currentOption={currentOption}
          details={registrationOptions[currentOption].details}
          options={registrationOptions[currentOption].options}
          handleNext={handleNext}
          handleBack={handleBack}

          setFirstName={props.setFirstName}
          setLastName={props.setLastName}
          setPdsNumber={props.setPdsNumber}
          pds={props.pds}
          setPds={props.setPds}

          setSpeakerSession={props.setSpeakerSession}
          setBoardMember={props.setBoardMember}

          setRegisteredWorkshops={props.setRegisteredWorkshops}
      
          setWorkshops={props.setWorkshops}
          workshops={props.workshops}
          disabledList={props.disabledList}
          setDisabledList={props.setDisabledList}
          setSelected={props.setSelected}
          selected={props.selected}
          setEmail={props.setEmail}

          setNoSlotsAvailable={props.setNoSlotsAvailable}

          setUserData={props.setUserData}
      />
      </div>
    </div>

  );
}
